<template>
    <div class="ImageNetVueControl" id="imagenet">
      	<label>종류</label>
	    	<select v-model="kinds_selected">
		    	<option v-for="option in kinds_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>요구 차원</label>
	    	<select v-model="req_dims_selected">
		    	<option v-for="option in req_dims_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>출력1의 개수</label>
	    	<select v-model="print1_num_selected">
		    	<option v-for="option in print1_num_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>출력2의 개수</label>
	    	<select v-model="print2_num_selected">
		    	<option v-for="option in print2_num_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
	    <label>현재 차원</label>
	    	<select v-model="current_dim_selected">
		    	<option v-for="option in current_dim_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select> 
	    	<!--class="mod-btn"-->
	    <b-button id="modify-btn" @click="showModal">수정</b-button>
        	<b-modal ref="my-modal" hide-footer size="xl">
        	    {{testid}}
    				<ModelDetailsComp></ModelDetailsComp>
    		</b-modal>
    	<!--<b-button id="model_save_btn">모델 저장</b-button>-->
    </div>
</template>

<script>
    // 모델 상세보기 // 모델 상세보기 모달 에디터 실행 불러오기
    import ModelDetailsComp from '@/components/MainPage/Trainmodels/ModelDetails/ModelDetailsComp';
    
    export default {
        name: 'ImageNetVueControl',
        data() {
            return {
                kinds_selected: 'ImageNet',
                req_dims_selected: '(None,224,224,3)',
                print1_num_selected: '1000',
                print2_num_selected: '1',
                current_dim_selected: '(224,224,3)',

                kinds_options: [
                    { text: 'ImageNet', value: 'ImageNet' },
                ],
                req_dims_options: [
                    { text: '(None,224,224,3)', value: '(None,224,224,3)' }
                ],
                print1_num_options: [
                    { text: '1000', value: '1000' }
                ],
                print2_num_options: [
                    { text: '1', value: '1' }
                ],
                current_dim_options: [
                    { text: '(224,224,3)', value: '(224,224,3)' }
                ],
                images: [],
                testid: '',
            }
        },
        components: {
            ModelDetailsComp
        },
        methods: {
            showModal() {
                this.$refs['my-modal'].show();
            },
            hideModal() {
                this.$refs['my-modal'].hide();
            },
        }
    }
</script>

<style scoped>
    .ImageNetVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .ImageNetVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .ImageNetVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    .ImageNetVueControl button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>
