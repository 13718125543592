import Rete from 'rete';
import { numSocket } from '../../sockets';
import ModelSaveControl from '../../controls/basic-controls/ModelSaveControl';

export default class ModelSaveComponent extends Rete.Component {
	constructor() {
		super("모델 저장");
		this.data = {
			render: 'vue'
		};
	}

	builder(node) {
		let input1 = new Rete.Input('input1', 'Input1', numSocket);
		// let output1 = new Rete.Output('output1', "Output1", numSocket);
		
		return node
			.addControl(new ModelSaveControl(this.editor, "num"))
			.addInput(input1)
			// .addOutput(output1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
