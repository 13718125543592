import Vue from 'vue';
import VueRouter from 'vue-router';

// Page 불러오기
import LoginPage from '../pages/LoginPage';
import MainPage from '../pages/MainPage';
import InferenceComp from '@/components/MainPage/Inferences/InferenceComp';
import WorkSpaceComp from '@/components/MainPage/Workspaces/WorkSpaceComp';
import LecturesComp from '@/components/MainPage/Lectures/LecturesComp';
import TrainComp from '@/components/MainPage/Trainmodels/TrainComp';
import TrainComp2 from '@/components/MainPage/Trainmodels/TrainComp2';

// Vue 라우터 사용
Vue.use(VueRouter)


export const router = new VueRouter({
    routes: [{
            // 상위 route 로그인 페이지
            path: '/',
            component: LoginPage
        },
        {
            // 메인 페이지 
            path: '/main',
            component: MainPage,
            children: [{
                    // 프로젝트 들어가기 
                    path: '/workspaces',
                    component: WorkSpaceComp
                }, {
                    // 모델 만들기 페이지 
                    path: '/trainmodel',
                    component: TrainComp
                }, {
                    // 강의 페이지
                    path: '/lectures',
                    component: LecturesComp
                },
                {
                    // 추론 페이지
                    path: '/inference',
                    component: InferenceComp
                },
                {
                    // 모델 훈련 2
                    path: '/trainmodel2',
                    component: TrainComp2
                }
            ]

        },

    ]
})
