<template>
    <div class="datamonitoringVueControl">
        <label>종류</label>
        <!--@change="changeKind"-->
	    	<select v-model="data_vis">
	    	    <option value = "" disabled></option>
	    	    <option value = "data_visualization">데이터 시각화</option>
	    	    <option value = "data_textualization">데이터 문자화</option>
		    	<!--<option v-for="option in kinds_options" v-bind:value="option.value">-->
			    <!--	{{ option.text }}-->
			    <!--</option>-->
	    	</select>
    	<label>모니터링 위치</label>
    	<!--@change="changelocation"-->
	    	<!--<select v-model="monitoring_loc_selected" @change="changelocation" >-->
		    <!--	<option v-for="option in monitoring_loc_options" v-bind:value="option.value">-->
			   <!-- 	{{ option.text }}-->
			   <!-- </option>-->
	    	<!--</select>-->
	    	<select v-model='vis_loc'>
	    	    <option value = "" disabled hidden></option>
	    	    <option value = "bottom">하단</option>
	    	    <option value = "bottom_right">하단 오른쪽</option>
	    	    <option value = "bottom_left">하단 왼쪽</option>
	    	</select>
	    <label>모니터링 방식</label>
	    	<!--<select v-model="monitoring_kinds_selected" @change='changemonitorkind' >-->
		    <!--	<option v-for="option in monitoring_kinds_options" v-bind:value="option.value">-->
			   <!-- 	{{ option.text }}-->
			   <!-- </option>-->
			   <select v-model="monitor_kind">
			       <option value = "" disabled hidden></option>
			       <option value = "DataTable">데이터 차트</option>
			       <option value = "Datagraph">데이터 그래프</option>
			       <option value = "MnistImage">데이터 이미지</option>
			   </select>
	    	</select>
    	<!--<label>구분 기준</label>-->
	    <!--	<select v-model="distribution_selected">-->
		   <!-- 	<option v-for="option in distribution_options" v-bind:value="option.value">-->
			  <!--  	{{ option.text }}-->
			  <!--  </option>-->
	    <!--	</select>-->
    </div>
</template>
<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';


    export default {
        name: 'datamonitoringVueControl',
        data() {
            return {
                // kinds_selected: '',
                // monitoring_loc_selected: '',
                // monitoring_kinds_selected: '',
                // distribution_selected: '폴더 이름',
                // kinds_options: [
                //     { text: '데이터 시각화', value: "data_visualization" },
                //     { text: '데이터 문자화', value: "data_textualization" },
                // ],
                // monitoring_loc_options: [
                //     { text: '하단', value: 'bottom' },
                //     { text: '하단 오른쪽', value: 'bottom_right' },
                //     { text: '하단 왼쪽', value: 'bottom_left' }
                // ],
                // monitoring_kinds_options: [
                //     { text: '원형 그래프', value: 'donut_graph' },
                //     { text: '데이터 차트', value: 'DataTable' },
                //     { text: '불러온 이미지', value: 'upload_image' }
                // ],
                // distribution_options: [
                // { text: '폴더 이름', value: '폴더 이름' }
                // ]
            }
        },
        methods: {
            // changeKind(e) {
            //     switch (e.target.value) {
            //         case 'data_visualization':
            //             store.dispatch('call_visualization', { visualization: 'data_visualization' });
            //             return
            //         case 'data_textualization':
            //             store.dispatch('call_visualization', { visualization: 'data_textualization' });
            //             return
            //     }
            // },
            // changelocation(e) {
            //     switch (e.target.value) {
            //         case 'bottom':
            //             store.dispatch('call_location', { bottomlocation: 'bottom' });
            //             return

            //         case 'bottom_right':
            //             store.dispatch('call_location', { bottomlocation: 'bottom_right' });
            //             this.monitoring_kinds_selected = ''
            //             return

            //         case 'bottom_left':
            //             store.dispatch('call_location', { bottomlocation: 'bottom_left' });
            //             this.monitoring_kinds_selected = ''
            //             return
            //     }
            // },
            // changemonitorkind(e) {

            //     switch (e.target.value) {
            //         case 'donut_graph':
            //             store.dispatch('call_monitor_kinds', { monitorKinds: 'Datagraph' });
            //             return

            //         case 'DataTable':
            //             store.dispatch('call_monitor_kinds', { monitorKinds: 'DataTable' });
            //             return

            //         case 'upload_image':
            //             store.dispatch("call_monitor_kinds", { monitorKinds: 'MnistImage' });
            //             return
            //     }
            // },
            // changetest(e) {
            // this.kinds_selected = 'data_textualization';
            // store.dispatch('call_visualization', { visualization: 'data_textualization' });
            // this.monitoring_loc_selected = 'bottom';
            // store.dispatch('call_location', { bottomlocation: 'bottom' });
            // }
        },
        // mounted() {
        //     this.changetest();
        // },
        computed: {
            data_input_name() {
                return store.getters.get_data_input_name
            },
            data_vis: {
                get() {
                    return store.getters.getVisualization
                },
                set(value) {
                    console.log('value changed');
                    store.dispatch('call_visualization', value);
                }
            },
            vis_loc: {
                get() {
                    return store.getters.getBottomLocation
                },
                set(value) {
                    console.log('location changed');
                    store.dispatch('call_location', { bottomlocation: value });
                }
            },
            // monitor_kind: {
            //     get() {
            //         return store.getters.getMonitorKinds
            //     },
            //     set(value) {
            //         console.log('monitor_kind changed');
            //         store.dispatch('call_monitor_kinds', value);
            //     }
            // }
            monitor_kind: {
                get() {
                    return store.getters.getMonitorKinds
                },
                set(value) {
                    console.log('monitor kind change');
                    store.dispatch('call_monitor_kinds', { monitorKinds: value });
                }
            }
        }
    }
</script>

<style scoped>
    .datamonitoringVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .datamonitoringVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .datamonitoringVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }
</style>
