<template>
    <!--사전 훈련된 모델 선택 내용 부분-->
        <div class="model_upload_content">
            <!--모델 선택 카드 상단 라벨 부분-->
            <div class="mi_content_header">
                <!--<label>사전 훈련된 모델</label>-->
                <label>{{ mi_content_header_modelselect }}</label>
            </div>
            <!--모델 선택 카드 상단 라벨 부분 end-->
            
            <!-- 모델 선택 카드 부분 -->
            <b-card no-body class="model_input_part">
                
                <!--모델 선택하기 카드 헤더 부분 -->
                <b-card-header>
                    <b-button @click="show_ModelSelct_Modal" variant="primary">{{ model_select }}</b-button>
                    <b-modal ref="model_picker" centered hide-header hide-footer size="xl">
                        <SelectModelModal v-on:close_btn="hide_ModelSelect_Modal" />
                    </b-modal>
                </b-card-header>
                <!--모델 선택하기 카드 헤더 부분 end -->

                <!--텐서 스페이스 모델 불러오기 -->
                <tensorspace_model_test ref="tensorspace_model" v-show="selected_pretrain_model"/>
                <!--텐서 스페이스 모델 불러오기 END-->
            </b-card>
            <!-- 모델 선택 카드 부분 end -->
            
        </div>
    <!--사전 훈련된 모델 선택 내용 부분 end-->
</template>

<script>
    // vuex =====================
    import store from '@/store';
    // ==========================

    // 모델 선택하기 모달 부분 =======================
    import SelectModelModal from './SelectModelModal';
    // ===============================================

    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================

    // TensorSpaceModel =============================================================

    // 텐서 스페이스 모델 로컬파일
    import tensorspace_model_localfile from './TensorSpaceModel/TensorSpaceModel_LocalFile';
    /*// 텐서 스페이스 모델 GAN CARTOON
    import tensorspace_model_GAN_CARTOON from './TensorSpaceModel/TensorSpaceModel_GAN_CARTOON';
    // 텐서 스페이스 모델 HAND POSE
    import tensorspace_model_HAND_POSE from './TensorSpaceModel/TensorSpaceModel_HAND_POSE';
    // 텐서 스페이스 모델 CONTEXT R CNN
    import tensorspace_model_CONTEXT_R_CNN from './TensorSpaceModel/TensorSpaceModel_CONTEXT_R_CNN';
    // 텐서 스페이스 모델 MNIST CONV NET
    import tensorspace_model_MNIST_CONV_NET from './TensorSpaceModel/TensorSpaceModel_MNIST_CONV_NET';
    // 텐서 스페이스 모델 MNIST DENSE NET
    import tensorspace_model_MNIST_DENSE_NET from './TensorSpaceModel/TensorSpaceModel_MNIST_DENSE_NET';
    // 텐서 스페이스 모델 IRIS DENSE NET
    import tensorspace_model_IRIS_DENSE_NET from './TensorSpaceModel/TensorSpaceModel_IRIS_DENSE_NET';
    // 텐서 스페이스 모델 MOBILE NET
    import tensorspace_model_MOBILE_NET from './TensorSpaceModel/TensorSpaceModel_MOBILE_NET';
    */
    import tensorspace_model_test from './TensorSpaceModel/TensorSpaceModel_test';
    // =======================================================================

    export default {
        name: 'SelectModelComp',
        data() {
            return {
                
                // selected pretrained model
                pretrained_model: '',

                // 다국어 설정 후 변경될 데이터 지정
                mi_content_header_modelselect: '',
                model_select: '',
                // 언어 설정
                localization: ''
            }
        },
        components: {
            // 모델 선택하기 모달 부분 컴포넌트 등록
            SelectModelModal,

            // 모델 선택하기 텐서스페이스 부분

            // 로컬파일 선택
            tensorspace_model_localfile,
            // GAN-CARTOONIZER
            /*tensorspace_model_GAN_CARTOON,
            // HAND POSE
            tensorspace_model_HAND_POSE,
            // CONTEXT-R-CNN
            tensorspace_model_CONTEXT_R_CNN,
            // MNIST CONVOLUTION NET
            tensorspace_model_MNIST_CONV_NET,
            // MNIST DENSE NET
            tensorspace_model_MNIST_DENSE_NET,
            // IRIS DENSE NET
            tensorspace_model_IRIS_DENSE_NET,
            // MOBILE NET
            tensorspace_model_MOBILE_NET,*/
            tensorspace_model_test,
            // ===============================

        },
        mounted() {
            /* global navigator */
            // 브라우저의 언어 설정을 읽어와서 변수로 지정
            let locale = navigator.language || navigator.userLanguage;


            // 지정한 변수를 데이터로 지정 
            this.localization = locale.substring(0, 2);

            // 언어 설정 영어 테스트 // 언어를 임의로 바꿔서 테스트 할 수 없기 때문에

            // this.localization = 'en';

            // 언어 별 분기 설정
            switch (this.localization) {

                case 'ko':
                    // 모델 선택 카드 부분
                    this.mi_content_header_modelselect = korean.inference.mi_content_header_modelselect;
                    // 모델 선택
                    this.model_select = korean.inference.model_select;
                    return;

                case 'en':
                    // 모델 선택 카드 부분
                    this.mi_content_header_modelselect = english.inference.mi_content_header_modelselect;
                    // 모델 선택
                    this.model_select = english.inference.model_select;
                    return;
            }

        },
        methods: {
            // 사전훈련된 모델 선택 모달 열기
            show_ModelSelct_Modal() {
                this.$refs['model_picker'].show();
            },
            // 사전훈련된 모델 선택 모달 닫기 // 텐서스페이스 실행 // 추론 이미지 초기화
            hide_ModelSelect_Modal(pretrained_model) {
                // 모달 닫기
                this.$refs['model_picker'].hide();

                // 반영될 파일 이미지 초기화
                this.images = [];
                // 추론 이미지 초기화
                this.session_Inference_Image = '';

                this.pretrained_model = pretrained_model;

                // 모델 선택 // 사전 훈련된 모델 선택에서 불러오기 할 부분
                this.$emit('setPretrainedModel', this.pretrained_model)

                // 텐서스페이스 모델 불러오기 
                this.$refs.tensorspace_model.tensorspace_model_upload();
            },
        },
        computed: {
            // 선택된 사전훈련된 모델
            selected_pretrain_model() {
                return store.getters.get_pretrain_model_name;
            },
        }

    }
</script>
<style scoped>
    /* 모델 업로드 상단 라벨 부분 ------------------------------*/

    .mi_content_header {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .mi_content_header label {
        font-size: 20px;
        font-weight: bold;
    }

    /* 모델 업로드 부분 ----------------------------------------*/

    .model_input_part {
        width: 450px;
        height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .model_input_part .card-header {
        width: 450px;
        height: 54px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .model_input_part .card-header label {
        font-size: 20px;
        font-weight: bold;
    }

    .model_input_part .card-header button {
        margin-left: auto;
        width: 100px;
    }
</style>
