<template>
    <div class="dock">
        <div class="dock-label" v-b-toggle.collapse-a >
            <b-icon icon="chevron-down"></b-icon>
            <!--노드 구성 토글 버튼 부분-->
            <!--노드 구성-->
            <label>{{ node_config_label }}</label>
        </div>
        <b-collapse visible id="collapse-a">
             <div class="dock-item">
                <div class="node 데이터입력" id="datainput">
                    <!--데이터 입력(이미지) 노드 생성-->
                    <!--데이터 입력(이미지)-->
                    <div class="title">{{ data_input_image }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node 데이터입력" id="defaultinput">
                    <!--데이터 입력(기본) 노드 생성-->
                    <!--데이터 입력(기본)-->
                    <div class="title">{{ data_input_default }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node 데이터입력" id="webcam">
                    <!--데이터 입력(웹캠) 노드 생성-->
                    <!--데이터 입력(웹캠)-->
                    <div class="title">{{ data_input_webcam }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node 모니터링" id="monitoring_data">
                    <!--데이터 모니터링 노드 생성-->
                    <!--데이터 모니터링-->
                    <div class="title">{{ data_monitoring }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node 모니터링" id="monitoring_model">
                    <!--모델 모니터링 노드 생성-->
                    <!--모델 모니터링-->
                    <div class="title">{{ model_monitoring }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node 모델 저장" id="modelsave">
                    <!--모델 저장 노드 생성-->
                    <!--모델 저장-->
                    <div class="title">{{ model_save }}</div>
                </div>
            </div>
        </b-collapse>
        <hr/>
        <div class="dock-label" v-b-toggle.collapse-b>
            <b-icon icon="chevron-down"></b-icon>
            <!--제공 데이터 토글 버튼 부분-->
            <!--제공 데이터-->
            <label>{{ data_provided_label }}</label>
        </div>
        <b-collapse visible id="collapse-b">
            <div class="dock-item">
                <div class="node" id="mnist_data">
                    <!--MNIST 데이터 노드 생성-->
                    <!--MNIST 데이터-->
                    <div class="title">{{ MNIST_DATA }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node" id="iris_data">
                    <!--IRIS 데이터 노드 생성-->
                    <!--IRIS 데이터-->
                    <div class="title">{{ IRIS_DATA }}</div>
                </div>
            </div>
        </b-collapse>
        <hr/>
        <div class="dock-label" v-b-toggle.collapse-c >
            <b-icon icon="chevron-down"></b-icon>
            <!--기본 모델 토글 버튼 부분-->
            <!--기본 모델-->
            <label>{{ default_model_label }}</label>
        </div>
        <b-collapse visible id="collapse-c">
            <div class="dock-item">
                <div class="node" id="mnist_convnet">
                    <!--MNIST CONVOLUTION NET 모델 노드 생성-->
                    <!--MNIST ConvNet-->
                    <div class="title">{{ MNIST_CONVNET }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node" id="mnist_densenet">
                    <!--MNIST DENSE NET 모델 노드 생성-->
                    <!--MNIST DenseNet-->
                    <div class="title">{{ MNIST_DENSENET }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node" id="mnist_custom_cnn">
                    <!--MNIST Custom CNN 모델 노드 생성-->
                    <!--MNIST Custom CNN-->
                    <div class="title">{{ MNIST_CUSTOM_CNN }}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node" id="iris_densenet">
                    <!--IRIS DENSE NET 모델 노드 생성-->
                    <!--IRIS DenseNet-->
                    <div class="title">{{ IRIS_DENSENET }}</div>
                </div>
            </div>
        </b-collapse>
        <hr/>
        <div class="dock-label" v-b-toggle.collapse-d>
            <b-icon icon="chevron-down"></b-icon>
            <!--훈련된 모델 SET 토글 버튼 부분-->
            <!--훈련된 모델 SET-->
            <label>{{ pretrained_model_label }}</label>
        </div>
        <b-collapse visible id="collapse-d">
            <div class="dock-item">
                <div class="node" id="mnist_test">
                    <!--MNIST MODEL 선택 노드 부분-->
                    <!--MNIST 모델-->
                <div class="title">{{ MNIST_MODEL }}</div>
            </div>
        </div>            
        </b-collapse>
        <hr/>
    </div>
</template>

<script>
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: "dock-item",
        data() {
            return {
                // 다국어 설정
                node_config_label: '',
                data_input_image: '',
                data_input_default: '',
                data_input_webcam: '',
                data_monitoring: '',
                model_monitoring: '',
                model_save: '',

                data_provided_label: '',
                MNIST_DATA: '',
                IRIS_DATA: '',

                default_model_label: '',
                MNIST_CONVNET: '',
                MNIST_DENSENET: '',
                MNIST_CUSTOM_CNN: '',
                IRIS_DENSENET: '',

                pretrained_model_label: '',
                MNIST_MODEL: '',
                
                // 언어 설정 
                localization: ''

            }
        },
        methods: {},
        mounted() {
            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;


            // localization 반영
            this.localization = locale.substring(0, 2);
            
            // 언어 설정 테스트
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.node_config_label = korean.trainmodel_dock.node_config_label;
                    this.data_input_image = korean.trainmodel_dock.data_input_image;
                    this.data_input_default = korean.trainmodel_dock.data_input_default;
                    this.data_input_webcam = korean.trainmodel_dock.data_input_webcam;
                    this.model_save = korean.trainmodel_dock.model_save;
                    this.data_monitoring = korean.trainmodel_dock.data_monitoring;
                    this.model_monitoring = korean.trainmodel_dock.model_monitoring;
                    this.data_provided_label = korean.trainmodel_dock.data_provided_label;
                    this.MNIST_DATA = korean.trainmodel_dock.MNIST_DATA;
                    this.IRIS_DATA = korean.trainmodel_dock.IRIS_DATA;
                    this.default_model_label = korean.trainmodel_dock.default_model_label;
                    this.MNIST_CONVNET = korean.trainmodel_dock.MNIST_CONVNET;
                    this.MNIST_DENSENET = korean.trainmodel_dock.MNIST_DENSENET;
                    this.MNIST_CUSTOM_CNN = korean.trainmodel_dock.MNIST_CUSTOM_CNN;
                    this.IRIS_DENSENET = korean.trainmodel_dock.IRIS_DENSENET;
                    this.pretrained_model_label = korean.trainmodel_dock.pretrained_model_label;
                    this.MNIST_MODEL = korean.trainmodel_dock.MNIST_MODEL;
                    return;
                case 'en':
                    this.node_config_label = english.trainmodel_dock.node_config_label;
                    this.data_input_image = english.trainmodel_dock.data_input_image;
                    this.data_input_default = english.trainmodel_dock.data_input_default;
                    this.data_input_webcam = english.trainmodel_dock.data_input_webcam;
                    this.model_save = english.trainmodel_dock.model_save;
                    this.data_monitoring = english.trainmodel_dock.data_monitoring;
                    this.model_monitoring = english.trainmodel_dock.model_monitoring;
                    this.data_provided_label = english.trainmodel_dock.data_provided_label;
                    this.MNIST_DATA = english.trainmodel_dock.MNIST_DATA;
                    this.IRIS_DATA = english.trainmodel_dock.IRIS_DATA;
                    this.default_model_label = english.trainmodel_dock.default_model_label;
                    this.MNIST_CONVNET = english.trainmodel_dock.MNIST_CONVNET;
                    this.MNIST_DENSENET = english.trainmodel_dock.MNIST_DENSENET;
                    this.MNIST_CUSTOM_CNN = english.trainmodel_dock.MNIST_CUSTOM_CNN;
                    this.IRIS_DENSENET = english.trainmodel_dock.IRIS_DENSENET;
                    this.pretrained_model_label = english.trainmodel_dock.pretrained_model_label;
                    this.MNIST_MODEL = english.trainmodel_dock.MNIST_MODEL;
                    return;
            }
        }
    }
</script>

<style scoped>
    .dock {
        width: 300px !important;
        height: 95% !important;
        overflow-y: auto !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        text-align: center !important;
        background-color: #ffffff !important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
    }

    @media screen and (min-height: 1080px) {
        .dock {
            height: 100% !important;
        }
    }



    .dock .node {
        cursor: pointer;
        height: 45px !important;
        display: flex;
        align-items: center !important;
        border: 1px solid #ccc !important;
        border-top: none !important;
        border-radius: 6px !important;
        box-shadow: 1px 1px 2px #ddd;
        color: #ccc !important;
        width: 210px !important;
    }


    /*.dock-item .node {*/

    /*    cursor: pointer ;*/

    /*    height: 45px !important;*/

    /*    display: flex;*/

    /*    align-items: center !important;*/

    /*    border: 1px solid #ccc !important;*/

    /*    border-top: none !important;*/

    /*    border-radius: 6px !important;*/

    /*    box-shadow: 1px 1px 2px #ddd;*/

    /*    color: #ccc !important;*/

    /*    width: 210px !important;*/

    /*}*/

    .dock .node span {
        height: 30px !important;
        width: 30px !important;
        margin-left: 5px;
    }

    .dock .node .title {
        margin-left: 5px !important;
    }

    .title {
        font-size: 13px;
    }
</style>
