<template>
    <!-- 계정 생성 모달 내용 부분 -->
    <div class="signup-outer">
        <label>REGISTER</label>
        <!--계정 생성 폼 부분-->
        <b-form class="form-signup" @submit="onRegister">
            <!--계정 생성 폼 내용 부분-->
            <b-form-group id="signup-group">
                <!--이메일 입력 부분-->
                <b-form-input id="signup-email" class="form-control" type="email" v-model="signupform.email" required placeholder="Enter email"></b-form-input>
                <!--패스워드 입력 부분-->
                <b-form-input id="signup-password" class="form-control" type="password" v-model="signupform.password" required placeholder="Enter Password"></b-form-input>
                <!--닉네임 혹은 이름 입력 부분-->
                <b-form-input id="signup-name" class="form-control" v-model="signupform.name" required placeholder="Enter name"></b-form-input>
            </b-form-group>
            <!--계정 생성 폼 내용 부분 end-->
            
            <!-- 생성한 계정 등록 버튼 부분 -->
            <div class="register_button_group">
                <b-button class="signup-button" type="submit" variant="primary">Register</b-button>
            </div>
            <!--생성한 계정 등록 버튼 부분 end-->
        </b-form>
        <!--계정 생성 폼 부분 end-->
    </div>
    <!--계정 생성 모달 내용 부분 end-->
</template>
<script>
    export default {
        name: 'SignupForm',
        data() {
            return {
                // 계정 생성 데이터
                signupform: {
                    email: '',
                    name: '',
                    password: '',
                },
            }
        },
        methods: {
            // 회원 등록 부분 
            onRegister(e) {
                e.preventDefault();

                //입력된 계정 생성 데이터 변수로 저장 
                const RegisterformData = {
                    email: this.signupform.email,
                    name: this.signupform.name,
                    password: this.signupform.password,
                };
                
                // 등록후 로그인 페이지로 돌아가기
                // this.$router.push(-1);
                // 페이지 뒤로가기설정 부분이므로 사용하지 않음

                // 등록후 모달창 지우기
                this.$emit('close_signup_modal');

                // axios를 통한 회원 가입 =============================
                //
                // axios.post('https://<project>url', RegisterformData)
                //   .then(response => console.log(response))
                //   .catch(error => console.log(error))
                //
                // ====================================================
            },
        },
        mounted(){
            
        }
    }
</script>
<style scoped>
    
    .signup-outer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .signup-outer label {
        font-size: 20px;
        font-weight: bold;
    }

    .form-signup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .modal-dialog input {
        width: 300px !important;
        height: 40px !important;
        margin: 5px;
    }

    .modal-dialog .modal-footer {
        display: none !important;
    }
    
    .register_button_group .signup-button {
        margin-left: auto;
    }
    
    
</style>
