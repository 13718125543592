<template>
    <!--파일 선택 탭 부분 -->
    <div class="select_data_part">
        <div class="inference_image">
            <input ref="inference_Image_input" type="file" id="images" multiple="multiple" hidden @change="onChange_Inference_Image">
            <div class="pre_image">
                <canvas ref="output_canvas"  id="output" style=""></canvas>
            </div>
        </div>
        <div class="inference_btn_group">
            <!--파일 업로드 부분 파일 불러오기 버튼-->
            <!--불러오기-->
            <b-button @click="onClick_Inference_Image" variant="primary">{{ inference_file_upload }}</b-button>
            <!--파일 불러오기 버튼 클릭시 모델을 먼저 선택하지 않으면 alert 띄우기-->
            <!--주의!-->
            <b-modal ref="model_select_alert" hide-footer :title="ainomis_alert">
            <!--alert의 내용 부분 -->
            <!--모델 선택 후에 추론을 실행 해주세요!-->
            <label class="model_alert_label">{{ inference_before_model_check_alert }}</label>
            </b-modal>
        </div>
    </div>
    <!-- 파일 선택 탭 부분 end -->
</template>
<script>
    // vuex =====================
    import store from '@/store';
    // ==========================

    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================
    export default {
        name: 'FileUploadComp',
        data() {
            return {
                // 
                inference_file_upload: '',
                ainomis_alert: '',
                inference_before_model_check_alert: '',
                
                // 
                localization: ''
            }
        },
        mounted() {
            /* global navigator */

            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트 
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.inference_file_upload = korean.inference.inference_file_upload;
                    // 파일 추론 경고창
                    this.ainomis_alert = korean.inference.ainomis_alert;
                    this.inference_before_model_check_alert = korean.inference.inference_before_model_check_alert;
                    return;
                case 'en':
                    this.inference_file_upload = english.inference.inference_file_upload;
                    // 파일 추론 경고창
                    this.ainomis_alert = english.inference.ainomis_alert;
                    this.inference_before_model_check_alert = english.inference.inference_before_model_check_alert;
                    return;
            }
        },
        methods: {
            // input image file button click
            onClick_Inference_Image(e) {
                this.$refs.inference_Image_input.click();
            },
            // input image file
            onChange_Inference_Image(e) {
                let files = e.target.files;

                ///=============SEAN========
                const selected_img = files[0];
                var fr = new FileReader();
                fr.onload = (e) => {
                    let data = e.target.result;

                    // 선택된 모델이 없을때 ALERT // 선택된 모델이 있을때는 실행
                    // if (this.selected_pretrain_model === null) {
                    //     this.$refs['model_select_alert'].show();
                    // }
                    // else {
                        var img = new Image();
                        img.onload = (e) => {
                            let data = e.target.result;
                            const canvas = this.$refs['output_canvas'];
                            canvas.width = 350; // set canvas size big enough for the image
                            canvas.height = 350;
                            var ctx = canvas.getContext("2d");

                            var gap = 0;
                            if (img.width > img.height) {
                                gap = (img.width - img.height) / 2;
                                ctx.drawImage(img, gap, 0, img.height, img.height, 0, 0, 350, 350); // draw the image
                            }
                            else {
                                gap = (img.height - img.width) / 2;
                                ctx.drawImage(img, 0, gap, img.width, img.width, 0, 0, 350, 350); // draw the image
                            }

                            canvas.toDataURL("image/png"); // get the data URL

                            // inversion check 
                            store.dispatch('call_inversion', { inversion: false });
                            var dataURL = canvas.toDataURL();
                            // this.session_Inference_Image = dataURL;
                            sessionStorage.setItem('session_Inference_Image', dataURL);
                            console.log("save to sessionstorage");
                            // 추론 실행 
                            // this.run();
                            this.$emit('file_upload');
                        }
                        img.src = data;

                    // }
                }
                fr.readAsDataURL(selected_img);
            },
        }
    }
</script>
<style scoped>
    .select_data_part {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*margin-top: 5.8%;*/
    }

    .select_data_part label {
        font-size: 20px;
        font-weight: bold;
    }

    .inference_btn_group {
        width: 350px;
        height: 35px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
    }

    .inference_btn_group button {
        margin-left: auto;
    }

    .inference_image {
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pre_image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 10px;
        padding-left: 3px;
        border-radius: 5px;
        height: 350px;
        width: 350px;
        background: #F5F6FA;
        box-shadow: 1px 1px 2px #ddd;

    }

    .pre_image img {
        width: 350px;
        height: 350px;
        border-radius: 10px;
    }
</style>
