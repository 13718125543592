import Rete from 'rete';
import { numSocket } from '../../sockets';
import VGGControl from '../../controls/named-controls/VGGControl';

export default class VGGComponent extends Rete.Component {
	constructor() {
		super("VGG");
		this.data = {
			render: 'vue'
		};
	}

	builder(node) {
		let input1 = new Rete.Input('input1', 'Input1', numSocket);
		let output1 = new Rete.Output('output1', 'Output1', numSocket);
		let output2 = new Rete.Output('output2', 'Output2', numSocket);
		return node
			.addControl(new VGGControl(this.editor, "num"))
			.addOutput(output1)
			.addOutput(output2)
			.addInput(input1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
