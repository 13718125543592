<template>
    <!--이미지 드로잉 부분-->
    <div class="drawing">
        <!--이미지 캔버스 부분-->
        <canvas id="drawing_Canvas" ref="printMe" width='350' height="350" style="border-radius: 10px;" @mousedown="beginDrawing" @mousemove="keepDrawing" @mouseup="stopDrawing"/>
        <!--드로잉 버튼 부분-->
        <div class="drawing_btn_group">
            <b-button variant="primary" class="drawingSection_Clear_button" @click="drawingSection_Clear">{{ drawing_all_clear }}</b-button>
            <b-button variant="primary" class="drawingSection_Capture_button" @click="drawingSection_Capture">{{ drawing_capture }}</b-button>
        </div>
        <!--드로잉 버튼 부분 END-->
    </div>
    <!--이미지 드로잉 부분 END-->
</template>
<script>
    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================

    export default {
        name: 'drawing',
        data() {
            return {
                x: 0,
                y: 0,
                isDrawing: false,
                canvas: null,
                output: null,

                // 다국어 설정
                drawing_all_clear: '',
                drawing_capture: '',

                // 언어 설정
                localization: '',

            }
        },
        mounted() {
            let drawingSection_Canvas = document.getElementById('drawing_Canvas');
            this.canvas = drawingSection_Canvas.getContext('2d');
            // console.log(this.canvas)

            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.drawing_all_clear = korean.drawing_comp.drawing_all_clear;
                    this.drawing_capture = korean.drawing_comp.drawing_capture;
                    return;

                case 'en':
                    this.drawing_all_clear = english.drawing_comp.drawing_all_clear;
                    this.drawing_capture = english.drawing_comp.drawing_capture;
                    return;
            }

        },
        methods: {
            // 그리기 설정 메소드
            drawLine(x1, y1, x2, y2) {
                let ctx = this.canvas;
                ctx.beginPath();
                ctx.strokeStyle = 'black'; //'skyblue';
                ctx.lineCap = 'round';
                ctx.lineWidth = 20;
                ctx.moveTo(x1, y1);
                ctx.lineTo(x2, y2);
                ctx.stroke();
                ctx.closePath();
            },
            // 그리기 시작
            beginDrawing(e) {
                this.x = e.offsetX;
                this.y = e.offsetY;
                this.isDrawing = true;
            },
            // 그리기 진행
            keepDrawing(e) {
                if (this.isDrawing === true) {
                    this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
                    this.x = e.offsetX;
                    this.y = e.offsetY;
                }
            },
            // 그리기 중지
            stopDrawing(e) {
                if (this.isDrawing === true) {
                    this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
                    this.x = 0;
                    this.y = 0;
                    this.isDrawing = false;
                }
            },
            // 캔버스 PRINT
            drawingSection_Capture() {
                const el = this.$refs.printMe;
                // console.log(el);
                // add option type to get the image version
                // if not provided the promise will return 
                // the canvas.
                const options = {
                    type: 'dataURL'
                };
                (async() => {
                    this.output = await this.$html2canvas(el, options);
                    let session_Inference_Image = this.output;

                    // 세션스토리지 저장
                    sessionStorage.setItem('session_Inference_Image', session_Inference_Image);

                    // 상위 페이지 EVENT EMIT
                    this.$emit('drawing_capture');

                })()
            },
            // 캔버스 초기화
            drawingSection_Clear() {
                // document.getElementById("drawing_Canvas").style.background = '#000000';   
                let drawingSection_Canvas = document.getElementById("drawing_Canvas");
                let context = drawingSection_Canvas.getContext("2d");

                context.clearRect(0, 0, drawingSection_Canvas.width, drawingSection_Canvas.height);
            },
        },
    }
</script>
<style scoped>
    .drawing {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .drawing_label {
        display: flex;
        align-items: center;
        width: 350px;
        height: 50px;
    }

    .drawing_label label {
        font-size: 20px;
        font-weight: bold;

    }

    .drawing_btn_group {
        display: flex;
        width: 350px;
        height: 40px;
    }

    .drawingSection_Clear_button {
        width: 100px;
        height: 35px;
        font-size: 15px;
        margin-left: 150px;
    }

    .drawingSection_Capture_button {
        width: 100px;
        height: 35px;
        font-size: 15px;
        margin-left: 10px;
    }

    /*.drawing button {*/

    /*    width: 100px;*/

    /*    font-size: 15px;*/

    /*    margin: 10px;*/

    /*}*/

    #drawing_Canvas {
        border: 2px solid #eeeeee;
        background: #FFFFFF;
        width: 350px;
        height: 350px;
        margin-bottom: 10px;
    }


    /*@media screen and (max-width: 1633px) {*/

    /*    .drawing {*/

    /*        margin-top: 18.5%;*/

    /*    }*/

    /*    #drawing_Canvas {*/

    /*        width: 300px;*/

    /*        height: 300px;*/

    /*    }*/

    /*    .drawing_btn_group {*/

    /*        display: flex;*/

    /*        width: 300px;*/

    /*        height: 40px;*/

    /*    }*/

    /*    .drawingSection_Clear_button {*/

    /*        font-size: 13px;*/

    /*        margin-left: 100px;*/

    /*    }*/

    /*    .drawingSection_Capture_button {*/

    /*        font-size: 13px;*/

    /*    }*/

    /*}*/
</style>
