<template>
<!--에디터 화면 메인-->
<div class="main">	
	<!--노드 도크 부분-->
	<dockitem></dockitem>
	<!--노드 에디터 부분-->
	<div class="editor">
		<!--노드 트레이너 부분-->
		<div class="dropdown-group">
			<div class="trainer-title">
				<!--모델 만들기 부분 모델 트레이너 버튼-->
				<!--트레이너-->
				<label class="trainer">{{ trainer_label }}</label>
				<!--rounded="sm"-->
				<b-overlay class="trainer_overlay" :show="show" rounded opacity="0.6" spinner-small spinner-variant="primary">
					<b-icon ref="show" :disabled="show" icon="play-fill" id="training" class="training-btn" v-b-tooltip.hover="'Model Train Button'" @click="model_training"></b-icon>			
				</b-overlay>
		        <component :key="componentkey" v-bind:is="model_training_component"></component>
		        
		        <!--트레이너 버튼 알람-->
		        <!--트레이너 버튼 클릭시 동작중임을 알려주는 알람 및 스피너 동작-->
		        <!--알람 타이틀: 주의!-->
		        <b-modal ref="trainer_button_alert" hide-footer :title="ainomis_alert" v-model="show">
		        	<div class="alert_div">
		        		<!--트레이너 버튼 알람 내용-->
		        		<!--탭을 닫거나 웹 브라우저를 종료시키지 마십시오.-->
		        		<label class="alert_label">{{ trainer_alert_1 }}<br/> 
		        		<!--해당 트레이닝이 비정상적으로 종료됩니다.-->
                		{{ trainer_alert_2 }}</label>
		        	</div>
                </b-modal>
		        
		        <!--노드 연결 확인 알람-->
		        <!--트레이닝 전 데이터 노드의 연결 확인 알람-->
		        <!--알람 타이틀: 주의!-->
				<b-modal ref="node_connect_alert" hide-footer :title="ainomis_alert">
					<div class="alert_div">
						<!--노드 연결 알람 내용-->
						<!--데이터 연결 혹은 모델 연결을 확인해 주세요!-->
						<label class="alert_label">{{ node_connected_alert }}</label>
					</div>
                </b-modal>	
            </div>
			<div class="dropdown-optimizer">
				<!--상단 하이퍼 파라미터 적용 옵티마이저 드롭다운 -->
				<!--옵티마이저-->
				<label>{{ optimizer_label }}</label>
				<select v-model="optimizer_selected">
					<option v-for="option in optimizer_options" v-bind:value="option.value">
						{{ option.text }}
					</option>
				</select>
			</div>
			<div class="dropdown-loss">
				<!--상단 하이퍼 파라미터 적용 손실함수 드롭다운-->
				<!--손실 함수-->
				<label>{{ loss_label }}</label>
				<select v-model="loss_selected" id="loss_select">
					<option v-for="option in loss_options" v-bind:value="option.value">
						{{ option.text }}
					</option>
				</select>
			</div>
			<div class="dropdown-epoch">
				<!--상단 하이퍼 파라미터 적용 에폭 드롭다운-->
				<!--에폭-->
				<label>{{ epoch_label }}</label> 
				<input type="number" v-model.number="epoch_input"></input>
			</div>
			<div class="dropdown-batch">
				<!--상단 하이퍼 파라미터 적용 배치 드롭다운-->
				<!--배치-->
				<label>{{ batch_label }}</label>
				<select v-model="batch_selected">
					<option v-for="option in batch_options" v-bind:value="option.value">
						{{ option.text }}
					</option>
				</select>
			</div>
			<div class="checkbox-group">
				<b-form-group>
					<b-form-checkbox-group
						id="checkbox-group-1"
						v-model="selected"
						:options="checkoptions"
						name="automodify">
					</b-form-checkbox-group>
				</b-form-group>
			</div>
			<div class="inference_group">
				<!--상단 하이퍼 파라미터 우측 추론 모달 클릭 버튼-->
				<!--추론-->
				<b-button id="inference-btn" variant="primary" @click="show_trained_inference_modal">{{ inference_modal_click }}</b-button>
				<b-modal ref="inference-modal" centered hide-header hide-footer size="xl">
				  	<ModalInference v-on:close_btn="hide_trained_inference_modal"/>
                </b-modal>
                <!--모델 만들기 부분 추론 하기 전 체크 알람 -->
                <b-modal ref="alert-modal" hide-footer :title="ainomis_alert">
                	<div class="alert_div">
                		<!--모델 만들기 부분 추론 알람 내용-->
                		<!--모델 훈련 후에 추론을 실행 해주세요!-->
                		<label class="alert_label">{{ inference_modal_click_alert }}</label>
                	</div>
                </b-modal>
			</div>
			<!--상단 하이퍼 파라미터 우측 훈련 중 그래프 확인 가능한 tfjs-vis 보기 설정 -->
			<!--훈련 그래프-->
			<b-button variant="primary" @click="open_right_tfjsvisor">{{ tfjs_visor_click }}</b-button>
		</div>
		<!-- rete.js 에디터 부분 -->
		<!--rete_editor_height-->
		<div id="rete" v-bind:style="{ height: rete_editor_height }">
			<!-- rete.js 에디터 상단 버튼 부분-->
			<div class="rete-top-area">
				<div class="operation-btn-group">
					<b-icon icon="justify" id="arrangebtn" class="arrange-btn" v-b-tooltip.hover="node_module_cleanup"></b-icon>
				  	<b-icon icon="arrow-counterclockwise" id="undobtn" class="undo-btn" v-b-tooltip.hover.bottom="rete_undo"></b-icon>
				  	<b-icon icon="arrow-clockwise" id="redobtn" class="redo-btn" v-b-tooltip.hover.bottom="rete_redo"></b-icon>
				    <b-icon icon="code" id="jsonbtn" class="json-btn" v-b-tooltip.hover.bottom="json_console_check"></b-icon>
				    <b-icon icon="cloud-download" id="downloadbtn" class="download-btn"v-b-tooltip.hover.bottom="rete_download"></b-icon>
				    <b-icon icon="trash" id="trashbtn" class="trash-btn" v-b-tooltip.hover.bottom="node_module_delete"></b-icon>
				</div>
			</div>
		</div>
		
		<!-- 데이터 모니터링 팝업 MNIST -->
		<dialog-drag id="dialog_mnist_sum" title="데이터 상세" :options="mnist_sum_options" v-show="dialog_mnist_sum" @close="close_mnist_sum">
			<Mnistdatasummary/>
		</dialog-drag>
		<dialog-drag id="dialog_mnist_image" title="데이터 상세" :options="mnist_image_options" v-show="dialog_mnist_image" @close="close_mnist_image">
			<MnistImage/>
		</dialog-drag>
		
		<!-- CSV 파일 업로드 팝업 TEST -->
		<!--<dialog-drag id="dialog_csv_test" :options="csv_test_options">-->
			<!--<input type="file" @change="readCSVfile"/>	-->
			<!--<b-button @click="readCSV_S3">readCSV_S3</b-button>-->
			<!--<b-table sticky-header :items="csv_iris_data" head-variant="light" class="iris_csv_table"></b-table>-->
		<!--</dialog-drag>-->
		
		
		<!-- 데이터 모니터링 팝업 MNIST END -->
		<!-- 데이터 모니터링 팝업 IRIS-->
		
		<!-- 아이리스 -->
		<dialog-drag id="dialog_iris_sum" title="데이터 상세" :options="iris_sum_options" v-show="dialog_iris_sum" @close="close_iris_sum">
			<Irisdatasummary/>
		</dialog-drag>
		
		<dialog-drag id="dialog_iris_table" title="데이터 상세" :options="iris_table_options" v-show="dialog_iris_table" @close="close_iris_table">
			<IrisTable/>
		</dialog-drag>
		<!-- 데이터 모니터링 팝업 IRIS END-->
	</div>
</div>
</template>

<script>
	// rete.js 불러오기
	import {
		createFlowEditor
	}
	from '@/rete-editor/index';

	import DialogDrag from 'vue-dialog-drag'


	// component =============================================================

	// 왼쪽 docking item
	import dockitem from './Nodedock/TrainCompDock';

	// 모델 훈련 부분
	import ModelTraining from './ModelTraining/ModelTraining';

	// 모달 추론 부분
	import ModalInference from './TrainAfterInference/TrainAfterInference_Modal';

	// 하단 시각화 부분
	import MnistImage from './Visualization/MnistImageComponent';
	import Mnistdatasummary from './Visualization/Mnistdatasummary';
	import Irisdatasummary from './Visualization/Irisdatasummary';
	import Datagraph from './Visualization/DataGraphComponent';
	// import DataTable from './Visualization/DataTableComponent';
	import IrisTable from './Visualization/IrisTable';

	// vuex
	import { mapGetters, mapMutations, mapActions } from 'vuex';
	import store from '@/store';

	// tfjs-vis 
	import * as tfvis from '@tensorflow/tfjs-vis';

	// 언어 설정 불러오기
	import korean from '@/language/korean.json';
	import english from '@/language/english.json';


	export default {
		name: 'rete-editor',
		data() {
			return {
				selected: null,
				optimizer_selected: 'adam',
				loss_selected: 'categoricalCrossentropy',
				batch_selected: '512',
				epoch_input: '5',
				optimizer_options: [
					{ text: 'ADAM(아담)', value: 'adam' },
					{ text: 'SGD', value: 'sgd' },
					{ text: 'MOMENTUM', value: 'momentum' },
					{ text: 'AdaGrad', value: 'adagrad' },
					{ text: 'AdaDelta', value: 'adadelta' },
					{ text: 'AdaMax', value: 'adamax' },
					{ text: 'RMSProp', value: 'rmsprop' },
				],
				loss_options: [
					{ text: '크로스엔트로피에러', value: 'categoricalCrossentropy' },
					{ text: '평균제곱오차', value: 'meanSquaredError' },
					{ text: '절대차', value: 'absoluteDifference' },
					{ text: '가중손실', value: 'computeWeightedLoss' },
					{ text: '힌지손실', value: 'hingeLoss' },
					{ text: '허버손실', value: 'huberLoss' },
					{ text: '로그손실', value: 'logLoss' },
					{ text: '시그모이드크로스엔트로피', value: 'sigmoidCrossEntropy' },
					{ text: '소프트맥스크로그엔트로피', value: 'softmaxCrossEntropy ' },
				],
				batch_options: [
					{ text: '512', value: '512' },
					{ text: '256', value: '256' },
					{ text: '128', value: '128' },
					{ text: '64', value: '64' },
					{ text: '32', value: '32' },
					{ text: '16', value: '16' }
				],
				epoch_options: [
					{ text: '50', value: '50' },
					{ text: '3', value: '3' },
					{ text: '2', value: '2' },
					{ text: '1', value: '1' },
				],
				checkoptions: [
					{ text: 'GPU 사용', value: '' },
				],
				model_training_component: '',
				visible: false,
				height: '90%',
				componentkey: 0,

				// 다국어 설정 
				trainer_label: '',
				ainomis_alert: '',
				trainer_alert_1: '',
				trainer_alert_2: '',
				node_connected_alert: '',
				optimizer_label: '',
				loss_label: '',
				epoch_label: '',
				batch_label: '',
				inference_modal_click: '',
				inference_modal_click_alert: '',
				tfjs_visor_click: '',


				//rete button click
				node_module_cleanup: '',
				rete_undo: '',
				rete_redo: '',
				json_console_check: '',
				rete_download: '',
				node_module_delete: '',

				// 언어 설정
				localization: '',

				availability: {},

				// 데이터 모니터링 팝업 테스트 // ======================

				mnist_sum_options: {
					buttonPin: false,
					x: 390,
					y: 240,
					width: 300,
					height: 300,
				},

				mnist_image_options: {
					buttonPin: false,
					x: 700,
					y: 240,
					width: 600,
					height: 300
				},

				iris_sum_options: {
					buttonPin: false,
					x: 390,
					y: 240,
					width: 300,
					height: 300,
				},

				iris_table_options: {
					buttonPin: false,
					x: 700,
					y: 240,
					width: 600,
					height: 300
				},

				csv_test_options: {
					buttonPin: false,
					x: 1020,
					y: 240,
					width: 600,
					height: 300
				},

				items: null,
				// =====================================================

			}
		},
		// 컴포넌트 등록
		components: {
			dockitem,
			Datagraph,
			IrisTable,
			ModelTraining,
			MnistImage,
			Mnistdatasummary,
			Irisdatasummary,
			ModalInference,
			DialogDrag,
		},
		mounted() {
			createFlowEditor();
			// 트레이너 부분 기본값 설정
			store.dispatch('call_optimizer', { optimizer: this.optimizer_selected });
			store.dispatch('call_loss', { loss: this.loss_selected });
			store.dispatch('call_epoch', { epoch: this.epoch_input });
			store.dispatch('call_batch_size', { batch_size: this.batch_selected });

			/* global navigator */
			let locale = navigator.language || navigator.userLanguage;

			this.localization = locale.substring(0, 2);

			// 언어 설정 테스트
			// this.localization = 'en';

			switch (this.localization) {
				case 'ko':
					this.trainer_label = korean.trainmodel.trainer_label;
					this.ainomis_alert = korean.trainmodel.ainomis_alert;
					this.trainer_alert_1 = korean.trainmodel.trainer_alert_1;
					this.trainer_alert_2 = korean.trainmodel.trainer_alert_2;
					this.node_connected_alert = korean.trainmodel.node_connected_alert;
					this.optimizer_label = korean.trainmodel.optimizer_label;
					this.loss_label = korean.trainmodel.loss_label;
					this.epoch_label = korean.trainmodel.epoch_label;
					this.batch_label = korean.trainmodel.batch_label;
					this.inference_modal_click = korean.trainmodel.inference_modal_click;
					this.inference_modal_click_alert = korean.trainmodel.inference_modal_click_alert;
					this.tfjs_visor_click = korean.trainmodel.tfjs_visor_click;
					this.node_module_cleanup = korean.trainmodel.node_module_cleanup;
					this.rete_undo = korean.trainmodel.rete_undo;
					this.rete_redo = korean.trainmodel.rete_redo;
					this.node_module_delete = korean.trainmodel.node_module_delete;
					return;

				case 'en':
					this.trainer_label = english.trainmodel.trainer_label;
					this.ainomis_alert = english.trainmodel.ainomis_alert;
					this.trainer_alert_1 = english.trainmodel.trainer_alert_1;
					this.trainer_alert_2 = english.trainmodel.trainer_alert_2;
					this.node_connected_alert = english.trainmodel.node_connected_alert;
					this.optimizer_label = english.trainmodel.optimizer_label;
					this.loss_label = english.trainmodel.loss_label;
					this.epoch_label = english.trainmodel.epoch_label;
					this.batch_label = english.trainmodel.batch_label;
					this.inference_modal_click = english.trainmodel.inference_modal_click;
					this.inference_modal_click_alert = english.trainmodel.inference_modal_click_alert;
					this.tfjs_visor_click = english.trainmodel.tfjs_visor_click;
					this.node_module_cleanup = english.trainmodel.node_module_cleanup;
					this.rete_undo = english.trainmodel.rete_undo;
					this.rete_redo = english.trainmodel.rete_redo;
					this.node_module_delete = korean.trainmodel.node_module_delete;
					return;
			}


		},
		updated() {
			// 트레이너 부분 바뀐 값 설정
			store.dispatch('call_optimizer', { optimizer: this.optimizer_selected });
			store.dispatch('call_loss', { loss: this.loss_selected });
			store.dispatch('call_epoch', { epoch: this.epoch_input });
			store.dispatch('call_batch_size', { batch_size: this.batch_selected });
		},
		methods: {
			// 추론하기 모달 띄우기
			show_trained_inference_modal() {

				/* global localStorage */
				// 훈련 후 저장된 이름 가져오기
				let saved_model_name = store.getters.get_model_name;

				// Chrome Web Localstroage에서 훈련 후 저장된 이름이 있는 내용 가져오기
				let localstorage_saved_model = localStorage.getItem('tensorflowjs_models/' + saved_model_name + '/info');

				//==========================================
				// Chrome Web Localstorage 에 훈련 후 저장된 모델이 있으면 추론 모달창 띄우기 / 없으면 alert 창!
				if (localstorage_saved_model === null) {
					this.$refs['alert-modal'].show();
				}
				else {
					this.$refs['inference-modal'].show();
				}
			},
			// 추론하기 모달 숨기기
			hide_trained_inference_modal() {
				this.$refs['inference-modal'].hide()
			},
			// trainer button 실행 (모델 트레이닝 실행)
			// 한번 실행 후 재실행이 안되는 문제 발생
			// ModelTraining 을 컴포넌트화 하여 컴포넌트 key 값 새로운 ModelTraining 컴포넌트를 실행 시켜 문제 해결

			model_training() {

				if (this.data_connect === true && this.model_save_connect === true) {

					// tfjs-visor cotnainer
					let tfjs_visor_container = document.getElementById('tfjs-visor-container');
					let body = document.body;

					// this.show = true;
					if (this.componentkey === 0) {
						this.model_training_component = ModelTraining;
						this.componentkey += 1;
					}
					else {
						if (tfjs_visor_container != null) {
							body.removeChild(tfjs_visor_container);
							this.componentkey += 1;

						}
						else {
							this.componentkey += 1;
						}
					}
				}
				else {
					// 노드 연결 ALERT
					this.$refs['node_connect_alert'].show();
				}
			},

			// 우측 바이저 부분 열기
			open_right_tfjsvisor() {
				tfvis.visor().open();
			},

			// bottom visualization visible / not visible collpase toggle
			collapse_click() {
				this.visible = !this.visible;

				if (this.visible === true) {
					this.height = '50%';
				}
				else {
					this.height = '80%';
				}
			},

			// 데이터 모니터링 팝업 테스트 ========================================================

			// close mnist_sum dialog
			close_mnist_sum() {
				store.dispatch('call_dialog_mnist_sum', { dialog_mnist_sum: false });
			},

			// close mnist_image dialog
			close_mnist_image() {
				store.dispatch('call_dialog_mnist_image', { dialog_mnist_image: false });
			},

			close_iris_sum() {
				store.dispatch('call_dialog_iris_sum', { dialog_iris_sum: false });
			},
			close_iris_table() {
				store.dispatch('call_dialog_iris_table', { dialog_iris_table: false });
			},

			// S3 에서 csv 파일 읽어오기 
			readCSV_S3() {

				// console.log(store.getters.get_iris_data)


				// this.$papa.parse('https://nocoding-ai.s3.ap-northeast-2.amazonaws.com/datasets/iris_csv/iris_data.csv', {
				// 	download: true,
				// 	complete: function(results) {
				// 		// console.log("Finished:", results.data);
				// 		let arr = [];
				// 		let iris = results.data;

				// 		iris.sort(() => {
				// 			return Math.random() - Math.random();
				// 		})

				// 		for (let i = 0; i < 20; i++) {
				// 			for (let j = 0; j < iris[i].length; j++) {
				// 				let iris_kind = '';
				// 				if (iris[i][4] === "0") {
				// 					iris_kind = 'Iris-setosa'
				// 				}
				// 				else if (iris[i][4] === "1") {
				// 					iris_kind = 'Iris-versicolor'
				// 				}
				// 				else if (iris[i][4] === "2") {
				// 					iris_kind = 'Iris-virginica'
				// 				}
				// 				let arr_content = {
				// 					"꽃받침 길이": Number(iris[i][0]),
				// 					"꽃받침 넓이": Number(iris[i][1]),
				// 					"꽃입 길이": Number(iris[i][2]),
				// 					"꽃잎 넓이": Number(iris[i][3]),
				// 					"품종 번호": Number(iris[i][4]),
				// 					"품종 이름": iris_kind,

				// 				}
				// 				arr[i] = arr_content;
				// 			}
				// 		}
				// 		store.dispatch('call_csv_iris_data', {csv_iris_data: arr});
				// 		console.log(arr)
				// 	}
				// });
			},

			// LOCAL에서 INPUT으로 파일 읽어오기 
			readCSVfile(event) {
				// csv_file 변수로 저장
				let csv_file = event.target.files[0];

				// papa parse 이용 하여 csv 파일 변환 
				this.$papa.parse(csv_file, {
					header: true,
					complete: function(results) {
						this.availability = results.data;
					}
				})

			}


			// ====================================================================================
		},
		computed: {
			// 데이터 모니터링 팝업 테스트 =================
			dialog_mnist_sum() {
				return store.getters.get_dialog_mnist_sum;
			},

			dialog_mnist_image() {
				return store.getters.get_dialog_mnist_image;
			},

			dialog_iris_sum() {
				return store.getters.get_dialog_iris_sum;
			},
			dialog_iris_table() {
				return store.getters.get_dialog_iris_table;
			},
			csv_iris_data() {
				return store.getters.get_csv_iris_data;
			},

			// =============================================

			// bottom visualization visible / not visible
			// 데이터 모니터링 노드 모듈이 연결되어 있는지 여부를 판단
			data_monitoring_connect: {
				get() {
					return store.getters.getConnecting
				},
				set(visible) {
					this.visible = visible;
					return this.visible;
				}
			},
			// bottom visualization left component
			leftcom() {
				return store.getters.getleft;
			},
			// bottom visualization right component
			rightcom() {
				return store.getters.getright;
			},

			// rete editor transition height
			rete_editor_height() {
				// return this.height;
				switch (this.visible) {
					case true:
						this.height = "50%";
						return this.height
					case false:
						this.height = "80%";
						return this.height
				}
			},
			data_connect() {
				return store.getters.get_mnist_connect;
			},
			model_save_connect() {
				return store.getters.get_model_save_con;
			},
			show: {
				get() {
					return store.getters.get_show_tf;
				},
				set(show_tf) {
					return show_tf;
				}
			},

		},
	}
</script>
<style src="vue-dialog-drag/dist/vue-dialog-drag.css"></style>

<style scoped>
	.myDiv {
		background-color: #eeeeee;
		height: 200px;
		transition: all ease 1s 0s;
	}

	.alert_div {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.alert_label {
		font-size: 15px;
		font-weight: bold;
		/*color: red;*/
	}

	.iris_csv_table {
		width: 600px;
		height: 300px;
	}

	#dialog_iris_table .dialog-drag .dialog-body {
		padding: none !important;
	}

	.dropdown-group .dropdown-loss select#loss_select option:nth-child(3) {

		background: #ccc;
	}

	.dropdown-group .dropdown-loss select#loss_select option:nth-child(4) {
		background: #ccc;
	}

	.dropdown-group .dropdown-loss select#loss_select option:nth-child(5) {
		background: #ccc;
	}

	.dropdown-group .dropdown-loss select#loss_select option:nth-child(6) {
		background: #ccc;
	}

	.dropdown-group .dropdown-loss select#loss_select option:nth-child(7) {
		background: #ccc;
	}

	.dropdown-group .dropdown-loss select#loss_select option:nth-child(8) {
		background: #ccc;
	}

	.dropdown-group .dropdown-loss select#loss_select option:nth-child(9) {
		background: #ccc;
	}
</style>
