<template>
    <!--로그인 이미지-->
    <div class="loginpage">
        <div class="loginpage_img">
            <!--로그인 페이지 좌측 그림-->
            <img src="@/assets/login_image.png"/>
        </div>
        <!--로그인 작성 부분-->
        <b-card no-header>
            <!--<image src="assets/logo.png"/>-->
            <!-- Login form -->
            <div class="company_name">
                <label>NOCODING AI</label>
            </div>
            <!--회사 이름 부분 end-->
            
            <!--로그인 페이지 환영 인사 부분-->
            <div class="welcome_talk">
                <!-- 로그인 페이지 환영 인사 글귀 -->
                <!-- 한국어 '다시 보게 되어 반갑습니다. 로그인을 해주세요.'-->
                <label>{{ welcome_talk }}</label>
            </div>
            <!--로그인 페이지 환영 인사 부분 end-->
            
            <!-- 로그인 폼 부분 -->
            <b-form class="form-signin" @submit="onClick_DefaultLogin">
                <b-form-group id="signin-group" label-for="signin">
                    <!--아이디 입력 부분-->
                    <b-form-input id="signin-email" type="email" v-model="signinform.email" required placeholder="아이디"></b-form-input>
                    <!--암호 입력 부분-->
                    <b-form-input id="signin-password" type="password" v-model="signinform.password" required placeholder="암호"></b-form-input>
                </b-form-group>
                <!--로그인 정보 기억하기 부분-->
                <div class="remember-group">
                    <!--기억하기 체크 박스-->
                    <div class="checkbox-group">
                        <b-form-checkbox v-model="check" value="remember" unchecked-value= "not-remember"/>
                        <!-- 로그인 정보 기억하기 -->
                        <!-- 기억하기 -->
                        <label>{{ login_inform_remeber_check }}</label>
                    </div>
                    <!-- 비밀번호 찾기 부분 -->
                    <div class="find-account">
                        <!-- 비밀 번호 찾기-->
                        <!-- 암호를 잊으셨습니까? -->
                        <label>{{ question_find_account }}</label>
                    </div>
                </div>
                <!--하단 로그인 버튼 그룹 부분-->
                <div class="login-button-group">
                    <!-- 로그인 버튼 -->
                    <b-button class="signin-button" type="submit">LOGIN</b-button>
                    <!-- 계정 생성 버튼 -->
                    <!-- 계정 생성 -->
                    <b-button variant="outline-dark" @click="show_create_account_form">{{ create_account }}</b-button>
                </div>
                <!--하단 로그인 버튼 그룹 부분 end-->
                    <!--카카오 로그인 이미지 버튼 부분 (한국어 일때)-->
                   <img id="kakaoLogin" v-if="localization === 'ko'" src="../assets/kakao_login_medium_wide.png" @click="onClick_KakaoLogin"/>
                   <!--카카오 로그인 이미지 버튼 부분 (영어 일때)-->
                   <img id="kakaoLogin" v-else-if="localization === 'en'" src="../assets/kakao_login_english_wide.png" @click="onClick_KakaoLogin"/>
                <!--계정 생성 모달 부분-->
                <b-modal ref="signup-modal" centered hide-header hide-footer title="계정생성하기">
                    <!--계정 생성 모달 내용 부분-->
                    <SignupForm v-on:close_signup_modal="hide_create_account_form"/>
                </b-modal>
                <!--계정 생성 모달 end-->
             </b-form>
            <!-- 로그인 폼 부분 end-->
            <!--bottom policy-->
            <div class="bottom_policy">
                <label>Term of use. Privacy policy</label>
            </div><!-- bottom policy end -->
        </b-card><!-- b-card end -->
    </div><!-- loginpage end -->
</template>
<script>
    import axios from 'axios';
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';

    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    // 회원가입 폼 불러오기
    import SignupForm from '@/components/LoginPage/SignupForm';

    export default {
        name: "loginpage",
        data() {
            return {
                signinform: {
                    email: '',
                    password: '',
                },
                check: "not-remember",
                signupform: {
                    email: '',
                    name: '',
                    password: '',
                },

                // 다국어 설정
                welcome_talk: '',
                login_inform_remeber_check: '',
                question_find_account: '',
                create_account: '',

                // 언어 check
                localization: '',
            }
        },
        components: {
            SignupForm
        },
        mounted() {
            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);
            // if (locale !== 'ko') locale = 'en' 
            // 한국어가 아닌 경우 무조건 영어로 설정

            // this.$i18n.locale = locale
            // console.log(locale)

            // this.localization = 'en';

            // 언어 별 분기 적용
            switch (this.localization) {
                // 한국어 일때
                case 'ko':
                    this.welcome_talk = korean.loginPage.welcome_talk;
                    this.Input_Id_placeholder = korean.loginPage.Input_Id_placeholder;
                    this.Input_password_placeholder = korean.loginPage.Input_password_placeholder;
                    this.login_inform_remeber_check = korean.loginPage.login_inform_remeber_check;
                    this.question_find_account = korean.loginPage.question_find_account;
                    this.create_account = korean.loginPage.create_account;
                    return;
                    // 영어 일때
                case 'en':
                    this.welcome_talk = english.loginPage.welcome_talk;
                    this.Input_Id_placeholder = english.loginPage.Input_Id_placeholder;
                    this.Input_password_placeholder = english.loginPage.Input_password_placeholder;
                    this.login_inform_remeber_check = english.loginPage.login_inform_remeber_check;
                    this.question_find_account = english.loginPage.question_find_account;
                    this.create_account = english.loginPage.create_account;
                    return;
            }

        },
        methods: {
            // 일반 로그인 부분
            onClick_DefaultLogin(e) {
                e.preventDefault();
                // alert confirm
                // alert(JSON.stringify(this.form));
                // loginformData 

                // 일반 로그인 폼 양식
                let loginformData = {
                    email: this.signinform.email,
                    password: this.signinform.password,
                };
                // console confirm
                console.log(loginformData);

                // Editor로 이동  
                this.$router.push('workspaces');

                // axios examples
                // axios.post('https://<project>url', loginformData)
                //   .then(response => console.log(response))
                //   .catch(error => console.log(error))	
            },

            // 회원 등록 부분 
            onRegister(e) {
                e.preventDefault();

                //RegisterformData 
                const RegisterformData = {
                    email: this.signupform.email,
                    name: this.signupform.name,
                    password: this.signupform.password,
                };
                // 등록후 로그인 페이지로 돌아가기
                // this.$router.push(-1);

                // 등록후 모달창 지우기
                this.$refs['signup-modal'].hide();
                // axios를 통한 회원 가입
                // axios.post('https://<project>url', RegisterformData)
                //   .then(response => console.log(response))
                //   .catch(error => console.log(error))
            },

            // 카카오 로그인 (No library)
            onClick_KakaoLogin() {
                // console.log(window.Kakao);
                window.Kakao.Auth.login({

                    // port 80 ========================
                    scope: 'account_email, profile',
                    // ================================

                    // port 8080~ ========================================================
                    // scope: 'account_email, profile, gender, age_range, friends, birthday',
                    // ===================================================================
                    success: this.get_Kakao_Information,
                });
            },

            // 로그인 정보 가져오기(카카오에서 가져온 로그인 정보)
            get_Kakao_Information(authObj) {
                // console.log(authObj);
                window.Kakao.API.request({
                    url: '/v2/user/me',
                    success: res => {

                        // res 콘솔로그 확인
                        console.log(res);

                        // 
                        const kakao_account = res.kakao_account;

                        const userInfo = {
                            nickname: kakao_account.profile.nickname,
                            profile_image: kakao_account.profile.profile_image_url,
                            email: kakao_account.email,
                            password: 'not yet password',
                            account_type: 2,
                        }

                        // ===================================================================
                        // BACKEND AND DB 회원관리 POST 부분
                        //  axios.post(`http://localhost:8080/account/kakao`,{
                        //      email : userInfo.email,
                        //      nickname : userInfo.nickname
                        //  })
                        //  .then(res => {
                        //     console.log(res);
                        //     console.log("데이터베이스에 회원 정보가 있음!");
                        //  })
                        //  .catch(err => {
                        //      console.log(err);
                        //     console.log("데이터베이스에 회원 정보가 없음!");
                        //  })
                        // ===================================================================



                        // 받아온 정보를 세션에 넣기위해 변환
                        let session_userInfo = JSON.stringify(userInfo);

                        // 세션에 받아온 정보 저장
                        sessionStorage.setItem("userInfo", session_userInfo);

                        // 저장 후 정보 가져오기
                        let get_session_userInfo = sessionStorage.getItem("userInfo");

                        // 정보 파싱
                        let session_userInfo_parse = JSON.parse(get_session_userInfo);


                        // 세션에 저장한 정보 가져오기
                        // store state에 닉네임 정보 올리기
                        store.dispatch('call_nickname', { user_nickname: session_userInfo_parse.nickname });

                        // store state에 프로필 이미지 정보 올리기
                        store.dispatch('call_profile', { user_profile: session_userInfo_parse.profile_image });


                        // TEST CONSOLE ======================================

                        // console.log(store.getters.getuserInfo);

                        // ===================================================

                        // alert("로그인 성공!");
                        // this.$bvModal.hide("bv-modal-example");

                        this.$router.push('workspaces');
                    },
                    fail: error => {
                        this.$router.push("/errorPage");
                        console.log(error);
                    }
                })
            },
            //modal 보여지기 click
            show_create_account_form() {
                this.$refs['signup-modal'].show();
            },
            //modal 없애기 click
            hide_create_account_form() {
                this.$refs['signup-modal'].hide();
            },
        },
    }
</script>
<style scoped>
    .loginpage {
        display: flex;
        width: 1920px;
        height: 977px;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: #F5F6FA;
    }

    .loginpage_img {
        width: 44%;
        height: 80%;
        display: flex;
        flex-direction: column-reverse;
    }

    .loginpage_img img {
        width: 814px;
        height: 594px;
    }


    .card {
        width: 30%;
        height: 80%;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
    }

    .card-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .company_name {
        margin-top: auto;
    }

    .company_name label {
        font-size: 30px;
        font-weight: bold;
        color: #4E57AA;
    }

    .welcome_talk label {
        color: #858585;
    }

    .form-signin {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
    }

    .card-body input {
        width: 400px !important;
        height: 60px !important;
        margin: 5px;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 2px solid #eeeeee;
        font-size: 15px !important;
    }

    .remember-group {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        margin-left: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .checkbox-group label {
        margin-bottom: none !important;
        font-size: 13px;
    }

    .find-account {
        margin-left: auto;
        margin-right: 10px;
    }

    .find-account label {
        font-size: 13px;
    }

    .find-account label:hover {
        cursor: pointer;
        /*background: #eeeeee;*/
        color: #858585;
    }

    .login-button-group {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .login-button-group button {
        width: 45%;
        height: 50px;
    }

    .signin-button {
        background: #4E57AA;
    }

    .signin-button:hover {
        background: #373E78;
    }


    /*.signin-button {*/

    /*    width: 300px;*/

    /*    height: 45px;*/

    /*    margin-top: 20px;*/

    /*    margin-bottom: 5px;*/

    /*}*/

    #kakaoLogin {
        width: 90%;
        cursor: pointer;
    }

    /*.card-body .signup-modal {*/

    /*width: 300px;*/

    /*height: 45px;*/

    /*margin-bottom: 5px;*/

    /*}*/

    .form-signup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

    }

    .modal-dialog input {
        width: 300px !important;
        height: 40px !important;
        margin: 5px;
    }

    /*.signup-button {*/

    /*    width: 300px;*/

    /*    margin-bottom: 5px;*/

    /*}*/

    .modal-dialog .modal-footer {
        display: none !important;
    }

    .bottom_policy {
        margin-top: auto;
    }

    @media screen and (min-width: 1920px) {
        .loginpage {
            height: 100%;
            width: 100%;
        }

        .card {
            width: 30%;
            height: 80%;
            border-radius: 10px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            transition: 0.3s;
        }
    }
</style>
