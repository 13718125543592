/* eslint-disable no-unused-vars */

// import rete
import Rete from 'rete';

// IMPORT RETE.JS PLUGIN
import VueRenderPlugin from 'rete-vue-render-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import HistoryPlugin from 'rete-history-plugin';
// import KeyBoardPlugin from 'rete-keyboard-plugin';
import AutoArrangePlugin from 'rete-auto-arrange-plugin';
import AreaPlugin from 'rete-area-plugin';



// IMPORT COMPONENT 
import DataInputComponent from './components/layercomponents/DataInputComponent';
import DenseComponent from './components/layercomponents/DenseComponent';
import FlattenComponent from './components/layercomponents/FlattenComponent';
// import DropoutComponent from './components/layercomponents/DropoutComponent';
import DataOutputComponent from './components/layercomponents/DataOutputComponent';

// import 기본 LAYER
import ConvComponent from './components/layercomponents/ConvComponent';
import MaxPoolingComponent from './components/layercomponents/MaxPoolingComponent';

// import DATA JSON FILE
// import data from './data/data.json';
import data from './data/TestCNNdata.json';
import defaultdata from './data/defaultCustom.json';

// VUEX 
import store from '../store';

// RETE EDITOR 
export async function createFlowEditor() {
	// check createFlowEditor
	console.log('rete editor working...');

	// CONTAINER 선언
	let container = document.querySelector('#rete-editor');

	// COMPONENT 등록
	let components = [
		new DataInputComponent(),
		new FlattenComponent(),
		new DenseComponent(),
		// new DropoutComponent(),
		new DataOutputComponent(),
		new ConvComponent(),
		new MaxPoolingComponent()
	];

	// editor 설정 Rete.NodeEditor
	let editor = new Rete.NodeEditor('demo@0.0.1', container);

	// Plugin UMD 설정
	editor.use(ConnectionPlugin);
	editor.use(VueRenderPlugin);


	// 에디터 범위 설정
	editor.use(AreaPlugin, {
		background: false,
		snap: false,
		scaleExtendt: { min: 0.1, max: 1 },
		translateExtent: { width: 5000, height: 4000 }
	});


	editor.use(AutoArrangePlugin, {
		margin: { x: 50, y: 50 },
		depth: 100
	});

	// editor.use(KeyBoardPlugin);

	editor.use(HistoryPlugin, { keyboard: true });



	// engine 설정
	let engine = new Rete.Engine('demo@0.0.1');

	// component들을 editor 와 engine에 register
	components.map(cmp => {
		editor.register(cmp);
		engine.register(cmp);
	});

	// node arrange button
	const btn = document.getElementById('mod-arrangebtn');

	btn.addEventListener('click', btnClick, false);

	function btnClick(e) {
		// console.log('Arranging...');
		editor.trigger('arrange');
		editor.view.resize();
		AreaPlugin.zoomAt(editor, editor.nodes);

	}

	// node history button
	const btn2 = document.getElementById('mod-undobtn');

	btn2.addEventListener('click', btnClick2, false);

	function btnClick2(e) {
		editor.trigger("undo");
	}

	const btn3 = document.getElementById('mod-redobtn');

	btn3.addEventListener('click', btnClick3, false);

	function btnClick3(e) {
		editor.trigger("redo");
	}

	// JSON 형식으로 콘솔 찍기 (TEST 용)
	const btn4 = document.getElementById('mod-jsonbtn');

	btn4.addEventListener('click', btnclick4, false);

	function btnclick4(e) {
		e.preventDefault();
		const editordata = editor.toJSON();

		console.log(editordata);

		const json = JSON.stringify(editordata);

		console.log(json)
		// sessionStorage.setItem('editdata', editdata)

	}
	
	// 노드 지우기 버튼 
	const btn6 = document.getElementById('mod-trashbtn');

	btn6.addEventListener('click', btnClick6, false);

	function btnClick6(e) {
		e.preventDefault();
		switch (e.type) {
			case 'click':
				editor.selected.each(n => editor.removeNode(n));
				break;
			default:
				break;
		}
	}

	// 모달 닫기 버튼
	const close_btn = document.getElementById('mod-closebtn');

	close_btn.addEventListener('click', close_click, false);

	function close_click(e) {
		e.preventDefault();
		save_model();
	}
	
	// 모달 닫기 버튼이 눌릴때 동시에 저장
	function save_model() {
		
		// 선택된 노드의 ID
		let c_num = store.getters.getNodeSelected;

		// 세션에 저장된 모달 데이터를 선택된 노드의 ID를 통하여 가져오기
		let model_data = sessionStorage.getItem("modaldata" + c_num);

		// JSON PARSING
		let model_json = JSON.parse(model_data);
		
		// JSON NODE
		let mod_json = model_json.nodes;

		// NODE KEY
		let model_key = Object.keys(mod_json);
		
		// NODE VALUE
		let model_value = Object.values(mod_json);

		
		// 순서 CHECK ARRAY
		let arr = [];

		for (let i = 0; i < model_key.length; i++) {
			if (i === 0) {
				arr[0] = model_value[0].id;
			}
			else if (i != 0) {

				if (i === 1) {
					arr[i] = model_value[i - 1].outputs.output1.connections[0].node;
				}
				else if (i >= 1) {
					arr[i] = mod_json[arr[i - 1]].outputs.output1.connections[0].node;
				}
			}
		}
		let model_numbering = JSON.stringify(arr);

		// model layer length
		store.dispatch('call_model_layer', { model_layer: arr.length - 2 });

		// 모델 순서 세션 저장
		sessionStorage.setItem('model_numbering' + c_num, model_numbering);


		for (let j = 0; j < arr.length; j++) {

			// let t = Object.keys(mod_json[arr[j]].data).length;

			mod_json[arr[j]].data = JSON.parse(sessionStorage.getItem(arr[j]));

		}

		// if (store.geters.get_model_node_name === 'MNIST ConvNet') {
		// 	sessionStorage.setItem("modaldata" + c_num, model_data);
		// }
		// else {

		// 	sessionStorage.setItem("modaldata" + c_num, JSON.stringify(model_json));

		// 	for (let k = 0; k < arr.length; k++) {
		// 		sessionStorage.removeItem(arr[k]);
		// 	}

		// }

		// ---------------------------- main MNIST CONV 에서 에러 발생----------------

		sessionStorage.setItem("modaldata" + c_num, JSON.stringify(model_json));

		for (let k = 0; k < arr.length; k++) {
			sessionStorage.removeItem(arr[k]);
		}
		// ===========================================================================
		store.dispatch('call_mod_node_id', { mod_node_id: '' });
	}

	// editor.on('connectioncreate', con => {
	// 	console.log(con);
	// })


	document.querySelectorAll('.modal-body .dock .node').forEach(item => {
		item.addEventListener('click', event => {

			switch (item.id) {

				case "datainput":
					components[0].createNode().then(n => editor.addNode(n));
					return;

				case "flatten":
					components[1].createNode().then(n => editor.addNode(n));
					return;


				case "dense":
					components[2].createNode().then(n => editor.addNode(n));
					return;

					// case "dropout":
					// 	components[3].createNode().then(n => editor.addNode(n));
					// 	return;

				case "dataoutput":
					components[3].createNode().then(n => editor.addNode(n));
					return;

				case "conv2d":
					components[4].createNode().then(n => editor.addNode(n));
					return;

				case "maxpooling2d":
					components[5].createNode().then(n => editor.addNode(n));
					return;
			}
		})
	})


	// json 파일로 불러오는 컴포넌트 설정
	// nodecreated noderemoved connectioncreated connectionremoved connectionpath
	editor.on('process', async() => {
		console.log('processing...');
		await engine.process(editor.toJSON());
		await AreaPlugin.zoomAt(editor, editor.nodes);
		await editor.view.resize();
		await engine.abort();
	});


	let c_num = store.getters.getNodeSelected;

	let get_modal_data = sessionStorage.getItem('modaldata' + c_num);

	let get_modal_json = JSON.parse(get_modal_data);

	if (get_modal_json === null) {

		// 비어있으면 초기값 넣기
		const editordata = editor.toJSON();

		let modaldata = JSON.stringify(editordata);

		sessionStorage.setItem('modaldata' + c_num, modaldata);

		// 넣은 초깃값이 들어있는 key 값을 통한 데이터 가져와 화면에 보여지기
		let get_new_data = sessionStorage.getItem('modaldata' + c_num);

		let new_data_json = JSON.parse(get_new_data);

		await editor.fromJSON(new_data_json);
		await AreaPlugin.zoomAt(editor, editor.nodes);
	}
	else {

		// 데이터가 존재하면 불러오기
		let get_exist_data = sessionStorage.getItem('modaldata' + c_num);


		let modaldata = JSON.parse(get_exist_data);

		editor.on('nodecreate', node => {

			let node_id = node.id;
			let node_name = node.name;

			switch (node_name) {

				case "데이터입력":
					store.dispatch('call_datainput', { datanode: node_name + node_id });
					store.dispatch('call_datainput_id', { datanode_id: node_id });
					return;

				case "conv2d":
					store.dispatch('call_convnode', { convnode: node_name + node_id });
					store.dispatch('call_convnode_id', { convnode_id: node_id });
					return;

				case "maxPooling2d":
					store.dispatch('call_maxpoolnode', { maxpoolnode: node_name + node_id });
					store.dispatch('call_maxpoolnode_id', { maxpoolnode_id: node_id });
					return;

				case "dense":
					store.dispatch('call_densenode', { densenode: node_name + node_id });
					store.dispatch('call_densenode_id', { densenode_id: node_id });
					return;

			}
		})

		await editor.fromJSON(modaldata);
		await AreaPlugin.zoomAt(editor, editor.nodes);

	}

	editor.on("nodeselect", node => {

		// modal 에서 선택된 노드의 위치 정보
		store.dispatch('call_mod_node_id', { mod_node_id: node.id });
	});


	editor.on("noderemoved", node => {

		let node_id = node.id;
		let node_name = node.name;

		sessionStorage.removeItem(node_name + node_id);
	})


	// 노드, 커낵션 생성시 세션에 데이터 저장
	editor.on('nodecreate nodecreated connectioncreate connectioncreated noderemove noderemoved connectionremoved nodedraged', node => {

		const editordata = editor.toJSON();

		let modaldata = JSON.stringify(editordata);

		sessionStorage.setItem('modaldata' + c_num, modaldata);

		// AreaPlugin.zoomAt(editor, editor.nodes);

		engine.abort();
	})


}
