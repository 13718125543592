<template>
	<div class="mod-specific">
		<dockitem></dockitem>
		<div class="mod-rete">
			<div class="mod-operation-btn-group">
				<b-icon icon="justify" id="mod-arrangebtn" class="arrange-btn" v-b-tooltip.hover="node_module_cleanup"></b-icon>
			  	<b-icon icon="arrow-counterclockwise" id="mod-undobtn" class="undo-btn" v-b-tooltip.hover.bottom="rete_undo"></b-icon>
			  	<b-icon icon="arrow-clockwise" id="mod-redobtn" class="redo-btn" v-b-tooltip.hover.bottom="rete_redo"></b-icon>
			    <b-icon icon="code" id="mod-jsonbtn" class="json-btn" v-b-tooltip.hover.bottom="json_console_check"></b-icon>
			    <!--<b-icon icon="cloud-download" id="mod-downloadbtn" class="download-btn"v-b-tooltip.hover.bottom="'Download json file button'"></b-icon>-->
			    <b-icon icon="trash" id="mod-trashbtn" class="trash-btn" v-b-tooltip.hover.bottom="rete_download"></b-icon>
			    <b-icon icon="x" id="mod-closebtn" class="close-btn" v-b-tooltip.hover.bottom="node_module_delete" v-on:click="close_btn"></b-icon> 
			     <!--v-on:click="close_btn"-->
			</div>
			<div id="rete-editor">
			</div>  
		</div>
	</div>
	
	
</template>

<script>
	import dockitem from './ModelDetailsDock.vue';

	import {
		createFlowEditor
	}
	from '@/modal-editor/index';

	import store from '@/store';

	import korean from '@/language/korean.json';
	import english from '@/language/english.json';


	export default {
		name: 'modal',
		data() {
			return {
				// 다국어 처리 설정
				// 모델 상세보기 내의 rete editor 상단 버튼 부분
				node_module_cleanup: '',
				rete_undo: '',
				rete_redo: '',
				json_console_check: '',
				rete_download: '',
				node_module_delete: '',

				// 언어 설정 변경
				localization: ''
			}
		},
		components: {
			dockitem
		},
		mounted() {
			createFlowEditor();

			/* global navigator */
			let locale = navigator.language || navigator.userLanguage;

			this.localization = locale.substring(0, 2);

			// 언어 설정 테스트
			// this.localization = 'en';

			switch (this.localization) {
				case 'ko':
					this.node_module_cleanup = korean.model_details_modal.node_module_cleanup;
					this.rete_undo = korean.model_details_modal.rete_undo;
					this.rete_redo = korean.model_details_modal.rete_redo;
					this.node_module_delete = korean.model_details_modal.node_module_delete;
					return;

				case 'en':
					this.node_module_cleanup = english.model_details_modal.node_module_cleanup;
					this.rete_undo = english.model_details_modal.rete_undo;
					this.rete_redo = english.model_details_modal.rete_redo;
					this.node_module_delete = english.model_details_modal.node_module_delete;
					return;
			}

		},
		methods: {
			close_btn(e) {
				this.$emit('close_btn');
			},
			// count_click() {
			// 	store.dispatch('call_count', { count: i });
			// }
		},
		// computed: {
		// 	count() {
		// 		return store.getters.get_count;
		// 	}
		// }
	}
</script>

<style scoped>
	/* 모델 상세보기 전체 부분 크기 설정 */
	.mod-specific {
		width: 1575px;
		height: 800px;
		display: flex;

	}
	/* ================================= */

	.mod-specific dockitem {
		height: 100% !important;
	}

	/* rete 사이즈 조절 */
	.mod-rete {
		width: 90% !important;
		height: 100% !important;
		display: flex;
		flex-direction: column;
		justiiy-content: center;
		align-items: center;
	}
	/* ================ */

	/* rete 상단 버튼 그룹 */
	.mode-rete .mod-operation-btn-group {
		width: 100%;
		height: 10%;
		display: flex;
	}
	/* ================== */
	
	#rete-editor {
		width: 100% !important;
		height: 90% !important;
	}
	
</style>
