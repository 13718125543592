<template>
    <div class="MNISTConvNetVueControl" id="cnn">
      	<label>종류</label>
	    	<select v-model="kinds_selected">
		    	<option v-for="option in kinds_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>입력 데이터</label>
		      <!--<input type="text" v-model.text="data_input"></input>-->
		      <div class="data_input" v-if="mnist_con === true">
		          {{ input_data.IMAGE_WIDTH + " , " + input_data.IMAGE_HEIGHT + " , " + input_data.IMAGE_CHANNELS }}
		      </div>
		      <div class="data_input" v-else>
		          0,0,0
		      </div>
    	<label>레이어 갯수</label>
		      <!--<input type="number" v-model.number="layers_input"></input>-->
		      <div class="model_layer" v-if=" model_layer_length != 0">
		          {{ model_layer_length }}
		      </div>
		      <div class="model_layer" v-else>
		          6
		      </div>
	    <b-button id="modify-btn" @click="showModal">상세 보기</b-button>
        	<b-modal ref="my-modal" hide-header hide-footer centered size="xl">
    				<ModelDetailsComp v-on:close_btn="hideModal"/>
    		</b-modal>
    	<!--<b-button id="model_save_btn">모델 저장</b-button>-->
    </div>
</template>

<script>
    // 모델 상세보기 // 모델 상세보기 모달 에디터 실행 불러오기
    import ModelDetailsComp from '@/components/MainPage/Trainmodels/ModelDetails/ModelDetailsComp';
    import store from '@/store';

    export default {
        name: 'MNISTConvNetVueControl',
        data() {
            return {
                kinds_selected: 'CNN',
                // data_input_selected: '28,28,1',
                // layer_num_selected: '3',
                kinds_options: [
                    { text: 'CNN', value: 'CNN' },
                ],
            }
        },
        components: {
            ModelDetailsComp
        },
        methods: {
            showModal() {
                this.$refs['my-modal'].show();
            },
            hideModal() {
                this.$refs['my-modal'].hide();
            },
        },
        computed: {
            input_data() {
                return store.getters.get_input_data;
            },
            model_layer_length() {
                return store.getters.get_model_layer;
            },
            mnist_con() {
                return store.getters.get_mnist_connect;
            }
        }
    }
</script>

<style scoped>
    .MNISTConvNetVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .MNISTConvNetVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .MNISTConvNetVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    .data_input {
        display: flex;
        align-items: center;
        width: 120px;
        height: 20px;
        border: 1px solid #999;
        border-radius: 5px;
        background-color: #fff;
        color: #000;
        font-size: 10px;
        padding-left: 5px;
    }

    .model_layer {
        display: flex;
        align-items: center;
        width: 120px;
        height: 20px;
        border: 1px solid #999;
        border-radius: 5px;
        background-color: #fff;
        color: #000;
        font-size: 10px;
        padding-left: 5px;
    }

    .MNISTConvNetVueControl button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>
