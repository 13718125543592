import { Control } from 'rete';
import ImageNetVueControl from './named-vue-controls/ImageNetVueControl.vue';

export default class ImageNetControl extends Control {
	constructor(emitter, key, readonly = false) {
		super(key);
		this.component = ImageNetVueControl;
		this.props = {
			emitter,
			ikey: key,
			type: 'number',
			readonly,
			change: () => this.onChange()
		};
	}
	
	setValue(value) {
		const ctx = this.vueContext || this.props;
		ctx.value = value;
	}
	
	onChange() {}
}