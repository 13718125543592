import Rete from 'rete';
import { numSocket } from '../../sockets';
import IrisDataControl from '../../controls/basic-controls/IrisDataControl';

export default class IrisDataComponent extends Rete.Component {
	constructor() {
		super("데이터입력(IRIS)");
	}

	builder(node) {
		let output1 = new Rete.Output("output1", "Output1", numSocket);

		return node
			.addControl(new IrisDataControl(this.editor, "num"))
			.addOutput(output1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
