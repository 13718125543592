<template>
    <!--부트스트랩 상단 네비게이션 바 부분-->
    <b-navbar class="topnavbar">
		<!-- 프로그램 및 회사 이름 -->
		<div class="company-name">
			<b-navbar-brand class="m-auto" href="#">nocodingAI ver 0.1</b-navbar-brand>
		</div>
		<!-- 워크스페이스 이름 부분-->
		<div class="project-name">
			<b-navbar-brand class="ml-3 mr-3" href="/#/workspaces">WORKSPACE</b-navbar-brand>
			/
			<!--진행중인 프로젝트 이름 부분-->
			<b-navbar-brand class="ml-3 mr-3 " href="/#/trainmodel">MNIST TEST</b-navbar-brand>
		</div>
		<!--상단 네비게이션 바 우측 알람 및 로그인 정보 부분-->
		<div class="header-right ml-md-auto">
		    <!--알람 아이콘 부분-->
				<b-icon icon="bell" class="bell-icon"/>
			<hr>
			<!-- 로그인 정보 및 로그아웃 드롭다운 부분-->
			<b-dropdown id="login_inform_dropdown" variant="outline" :text="user_nick">
				<b-dropdown-item-button>Settings</b-dropdown-item-button>
				<b-dropdown-item-button>Profiles</b-dropdown-item-button>
				<b-dropdown-item @click="onLogout">Logout</b-dropdown-item>
			</b-dropdown>
			<!-- 유저 이미지 -->
			<b-avatar id="avatar" :src="user_img"></b-avatar>
		</div>
	</b-navbar>
	<!--부트스트랩 상단 네비게이션 바 부분 end-->
</template>
<script>
    // 언어 설정 JSON 불러오기 
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: 'topnavbar',
        data() {
            return {
                user_nick: '',
                user_img: '',

                // 언어 설정
                localization: ''
            }
        },
        methods: {
            // 로그아웃 
            onLogout() {
                // 로그아웃 콘솔 체크
                // console.log("onlogout button click!!");

                // 세션에 저장된 로그인 정보를 지우고 첫 로그인 페이지로 이동 
                // 추후 바뀔 부분
                sessionStorage.removeItem('userInfo');

                // 로그아웃
                window.Kakao.Auth.logout(() => {
                    
                    // 액세스토큰 제거 체크
                    // 토큰이 지워지면 null 값이 됨
                    // console.log(window.Kakao.Auth.getAccessToken());
                });

                // port 80 ==================================
                // location.href = "https://www.learning-machine.net/"
                // ==========================================

                /* global location */
                // port 8080 ~ ==============================
                // location.href = "https://4d886a8ffa7e475b8f3e0bf2483fadcc.vfs.cloud9.ap-northeast-2.amazonaws.com/#/"
                // ==========================================
                
                // alph ==========================================
                location.href = "https://alpha.nocodingai.com"
                // ===============================================
            }
        },
        mounted() {
            // 사용자 정보 가져오기
            let get_session_userInfo = sessionStorage.getItem("userInfo");

            let session_userInfo_parse = JSON.parse(get_session_userInfo);

            // 화면에 띄우기
            this.user_nick = session_userInfo_parse.nickname;
            this.user_img = session_userInfo_parse.profile_image;
        },
    }
</script>
<style scoped>

    /*상단 네비게이션 바 사이즈 지정*/
    .topnavbar {
        float: none;
        /*box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, .25), inset 0 -1px 5px rgba(0, 0, 0, .25);*/
        box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.5);
        /*border-bottom: 1px solid #555555;*/
        background: #ffffff !important;
        height: 50px;
        /* 포지션 지정*/
        position: relative;
        z-index: 1000;
    }
    
    .company-logo {
        margin-left: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        /*width: 67px;*/
        height: 30px;
    }

    /*회사 이름 부분 사이즈 지정*/
    .company-name {
        width: 10%;
        margin-left: 10px;
    }
    
    /* 링크 */
    .company-name a {
        font-size: 15px;
        color: #7A7B85 !important;
        font-weight: bold;
    }

    /* 프로젝트 이름 부분 사이즈 지정*/
    .project-name {
        margin-left: 50px;
    }

    .project-name a {
        font-size: 15px;
        font-style: solid;
        font-weight: bold;
        color: #7A7B85 !important;
    }

    /* 네비게이션 우측 사이즈 지정*/
    .header-right {
        display: flex;
        align-items: center;
    }

    /*.header-right .gpu {*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    margin-right: 30px;*/
    /*}*/

    /*.header-right .gpu label {*/
    /*    margin-bottom: 0 !important;*/
    /*    font-size: 13px;*/
    /*    color: #7A7B85;*/
    /*}*/

    .tfvis_show button {
        color: #eeeeee;
    }

    .header-right-icons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 200px;
        margin-right: 30px;
    }

    .header-right-icons .searchicon label {
        margin-left: 5px;
    }

    .header-right-icons label {
        margin-bottom: 0 !important;
        font-size: 13px;
        color: #7A7B85;
    }

    .header-right-icons .b-icon.bi {
        color: #7A7B85;
    }

    .header-right hr {
        border: none;
        border-left: 1px solid #BCBCCB;
        height: 30px;
        width: 1px;
        margin-right: 30px;
        background: #BCBCCB;
    }

    .header-right .b-dropdown .btn {
        height: 40px;
        background-color: #ffffff;
        border: none;
        color: #7A7B85;
        font-size: 13px;
    }

    .bell-icon {
        margin-right: 10px;
    }

    .header-right .b-dropdown .btn .show {
        height: 40px;
        background-color: #ffffff;
        border: none;
        color: #BCBCCB;
    }

    #avatar {
        margin: 10px 10px 10px 10px;
        width: 35px !important;
        height: 35px !important;
    }
</style>
