<template>
    <!--노드 작성 모달 부분-->
    <div class="modalinference">
        <!-- 노드 모달 HEADER -->
        <div class="modalinference_header">
            <!--InferencePage 헤더 부분 -->
            <!-- Prediction(추론)-->
            <label>{{ modalinference_header }}</label>
            <!-- 모달 닫기 -->
            <b-icon icon="x" id="mod-closebtn" class="close-btn" v-b-tooltip.hover.bottom="'Modal Close Button'" v-on:click="close_btn"></b-icon> 
        </div>
        <!-- 모달 CONTENT -->
        <div class="modalinference_content">
             <!--모델 불러오기 부분-->
            <div class="model_upload_content">
                <div class="mi_content_header">
                    <!--모델 선택 카드 헤더 부분-->
                    <!--사전 훈련된 모델-->
                    <label>{{ modal_content_header_modelselect }}</label>        
                </div>
                <!-- 모델 불러오기 부분-->
                <b-card no-body class="model_input_part" v-if="model_node_name ==='MNISTCustomCNN'">
                    <b-card-header>
                    </b-card-header>
                    <div class="model_part">
                         <!--같은 파일 -->
                            <img src="@/assets/emptymodel.jpg"/>
                         <!--컴포넌트 화-->
                        <div class="model_explain">
                            <!--텐서스페이스 3d모델이 없는 경우 샘플이미지와 함께 설명-->
                            <!--이 부분은 개발 진행중입니다. Coming Soon....-->
                            <label class="model_custom_explain">{{ model_explain_not_complete }}</label>
                        </div>
                    </div>
                </b-card>
                <b-card no-body class="model_input_part" v-else>
                    <b-card-header>
                    </b-card-header>
                    <div class="model_part">
                         <!--같은 파일 -->
                        <div id='container'></div>
                         <!--컴포넌트 화-->
                        <div class="model_explain">
                            <label>
                                <strong>{{ model_data }}</strong>
                                <!--을 이용한 모델의 개념도 입니다.-->
                                {{ model_explain_complete }}
                            </label>
                        </div>
                    </div>
                </b-card>
            </div>
            <!--모델 불러오기 end -->
            <div class="data_input_content">
                <div class="mi_content_header">
                    <!--데이터 입력 카드 헤더 부분-->
                    <!--데이터입력-->
                    <label>{{ modal_content_header_datainput }}</label>                
                </div>
                <!--iris data node가 실행 중 일때-->
                <b-card no-body class="data_input_select_part" v-if="data_input_name === '데이터입력(IRIS)'">
                    <b-card-header>
                    </b-card-header>
                    <div class="irisdense_part">
                        <div class="irisdense_input">
                            <!--아이리스데이터 입력 부분-->
                            <!--꽃잎 길이(petal_length)-->
                            <label>{{ iris_petal_length }}</label>
                            <input type="number" v-model="petal_length"/>
                            <!--꽃잎 넓이(petal_width)-->
                            <label>{{ iris_petal_width }}</label>
                            <input type="number" v-model="petal_width"/>
                            <!--꽃받침 길이(sepal_length)-->
                            <label>{{ iris_sepal_length }}</label>
                            <input type="number" v-model="sepal_length"/>
                            <!--꽃받침 넓이(sepal_width)-->
                            <label>{{ iris_sepal_width }}</label>
                            <input type="number" v-model="sepal_width"/>
                            <div class="irisdense_input_button_group">
                                <!--아이리스 추론 버튼 부분-->
                                <!--반영-->
                                <b-button variant="primary" @click="model_Predict_Run_Click">{{ iris_value_predict }}</b-button>
                                <b-modal ref="iris_value_alert" hide-footer :title="ainomis_alert">
                                    <!--alert의 내용 부분 -->
                                    <!--아이리스(붓꽃) 데이터의 최소값 , 최대값 내의 값을 입력해주세요.-->
                                    <label class="iris_alert_label">{{ iris_value_alert }}</label>
                                </b-modal>
                            </div>
                        </div>
                    </div>
                </b-card>
                 <!--iris data 가 실행 중이 아닐 때-->
                <b-card no-body class="data_input_select_part" v-else>
                    <b-tabs card align="right">
                        <!--그리기 탭 타이틀 변경 부분 (상단 탭 클릭 부분)-->
                        <!--그리기-->
                        <b-tab :title="tab_drawing_title">
                            <drawingComp v-on:drawingSection_Capture="drawing_Input_to_Inference"/>
                        </b-tab>
                        <!--웹캠 탭 타이틀 변경 부분 (상단 탭 클릭 부분) -->
                        <!--웹캠-->
                        <b-tab :title="tab_webcam_title">
                            <webcamComp v-on:capture="capture_Input_to_Inference"/>
                        </b-tab>
                        <!--파일 업로드 타이틀 변경 부분 (상단 탭 클릭 부분)-->
                        <!--파일-->
                        <b-tab :title="tab_fileupload_title">
                            <div class="select_data_part">
                                <div class="inference_image">
                                    <input ref="inference_Image_input" type="file" id="images" multiple="multiple" hidden @change="onChange_Inference_Image">
                                    <div class="pre_image">
                                        <img class="input_Image" v-for="(image, idx) in this.images" :src="image" v-if="idx<1"/>
                                    </div>
                                </div>
                                <div class="inference_btn_group">
                                    <b-button @click="onClick_Inference_Image" variant="primary">{{ inference_file_upload }}</b-button>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div><!--데이터 입력 부분 끝-->
            <div class="inference_content">
                <div class="mi_content_header">
                    <label>{{ modal_content_header_result }}</label>                
                </div>
                <b-card no-body class="show_inference_part" v-if="data_input_name === '데이터입력(IRIS)'">
                    <b-card-header>
                    </b-card-header>
                    <div class="inference_output">
                        <div class="inference_iris"  v-if="max_value_name != null ">
                            <img v-if="max_value_name === '세토사'" src="@/assets/iris-setosa.jpg"/>
                            <img v-else-if="max_value_name === '버시컬러'" src="@/assets/iris-versicolor.jpg"/>
                            <img v-else-if="max_value_name === '버지니카'" src="@/assets/iris-virginica.jpg"/>
                        </div>
                        <div class="inference_iris" v-else>
                        </div>
                       <div class="inference_data" v-if="max_value_name != null ">
                            <div class="inference_num_iris">
                                <div class="max_result_value">
                                    <!--첫번째 그래프 값-->
                                    <label>{{ max_value_name }}</label>
                                    <div class="max_result_progress_bar_outer">
                                        <div class="max_result_progress_bar_inner" v-if=" max_value_result_percentage != '-Infinity' " :style="{width: max_value_result_percentage + '%'}">{{ max_value_result_percentage }}%</div>
                                        <div class="max_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>
                                    </div>
                                </div>
                                <div class="second_result_value">
                                    <!--두번째 그래프 값-->
                                    <label>{{ second_max_value_name }}</label>
                                    <div class="second_result_progress_bar_outer">
                                        <div class="second_result_progress_bar_inner" v-if=" second_max_value_result_percentage != '-Infinity' " :style="{width: second_max_value_result_percentage + '%'}">{{ second_max_value_result_percentage }}%</div>
                                        <div class="second_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inference_data" v-else>
                            <div class="inference_num_iris">
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card no-body class="show_inference_part" v-else>
                    <b-card-header>
                    </b-card-header>
                    <div class="inference_output">
                        <div class="inference" v-if="session_Inference_Image != '' ">
                            <img :src="session_Inference_Image"/>
                        </div>
                        <div class="inference" v-else>
                        </div>
                        <div class="inference_data" v-if="session_Inference_Image != '' ">
                            <div class="inference_num">
                                <div class="max_result_value">
                                    <!--첫번째 그래프 값-->
                                    <label>{{ max_value_name }}</label>
                                    <div class="max_result_progress_bar_outer">
                                        <div class="max_result_progress_bar_inner" v-if=" max_value_result_percentage != '-Infinity' " :style="{width: max_value_result_percentage + '%'}">{{ max_value_result_percentage }}%</div>
                                        <div class="max_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>
                                    </div>                                
                                </div>
                                <div class="second_result_value">
                                    <!--두번째 그래프 값-->
                                    <label>{{ second_max_value_name }}</label>
                                    <div class="second_result_progress_bar_outer">
                                        <div class="second_result_progress_bar_inner" v-if=" second_max_value_result_percentage != '-Infinity' " :style="{width: second_max_value_result_percentage + '%'}">{{ second_max_value_result_percentage }}%</div>
                                        <div class="second_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inference_data" v-else>
                             <div class="inference_num">
                            </div>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>
<script>
    // Vue vuex 
    import store from '@/store';

    // component 불러오기 =================================================================

    import drawingComp from '@/components/MainPage/Inferences/DataInput/InputMethods/DrawingComp';
    import webcamComp from '@/components/MainPage/Inferences/DataInput/InputMethods/WebCamComp';
    
    // ====================================================================================

    // tf-js 불러오기
    import * as tf from '@tensorflow/tfjs';
    import * as tfvis from '@tensorflow/tfjs-vis';
    import { fitCallbacks } from '@tensorflow/tfjs-vis/dist/show/history';

    // data 불러오기
    import { MnistData } from '@/MnistData/predictdata.js';
    import { IrisData } from '@/IrisData/predictdata.js';

    // Tensorspace 불러오기
    import * as TSP from 'tensorspace';
    import { tensorspace } from '@/tensorspace/tensorspace';

    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';


    export default {
        name: 'ModalInference',
        data() {
            return {
                images: [],
                session_Inference_Image: '',
                uploadmodel: [],
                count: 0,
                test33: [],
                // iris data
                petal_length: 0,
                petal_width: 0,
                sepal_length: 0,
                sepal_width: 0,
                model_data: '',

                // 다국어 설정
                modalinference_header: '',
                modal_content_header_datainput: '',
                iris_petal_length: '',
                iris_petal_width: '',
                iris_sepal_length: '',
                iris_sepal_width: '',
                iris_value_predict: '',
                ainomis_alert: '',
                iris_value_alert: '',
                tab_drawing_title: '',
                tab_webcam_title: '',
                tab_fileupload_title: '',
                inference_file_upload: '',
                modal_content_header_modelselect: '',
                model_explain_not_complete: '',
                model_explain_complete: '',
                modal_content_header_result: '',

                // 언어 설정
                localization: ''

            }
        },
        components: {
            drawingComp,
            webcamComp,
        },
        methods: {
            init() {
                tf;
                tfvis;
                MnistData;
            },
            // 
            onClick_Inference_Image(e) {
                this.$refs.inference_Image_input.click();
            },
            onChange_Inference_Image(e) {
                let files = e.target.files;
                this.create_File_to_Base64(files[0]);
            },
            // create Image base64 image
            create_File_to_Base64(file) {
                let reader = new FileReader();

                reader.onload = (e) => {
                    let data = e.target.result;

                    this.images = [];
                    this.images.push(data);
                    this.session_Inference_Image = '';
                    this.session_Inference_Image = data;


                    sessionStorage.setItem('session_Inference_Image', e.target.result);
                    // 추론 실행 
                    this.run();
                }
                reader.readAsDataURL(file);
            },
            // drawing component 에서 그린 이미지 파일 받기
            drawing_Input_to_Inference() {
                this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                // this.images = [];
                // this.images.push(this.session_Inference_Image);
                this.model_Predict_Run_Click();
            },
            // capture component 에서 캡쳐 이미지 파일 받기
            capture_Input_to_Inference() {
                this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                this.model_Predict_Run_Click();
            },
            model_Predict_Run_Click() {

                // 아이리스 데이터 입력 시 아이리스 데이터임을 확인하기 위한 변수 지정
                let data_input_name = store.getters.get_data_input_name;

                // 아이리스 데이터 입력 시 아이리스 데이터임이 확인 될때
                if (data_input_name === "데이터입력(IRIS)") {

                    // petal_length, petal_width, sepal_length, sepal_width 가 0일 때
                    if (Number(this.petal_length) === 0 || Number(this.petal_width) === 0 || Number(this.sepal_length) === 0 || Number(this.sepal_width) === 0) {

                        // 아이리스 데이터 알람창 띄우기
                        this.$refs['iris_value_alert'].show();
                    }
                    else {
                        // 값이 제대로 입력 되면 값을 입력하고, 추론 진행 하기
                        this.run();
                    }
                }
                // 아이리스 데이터 입력이 아닐때 
                else {
                    // 추론 진행 하기
                    console.log("runn")
                    this.run();
                }

            },
            upload_model_click() {
                this.$refs.modelInput.click();
            },
            async tensorspace_model_upload(e) {

                // TENSORSPACE
                let tensorspace_container = document.getElementById('container');

                while (tensorspace_container.hasChildNodes()) {
                    tensorspace_container.removeChild(tensorspace_container.firstChild);
                }

                let vmcontainer = document.getElementById('container');

                let ts_space = new tensorspace(vmcontainer);

                // TENSORSPACE 를 불러오기 전 
                let data_input_name = store.getters.get_data_input_name;

                let ts_model_view = null;

                switch (data_input_name) {
                    case "데이터입력(MNIST)":

                        let ts_model_name = this.model_node_name;

                        if (ts_model_name === 'MNISTConvNet') {
                            ts_model_view = await ts_space.ts_mnistconv();

                            ts_model_view.init(function() {
                                console.log('tensorspace is working');
                            })
                            this.model_data = "MNIST CONVOLUTION NET";
                        }
                        else if (ts_model_name === 'MNISTDenseNet') {
                            ts_model_view = await ts_space.ts_mnistdense();

                            ts_model_view.init(function() {
                                console.log('tensorspace is working');
                            })
                            this.model_data = "MNIST DENSE NET";
                        }
                        else {
                            this.model_data = "";
                        }

                        return;

                    case "데이터입력(IRIS)":
                        ts_model_view = await ts_space.ts_irisdense();
                        ts_model_view.init(function() {
                            console.log('tensorspace is working');
                        })
                        this.model_data = "IRIS DENSE NET";
                        return;
                }
            },
            async run() {

                // 사전 훈련된 모델처럼 check 하기 어려워 입력된 데이터로 추론 구분
                let data_input_name = store.getters.get_data_input_name;

                const data = new MnistData();
                const irisdata = new IrisData();
                let model_name = store.getters.get_model_name;
                let model = null;

                switch (data_input_name) {
                    case "데이터입력(MNIST)":
                        model = await tf.loadLayersModel('localstorage://' + model_name);
                        data.load(this, model, this.session_Inference_Image, true);
                        return;

                    case "데이터입력(IRIS)":
                        model = await tf.loadLayersModel('localstorage://' + model_name);
                        let iris_array = [Number(this.petal_length), Number(this.petal_width), Number(this.sepal_length), Number(this.sepal_width)];
                        irisdata.load(this, model, iris_array);
                        return;
                }
            },
            predictiontest(model, data) {
                const IMAGE_WIDTH = 28;
                const IMAGE_HEIGHT = 28;
                const testData = data.imgArray();
                const testxs = testData.xs.reshape([
                    testDataSize,
                    IMAGE_WIDTH,
                    IMAGE_HEIGHT,
                    1
                ]);
                // console.log(model.predict(testxs))
            },
            close_btn() {
                this.$emit('close_btn');
            },
        },
        mounted() {

            // 텐서스페이스 모델 업로드
            this.tensorspace_model_upload();

            // 세션에서 가져와서 저장시킨 이미지 변수 초기화
            this.session_Inference_Image = '';

            // 첫번째 값 초기화
            store.dispatch('call_max_value_name', { max_value_name: null });
            store.dispatch('call_max_value_percentage', { max_value_percentage: null });

            // 두번째 값 초기화
            store.dispatch('call_second_max_value_name', { second_max_value_name: null });
            store.dispatch('call_second_max_value_percentage', { second_max_value_percentage: null });

            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // this.localization = 'en';

            // if locale 한국어 설정
            switch (this.localization) {
                case 'ko':
                    this.modalinference_header = korean.trainafterinference.modalinference_header;
                    this.modal_content_header_datainput = korean.trainafterinference.modal_content_header_datainput;
                    this.iris_petal_length = korean.trainafterinference.iris_petal_length;
                    this.iris_petal_width = korean.trainafterinference.iris_petal_width;
                    this.iris_sepal_length = korean.trainafterinference.iris_sepal_length;
                    this.iris_sepal_width = korean.trainafterinference.iris_sepal_width;
                    this.iris_value_predict = korean.trainafterinference.iris_value_predict;
                    this.ainomis_alert = korean.trainafterinference.ainomis_alert;
                    this.iris_value_alert = korean.trainafterinference.iris_value_alert;
                    this.tab_drawing_title = korean.trainafterinference.tab_drawing_title;
                    this.tab_webcam_title = korean.trainafterinference.tab_webcam_title;
                    this.tab_fileupload_title = korean.trainafterinference.tab_fileupload_title;
                    this.inference_file_upload = korean.trainafterinference.inference_file_upload;
                    this.modal_content_header_modelselect = korean.trainafterinference.modal_content_header_modelselect;
                    this.model_explain_not_complete = korean.trainafterinference.model_explain_not_complete;
                    this.model_explain_complete = korean.trainafterinference.model_explain_complete;
                    this.modal_content_header_result = korean.trainafterinference.modal_content_header_result;
                    return;
                case 'en':
                    this.modalinference_header = english.trainafterinference.modalinference_header;
                    this.modal_content_header_datainput = english.trainafterinference.modal_content_header_datainput;
                    this.iris_petal_length = english.trainafterinference.iris_petal_legnth;
                    this.iris_petal_width = english.trainafterinference.iris_petal_width;
                    this.iris_sepal_length = english.trainafterinference.iris_sepal_length;
                    this.iris_sepal_width = english.trainafterinference.iris_sepal_width;
                    this.iris_value_predict = english.trainafterinference.iris_value_predict;
                    this.ainomis_alert = english.trainafterinference.ainomis_alert;
                    this.iris_value_alert = english.trainafterinference.iris_value_alert;
                    this.tab_drawing_title = english.trainafterinference.tab_drawing_title;
                    this.tab_webcam_title = english.trainafterinference.tab_webcam_title;
                    this.tab_fileupload_title = english.trainafterinference.tab_fileupload_title;
                    this.inference_file_upload = english.trainafterinference.inference_file_upload;
                    this.modal_content_header_modelselect = english.trainafterinference.modal_content_header_modelselect;
                    this.model_explain_not_complete = english.trainafterinference.model_explain_not_complete;
                    this.model_explain_complete = english.trainafterinference.model_explain_complete;
                    this.modal_content_header_result = english.trainafterinference.modal_content_header_result;
                    return;
            }

        },
        computed: {
            // 입력 데이터로 분기해주었기 때문에 데이터 입력 체크
            data_input_name() {
                return store.getters.get_data_input_name
            },
            // 노드 에디터에 있는 모델 노드의 이름 가져오기
            model_node_name() {
                let model_node_name = store.getters.get_model_node_name;

                let model_node_name_replace = model_node_name.replace(/(\s*)/g, "");
                return model_node_name_replace;
            },

            // 제일 높은 확률 값의 이름 가져오기
            max_value_name() {
                return store.getters.get_max_value_name;
            },
            // 두번째로 높은 확률값의 이름 가져오기 
            second_max_value_name() {
                return store.getters.get_second_max_value_name;
            },
            // 제일 높은 확률 퍼센테이지 가져오기
            max_value_result_percentage() {
                let max_value_multiply = store.getters.get_max_value_percentage * 100;
                let max_value_to_integer = Math.round(max_value_multiply);
                return max_value_to_integer
            },
            // 두번째로 높은 확률 퍼센테이지 가져오기
            second_max_value_result_percentage() {
                let second_value_multiply = store.getters.get_second_max_value_percentage * 100;
                let second_value_to_integer = Math.round(second_value_multiply);
                return second_value_to_integer
            },
            // 색상 반전 체크
            inversion_check() {
                return store.getters.get_inversion_tf;
            }
        }
    }
</script>
<style scoped>
    .modalinference {
        width: 1600px;
        height: 800px;
        display: flex;
        flex-direction: column;
        /*background: #F5F6FA;*/
    }

    .modalinference_header {
        display: flex;
        align-items: center;
        /*margin-bottom: 3%;*/
        width: 100%;
        height: 70px;
    }

    .modalinference_header label {
        color: #858585;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px;
    }

    .modalinference_header .b-icon {
        margin-left: auto;
        margin-right: 30px;
    }


    .mi_content_header {
        display: flex;
        justify-content: flex-start;
    }

    .mi_content_header label {
        font-size: 20px;
        font-weight: bold;
    }


    /*============================================*/

    .modalinference_content {
        display: flex;
        width: 100%;
        /*height: 90%;*/
        height: 700px;
        justify-content: center;
        /*align-items: center;*/
        /*margin-bottom: 30px;*/
    }

    .data_input_content,
    .model_upload_content,
    .inference_content {
        width: 410px;
        height: 500px;
        margin: 30px;
    }

    /*.data_input_content {*/

    /*    margin-left: 30px;*/

    /*}*/

    /*.model_upload_content {*/

    /*    margin-left: 30px;*/

    /*}*/

    /*.inference_content {*/

    /*    margin-left: 30px;*/

    /*}*/

    .mi_content_header {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .mi_content_header label {
        font-size: 20px;
        font-weight: bold;
    }

    /* 데이터 입력 부분 ----------------------------------------*/

    .data_input_select_part {
        width: 410px;
        height: 550px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    /* IRIS DATA ------------------------------*/

    .data_input_select_part .card-header {
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .data_input_select_part .card-body {
        width: 410px;
        height: 496px;
        padding: 0px;
        padding-bottom: 10px;
    }

    .data_input_select_part .irisdense_part {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .irisdense_part .irisdense_input {
        /*width: 100%;*/
        /*height: 70%;*/
        width: 350px;
        height: 350px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;

    }

    .irisdense_input input {
        width: 350px !important;
        height: 60px !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-radius: 0 !important;
        font-size: 15px !important;
    }

    .irisdense_input_button_group {
        width: 350px;
        height: 35px;
        margin-top: 10px;

    }

    .irisdense_input_button_group button {
        width: 120px;
        height: 35px;
        margin-left: 230px;

    }

    /* IRIS DATA END---------------------------*/

    /* OTHER DATA -----------------------------*/

    .data_input_select_part .tabs {
        width: 410px;
        height: 496px;
        display: flex;
        flex-direction: column;

    }

    .select_data_part {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*margin-top: 5.8%;*/
    }

    .select_data_part label {
        font-size: 20px;
        font-weight: bold;
    }

    .inference_btn_group {
        width: 350px;
        height: 35px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
    }

    .inference_btn_group button {
        margin-left: auto;
    }

    .inference_image {
        width: 100%;
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pre_image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 10px;
        padding-left: 3px;
        border-radius: 5px;
        height: 350px;
        width: 350px;
        background: #F5F6FA;
        box-shadow: 1px 1px 2px #ddd;

    }

    .pre_image img {
        width: 350px;
        height: 350px;
        border-radius: 10px;
    }

    /* OTHER DATA END--------------------------*/

    /* 데이터 입력 부분 END-------------------------------------*/

    /* 모델 업로드 부분 ----------------------------------------*/

    .model_input_part {
        width: 410px;
        height: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .model_input_part .card-header {
        width: 410px;
        height: 54px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .model_input_part .card-header label {
        font-size: 20px;
        font-weight: bold;
    }

    .model_input_part .card-header button {
        margin-left: auto;
        width: 100px;
    }

    .model_part {
        width: 100%;
        height: 100%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    .model_part img {
        width: 350px;
        height: 350px;
    }

    #container {
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
        margin-bottom: 10px;
    }

    #container canvas {
        width: 350px !important;
        height: 350px !important;
        border-radius: 10px !important;
    }

    #container img {
        width: 350px !important;
        height: 350px !important;
    }

    .model_explain {
        width: 350px;
        height: 40px;
    }

    .model_explain label {
        font-size: 13px;
    }

    .model_explain strong {
        font-size: 17px !important;
    }

    .model_custom_explain {
        font-size: 15px !important;
    }

    /* 모델 업로드 부분 END-------------------------------------*/

    /* 추론 부분 -----------------------------------------------*/

    .show_inference_part {
        width: 410px;
        height: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .show_inference_part .card-header {
        width: 410px;
        height: 54px;
        display: flex;
        align-items: center;
    }

    .show_inference_part .card-header label {
        font-size: 20px;
        font-weight: bold;
    }

    .inference_output {
        width: 100%;
        height: 100%;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    .inf_label {
        width: 100%;
        width: 350px;
        height: 10%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .inf_label label {
        font-size: 20px;
        font-weight: bold;
    }

    .inference {
        width: 100%;
        height: 70%;
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .inference_iris {
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .inference_iris img {
        width: 350px;
        height: 350px;
        border-radius: 5px;
    }

    .inference img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    .inference_data {
        display: flex;
        width: 350px;
        height: 40px;
    }

    .inference_data_in {
        width: 98%;
    }

    /* MNIST 추론 ============================*/

    .inference_num {
        width: 350px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inference_num label {
        font-size: 20px;
        font-weight: bold;
    }

    /*========================================*/

    /* IRIS 추론 =============================*/

    .inference_num_iris {
        width: 120px;
        height: 40px;
    }

    .inference_num_iris label {
        font-size: 20px;
        font-weight: bold;
    }

    /*========================================*/

    /* 추론 공통 =============================*/

    .max_result_value,
    .second_result_value {
        display: flex;
        align-items: center;
        width: 350px;
        height: 35px;
    }

    .max_result_value {
        margin-bottom: 5px;
    }


    /*========================================*/

    .max_result_progress_bar_outer,
    .second_result_progress_bar_outer {
        margin-left: auto;
        box-shadow: 1px 1px 2px #ddd;
    }

    .max_result_progress_bar_outer,
    .second_result_progress_bar_outer {
        width: 180px;
        height: 35px;
        background-color: #eee;
        /*background-color: #FFDDB6;*/
        border-radius: 5px;
        -webkit-transition: width 2s, height 2s, background-color 2s, -webkit-transform 2s;
        transition: width 2s, height 2s, background-color 2s, transform 2s;
    }

    .max_result_progress_bar_inner,
    .second_result_progress_bar_inner {
        height: 35px;
        background-color: #0D8CFF;
        margin: 0;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        -webkit-transition: width 2s, height 2s, background-color 2s, -webkit-transform 2s;
        transition: width 2s, height 2s, background-color 2s, transform 2s;
    }

    .second_result_progress_bar_inner {
        background-color: #BABABA;

    }

    /* 추론 부분 END -------------------------*/
</style>
