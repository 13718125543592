<template>
    <div class="Conv">
            <div class="Conv_kernelSize">
                <label>kernelSize</label>
                <input type="number" v-model.number="kernelSize_input"></input>
            </div>
            <div class="Conv_filters">
                <label>filters</label>
                <input type="number" v-model.number="filters_input"></input>
            </div>
            <div class="Conv_strides">
                <label>strides</label>
                <input type="number" v-model.number="strides_input"></input>
            </div>
            <div class="Conv_activation">
                <label>activation</label>
                <b-form-select v-model="activation_selected" :options="activation_options">
                </b-form-select>
            </div>
            <div class="Conv_kernelInitializer">
                <label>kernelInitializer</label>
                <b-form-select v-model="initializer_selected" :options="initializer_options">
                </b-form-select>
            </div>
         <!--</b-collapse>-->
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'Conv',
        data() {
            return {
                kernelSize_input: 0,
                filters_input: 0,
                strides_input: 0,
                activation_selected: '',
                initializer_selected: '',
                activation_options: [
                    { value: 'relu', text: 'relu' },
                    { value: 'sigmoid', text: 'sigmoid' }
                ],
                initializer_options: [
                    { value: 'varianceScaling', text: 'varianceScaling' }
                ],
                input_data: {
                    kernelSize: 0,
                    filters: 0,
                    strides: 0,
                    activation: '',
                    kernelInitializer: ''
                }

            }
        },
        updated() {
            // 값 조정 부분
            this.input_data.kernelSize = this.kernelSize_input;
            this.input_data.filters = this.filters_input;
            this.input_data.strides = this.strides_input;
            this.input_data.activation = this.activation_selected;
            this.input_data.kernelInitializer = this.initializer_selected;

            // 해당 노드의 ID
            let m_num = store.getters.get_mod_node_id;

            if (m_num != '') {
                sessionStorage.setItem(m_num, JSON.stringify(this.input_data));
            }
            else {
                return;
            }
        },
        mounted() {
            // MAIN MODEL NODE id가 MNIST ConvNet 이라면 지정값 넣어주기
            if (store.getters.get_model_node_name === "MNIST ConvNet") {

                // switch (store.getters.getconvnode) {

                //     case "conv2d8":
                //         this.kernelSize_input = 5;
                //         this.filters_input = 8;
                //         this.strides_input = 1;
                //         this.activation_selected = 'relu';
                //         this.initializer_selected = 'varianceScaling';
                //         return;

                //     case "conv2d10":
                //         this.kernelSize_input = 5;
                //         this.filters_input = 16;
                //         this.strides_input = 1;
                //         this.activation_selected = 'relu';
                //         this.initializer_selected = 'varianceScaling';
                //         return;

                // }

                // 해당하는 모달의 데이터를 세션에서 가져오기
                let saved_model_data = sessionStorage.getItem('modaldata' + store.getters.getNodeSelected);

                // JSON 파싱
                let saved_model_data_json = JSON.parse(saved_model_data);

                // JSON NODE
                let s_json = saved_model_data_json.nodes;

                // NODE KEY
                let s_keys = Object.keys(s_json);

                // NODE VALUE
                let s_values = Object.values(s_json);

                // 선택한 CONV2D NODE의 ID
                let conv_id = store.getters.getconvnode_id;

                // CONV2D NODE 값과 내용들을 세션에 저장
                for (let i = 0; i < s_keys.length; i++) {
                    if (s_values[i].id === conv_id) {
                        sessionStorage.setItem(conv_id, JSON.stringify(s_values[i].data));

                        this.kernelSize_input = s_values[i].data.kernelSize;
                        this.filters_input = s_values[i].data.filters;
                        this.strides_input = s_values[i].data.strides;
                        this.activation_selected = s_values[i].data.activation;
                        this.initializer_selected = s_values[i].data.kernelInitializer;
                    }
                }


            }
            else {
                // 해당하는 모달의 데이터를 세션에서 가져오기
                let saved_model_data = sessionStorage.getItem('modaldata' + store.getters.getNodeSelected);

                // JSON 파싱
                let saved_model_data_json = JSON.parse(saved_model_data);

                // JSON NODE
                let s_json = saved_model_data_json.nodes;

                // NODE KEY
                let s_keys = Object.keys(s_json);

                // NODE VALUE
                let s_values = Object.values(s_json);

                // 선택한 CONV2D NODE의 ID
                let conv_id = store.getters.getconvnode_id;

                // CONV2D NODE 값과 내용들을 세션에 저장
                for (let i = 0; i < s_keys.length; i++) {
                    if (s_values[i].id === conv_id) {
                        sessionStorage.setItem(conv_id, JSON.stringify(s_values[i].data));

                        this.kernelSize_input = s_values[i].data.kernelSize;
                        this.filters_input = s_values[i].data.filters;
                        this.strides_input = s_values[i].data.strides;
                        this.activation_selected = s_values[i].data.activation;
                        this.initializer_selected = s_values[i].data.kernelInitializer;
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .Conv {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 20px;
        color: #555555;
    }

    .inner_view {
        width: 120px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inner_view:hover {
        background: #F5F5FB;
        border: 1px solid #A5A4BF;
        border-radius: 5px;
    }

    .Conv label {
        color: #555555;
        font-size: 8px;
    }

    .Conv input {
        width: 55px !important;
        height: 20px !important;
        border-radius: 5px;
        padding: 2px 6px;
        border: 1px solid #999;
        font-size: 100%;
    }

    .Conv_kernelSize {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .Conv_kernelSize input {
        margin-left: auto;
    }

    .Conv_filters {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .Conv_filters input {
        margin-left: auto;
    }

    .Conv_strides {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .Conv_strides input {
        margin-left: auto;
    }


    .Conv_activation {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Conv_activation label {
        color: #555555;
        font-size: 8px;
    }

    .Conv_activation select {
        width: 120px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid #999;
    }

    .Conv_kernelInitializer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Conv_kernelInitializer label {
        color: #555555;
        font-size: 8px;
    }

    .Conv_kernelInitializer select {
        width: 120px !important;
        height: 20px !important;
        border-radius: 5px;
        background-color: white;
        padding: 2px 6px;
        border: 1px solid #999;
    }
</style>
