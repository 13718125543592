<template>
    <div class="DataInputVueControl">
        <!--<div class="inner_view" v-b-toggle.collapse-a>-->
        <!--    <b-icon icon="arrow-down-up"></b-icon>-->
        <!--</div>-->
        <!--<b-collapse id="collapse-a">-->
          	<label>DATA-WIDTH</label>
    	    <!--<input type="number" v-model.number="IMAGE_WIDTH_INPUT" ></input>-->
    	    <!--@change="image_width_input_change"-->
    	    <div class="image_data">
    	        {{input_data.IMAGE_WIDTH}}
    	    </div>
    	    <label>DATA-HEIGHT</label>
    	    <!--<input type="number" v-model.number="IMAGE_HEIGHT_INPUT" ></input>-->
    	     <!--@change="image_height_input_change"-->
    	     <div class="image_data">
        	     {{input_data.IMAGE_HEIGHT}}    
    	     </div>
        	<label>DATA-CHANNELS</label>
    	    <!--<input type="number" v-model.number="IMAGE_CHANNELS_INPUT" ></input>-->
            <div class="image_data">
                {{input_data.IMAGE_CHANNELS}}
            </div>
        <!--</b-collapse>-->
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'DataInputVueControl',
        data() {
            return {
                input_data: {
                    IMAGE_WIDTH: 0,
                    IMAGE_HEIGHT: 0,
                    IMAGE_CHANNELS: 0,
                }
            }
        },
        methods: {},
        mounted() {
            // 사전 훈련된 모델처럼 check 하기 어려워 입력된 데이터로 추론 구분
            let data_input_name = store.getters.get_data_input_name;
            console.log(data_input_name)

            switch (data_input_name) {
                case "데이터입력(MNIST)":
                    console.log("입력 데이터가 MNIST");
                    this.input_data.IMAGE_WIDTH = store.getters.get_input_data.IMAGE_WIDTH;
                    this.input_data.IMAGE_HEIGHT = store.getters.get_input_data.IMAGE_HEIGHT;
                    this.input_data.IMAGE_CHANNELS = store.getters.get_input_data.IMAGE_CHANNELS;
                    return;

                case "데이터입력(IRIS)":
                    console.log("입력 데이터가 IRIS");
                    this.input_data.IMAGE_WIDTH = store.getters.get_input_iris_data.IMAGE_WIDTH;
                    this.input_data.IMAGE_HEIGHT = store.getters.get_input_iris_data.IMAGE_HEIGHT;
                    this.input_data.IMAGE_CHANNELS = 0;
                    return;
            }

        }
        // computed: {
        //     // 메인 에디터 데이터입력 부분의 값을 불러오기
        //     input_data() {
        //         return store.getters.get_input_data
        //     },
        // },
    }
</script>

<style scoped>
    .DataInputVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .inner_view {
        width: 120px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inner_view:hover {
        background: #F5F5FB;
        border: 1px solid #A5A4BF;
        border-radius: 5px;
    }

    .DataInputVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .DataInputVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    .image_data {
        display: flex;
        align-items: center;
        width: 120px;
        height: 20px;
        border: 1px solid #999;
        border-radius: 5px;
        background-color: #fff;
        color: #000;
        font-size: 10px;
        padding-left: 5px;
    }
</style>
