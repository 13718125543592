<template>
    <div class="DefaultMNISTVueControl">
    	<label>크기</label>
	    <select v-model="size_selected">
		   	<option v-for="option in size_options" v-bind:value="option.value">
		    	{{ option.text }}
		    </option>
	    </select>
    	<label>종류</label>
	    <select v-model="kind_selected">
		   	<option v-for="option in kind_options" v-bind:value="option.value">
		    	{{ option.text }}
		    </option>
	    </select>
	    <label>훈련/테스트 비율</label>
        <b-form-input id="range_slide" v-model="train_test_ratio" type="range" min="0" max="100"></b-form-input>
        <div class="range_num">
   	        <b-form-input id="range_number" type="number" v-model="train_test_ratio"></b-form-input><label>%</label>                
        </div>
	</div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';

    export default {
        name: 'DefaultMNISTVueControl',
        data() {
            return {
                size_selected: '',
                kind_selected: '',
                size_options: [
                    { text: '224X224', value: '224X224' },
                    { text: '150X150', value: '150X150' },
                    { text: '150X5', value: "150X5" },
                    { text: '32X32', value: '32X32' },
                    { text: '28X28', value: '28X28' },
                    { text: '16X16', value: '16X16' }
                ],
                kind_options: [
                    { text: '숫자', value: '숫자' },
                ],
            }
        },
        updated() {
            let size = this.size_selected;

            let kinds = this.kind_selected;


            store.dispatch('call_iris_data_size', { iris_data_size: size });
            // console.log(store.getters.get_input_data.IMAGE_WIDTH + " " + store.getters.get_input_data.IMAGE_HEIGHT);

            // store.dispatch('call_data_kinds', { data_kinds: kinds });
            // console.log(store.getters.get_input_data.IMAGE_CHANNELS);

        },
        mounted() {
            this.size_selected = '150X5';
            this.kind_selected = '숫자';

            // store.dispatch('call_data_size', { data_size: '150X5'});
            // store.dispatch('call_data_kinds', { data_kinds: '숫자' });

            // IRIS DATA DEFAULT 값
            store.dispatch('call_train_test_ratio', { train_test_ratio: 100 });
        },
        computed: {
            train_test_ratio: {
                get() {
                    // 아이리스 데이터 에서 훈련 테스트 할 데이터 비율
                    let t_t_ratio = store.getters.get_train_test_ratio;

                    // 데이터 셋
                    // let dataset_num = store.getters.get_dataset_num;
                    let dataset_num = store.getters.get_iris_dataset_num;

                    // 계산할 값으로 변환
                    let ratio_value = t_t_ratio * 0.01;

                    // 계산된 셋팅 값
                    let train_test_set = dataset_num * ratio_value;

                    // Train Set 값 (8)
                    let trainlabels_num = Math.round(train_test_set * 0.8);

                    // Test Set 값 (2)
                    let testlabels_num = Math.round(train_test_set * 0.2);

                    // vuex store에 등록 (훈련데이터 값)
                    store.dispatch("call_iris_trainlabels_num", { trainlabels_num: trainlabels_num });
                    // vuex store에 등록 (테스트데이터 값)
                    store.dispatch('call_iris_testlabels_num', { testlabels_num: testlabels_num });

                    return t_t_ratio

                },
                set(ratio) {
                    let t_t_ratio = ratio;

                    // 아이리스 데이터 에서 훈련 테스트 할 데이터 비율
                    store.dispatch("call_train_test_ratio", { train_test_ratio: t_t_ratio });

                    // 데이터 셋
                    // let dataset_num = store.getters.get_dataset_num;
                    let dataset_num = store.getters.get_iris_dataset_num;
                    // 계산할 값으로 변환
                    let ratio_value = t_t_ratio * 0.01;

                    // 계산된 셋팅 값
                    let train_test_set = dataset_num * ratio_value;

                    // Train Set 값 (8)
                    let trainlabels_num = Math.round(train_test_set * 0.8);

                    // Test Set 값 (2)
                    let testlabels_num = Math.round(train_test_set * 0.2);

                    // vuex store에 등록 (훈련데이터 값)
                    store.dispatch("call_iris_trainlabels_num", { trainlabels_num: trainlabels_num });
                    // vuex store에 등록 (테스트데이터 값)
                    store.dispatch('call_iris_testlabels_num', { testlabels_num: testlabels_num });

                    return t_t_ratio
                }
            }
        }

    }
</script>

<style scoped>
    .DefaultMNISTVueControl {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .DefaultMNISTVueControl label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .DefaultMNISTVueControl select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    #range_slide {
        border: none !important;
    }

    .range_num {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .range_num label {
        font-size: 13px !important;
        margin-left: 3px;
    }

    #range_number {
        width: 100px !important;
        height: 25px !important;
    }


    .DefaultMNISTVueControl button {
        margin-top: 5px;
        height: 25px;
        font-size: 13px;
        color: #ffffff;
        background: #7784FB;
        text-align: center;
        line-height: 12px;
        border: none;
    }
</style>
