<template>
    <!-- 메인 프로젝트 화면 -->
    <div class="workspaces_outer">
        
        <div class="workspaces_wrapper">
            
        </div>
        <!-- header -->
        <div class="workspaces_header">
            <!--워크스페이스 부분 헤더-->
            <!--Workspaces(프로젝트)-->
            <label>{{ workspaces_header }}</label>
        </div>
        <!-- body -->
        <div class="workspaces_content">
            <!--프로젝트 검색 부분-->
            <div class="workspaces_search">
                <!--placeholder="찾는 이름..."-->
                <input type="text" placeholder="찾는 이름..."></input>
                <!--워크스페이스 셀렉트-->
                <select v-model="sort_workspace">
                    <!--셀렉트 메뉴 1-->
                    <!--최근 업데이트순-->
                    <option>{{ sort_workspace_recent }}</option>
                    <!--셀렉트 메뉴 2-->
                    <!--이름순-->
                    <option>{{ sort_workspace_name }}</option>
                </select> 
            </div>
            <!-- 프로젝트 갯수-->
            <div class="project_label">
                <!--내 프로젝트들 (프로젝트 수)-->
                <label>{{ project_label_myproject }} ({{ session_saved_workspaces.length }})</label>
            </div>
            <!-- 프로젝트 리스트 -->
            <div class="workspace_list">
                <!-- 새로운 프로젝트 만들기 MODAL -->
                <div class="new_project_form" @click="show_Create_New_Project">
                    <b-icon icon="plus-square-fill"></b-icon>
                    <!--새로 만들기 모달 부분-->
                    <!--새로 만들기-->
                    <label>{{ new_project_form_label }}</label>
                    <!--새로 만들기 부분 설명 -->
                    <!--workspace에 새로운 프로젝트를 만듭니다-->
                    <p>{{ new_project_form_explain }}</p>
                    <b-modal ref="new_workspace_form" hide-header hide-footer size="lg">
                        <new_workspace v-on:close_btn="hide_Create_New_Project"/>
                        <!--v-on:test_click_child="testParent"-->
                    </b-modal>
                </div>
                <!--기존 프로젝트 표시-->
                <!--기존 저장되어 있던 프로젝트들 카드로 리스트 생성-->
                <b-card class="workspace_card" no-body v-for="(saved_workspace, index) in session_saved_workspaces" :key="index">
                    <div class="authorize">
                        <div class="authorize_account">
                            <!--프로젝트 권한자-->
                            <label>{{ saved_workspace.authorize }}</label>
                        </div>
                        <div class="authorize_setting">
                            <b-dropdown class="setting_dropdown" size="sm" variant="link" right no-caret>
                            <div slot="button-content">
                                <b-icon icon="three-dots-vertical"/>
                            </div>
                            <!--프로젝트 삭제-->
                            <!--삭제-->
                            <b-dropdown-item @click="project_delete(saved_workspace, index)">{{ delete_project }}</b-dropdown-item>
                            </b-dropdown>
                        </div> <!-- authorize_setting end -->
                    </div>
                    <div class="workspace_name">
                        <!--워크스페이스 프로젝트 이름-->
                        <label>{{ saved_workspace.project_name }}</label>
                    </div>
                    <div class="workspace_rating">
                        <b-form-rating v-model="saved_workspace.project_rating" color="#FF6565"></b-form-rating>
                    </div>
                    <div class="workspace_explain">
                        <!--워크스페이스 프로젝트 설명-->
                        <p>{{ saved_workspace.project_explain }}</p>
                    </div>
                    <div class="workspace_card_select">
                        <!--현재 프로젝트 표시 -->
                        <!-- 프로젝트 열기 버튼 링크랑 조건식 연결해야 함-->
                        <!--현재 프로젝트-->
                        <b-button @click="workspace_project_button_click(saved_workspace)" variant="warning" v-if="saved_workspace.project_name === 'MNIST TEST'">{{ current_project }}</b-button>
                        <!-- 나머지 프로젝트 표시 -->
                        <!--프로젝트 열기-->
                        <b-button @click="workspace_project_button_click(saved_workspace)" v-else :disabled="saved_workspace.isDisabled">{{ project_open }}</b-button>
                        <!--<b-avatar src="https://placekitten.com/300/300"></b-avatar>-->
                        <img :src="saved_workspace.user_profile_image" />
                    </div> <!-- workspace_card_select end -->
                </b-card> <!-- workspace_card end-->
            </div> <!-- workspaces_list end -->
        </div> <!-- workspaces_content end -->
    </div> <!-- workspace_outer end-->
</template>
<script>
    import new_workspace from './Newworkspaceform';


    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    // vuex store 이용
    import store from '@/store';


    export default {
        name: 'workspaces',
        data() {
            return {
                sort_workspace: '최근 업데이트순',
                session_saved_workspaces: [],
                // uploadmodel: [],

                // 다국어모드 설정
                workspaces_header: '',
                workspaces_search_placeholder: '',
                sort_workspace_recent: '',
                sort_workspace_name: '',

                project_label_myproject: '',

                new_project_form_label: '',
                new_project_form_explain: '',
                delete_project: '',
                current_project: '',
                project_open: '',

                // 언어 설정
                localization: ''
            }
        },
        components: {
            new_workspace
        },
        methods: {
            // event emit test 
            // testParent(test_button_click_content) {
            //     console.log(test_button_click_content);
            // },
            // 새로운 프로젝트 만들기 모달 OPEN
            show_Create_New_Project() {
                this.$refs['new_workspace_form'].show();
            },
            // 새로운 프로젝트 만들기 모달 HIDE
            hide_Create_New_Project() {
                this.$refs['new_workspace_form'].hide();

                // 새로운 프로젝트 만들기 모달이 hide 되면서
                // 세션에 저장되어 있던 파일 다시 불러오기 refresh
                this.workspace_list_refresh();

            },
            // 세션에 저장한 워크스페이스 목록 다시 가져와서 화면에 반영하기
            workspace_list_refresh() {
                // 세션에 저장한 워크스페이스 목록 다시 가져오기
                let workspaces = sessionStorage.getItem('session_saved_workspaces');

                // 세션에서 가져온 워크스페이스 목록 제에슨 파싱
                let workspace_parse = JSON.parse(workspaces);

                // data 객체 내의 session_saved_workspaces 함수에 저장
                this.session_saved_workspaces = workspace_parse;

            },
            // 프로젝트 열기 CLICK
            workspace_project_button_click(saved_workspace) {
                // console.log(saved_workspace.project_rating)
                this.$router.push('trainmodel');
            },
            // 프로젝트 지우기 CLICK
            project_delete(saved_workspace, index) {
                // 클릭 확인용 콘솔
                // console.log(index);
                // console.log(saved_workspace);

                // 세션에 저장되어있는 목록 가져오기
                let workspaces = sessionStorage.getItem('session_saved_workspaces');

                // 세션에서 가져온 워크스페이스 목록 제이슨 파싱
                let workspace_parse = JSON.parse(workspaces);

                // 해당 인덱스의 프로젝트 지우기
                workspace_parse.splice(index, 1);

                // 값 변환 후 세션스토리지에 저장
                sessionStorage.setItem('session_saved_workspaces', JSON.stringify(workspace_parse));

                // 워크스페이스 화면에 다시 반영
                this.workspace_list_refresh();
            }
        },
        mounted() {

            // console.log('mounted!!')
            // 추후 통신을 통해 받아와서 워크스페이스 목록에 띄워줘야 할 부분

            // 세션스토리지에 저장되어 있는 유저 프로필 이미지 정보 가져오기
            let userinfo = sessionStorage.getItem('userInfo');

            let userinfo_parse = JSON.parse(userinfo);

            let profile_image = userinfo_parse.profile_image;


            // 세션스토리지에 목록 유무 확인
            // console.log(sessionStorage.getItem('session_saved_workspaces'));

            // 날짜 테스트 (최근 업데이트로 SORT 시 필요 할것 같음)
            // const date = new Date();
            // ===================================================

            // 세션 스토리지에서 저장된 워크스페이스가 없으면 세션 스토리지에 저장
            if (sessionStorage.getItem('session_saved_workspaces') === null) {
                // 변수에 저장된 워크스페이스들 저장
                let session_saved_workspaces = [{
                        authorize: '관리자',
                        project_name: 'MNIST TEST',
                        project_rating: 4,
                        project_explain: 'MNIST 데이터셋 예측 모델 훈련 테스트',
                        user_profile_image: profile_image,
                        isDisabled: false
                    },
                    {
                        authorize: '관리자',
                        project_name: 'TESTMODEL1',
                        project_rating: 3,
                        project_explain: 'MNIST 데이터셋, DENSE LAYER 모델 훈련 테스트',
                        user_profile_image: profile_image,
                        isDisabled: true

                    },
                    {
                        authorize: '관리자',
                        project_name: 'TESTMODEL2',
                        project_rating: 3,
                        project_explain: 'IRIS 데이터셋, DENSE LAYER 모델 훈련 테스트',
                        user_profile_image: profile_image,
                        isDisabled: true


                    },
                    {
                        authorize: '게스트',
                        project_name: 'TESTMODEL3',
                        project_rating: 3,
                        project_explain: '사진을 통한 분류, MOBILE NET 모델 훈련 테스트',
                        user_profile_image: 'https://placekitten.com/300/300',
                        isDisabled: true

                    },
                    {
                        authorize: '게스트',
                        project_name: 'TESTMODEL4',
                        project_rating: 3,
                        project_explain: 'MNIST 데이터셋, LENET 모델 훈련 테스트',
                        user_profile_image: 'https://placekitten.com/300/300',
                        isDisabled: true

                    },
                    {
                        authorize: '게스트',
                        project_name: 'TESTMODEL5',
                        project_rating: 2,
                        project_explain: 'MNIST 데이터셋, CONVOLUTION NET CUSTOM 모델 훈련 테스트',
                        user_profile_image: 'https://placekitten.com/300/300',
                        isDisabled: true
                    },
                ]

                // 화면에 반영하기
                this.session_saved_workspaces = session_saved_workspaces;

                // 세션에 저장하기 위해 stringify
                let workspace_string = JSON.stringify(session_saved_workspaces);

                // 세션 스토리지에 저장하기
                sessionStorage.setItem('session_saved_workspaces', workspace_string);

            }
            else {
                // 콘솔 확인 (else)
                // console.log('세션에 이미 저장 되어 있습니다.');

                // 세션에 저장한 워크스페이스 목록 다시 가져오기
                let workspaces = sessionStorage.getItem('session_saved_workspaces');

                // 세션에서 가져온 워크스페이스 목록 제에슨 파싱
                let workspace_parse = JSON.parse(workspaces);

                // data 객체 내의 session_saved_workspaces 함수에 저장
                this.session_saved_workspaces = workspace_parse;
            }

            // =========================================================================================
            
             /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.workspaces_header = korean.workspaces.workspaces_header;
                    this.workspaces_search_placeholder = korean.workspaces.workspaces_search_placeholder;
                    this.sort_workspace_recent = korean.workspaces.sort_workspace_recent;
                    this.sort_workspace_name = korean.workspaces.sort_workspace_name;
                    this.project_label_myproject = korean.workspaces.project_label_myproject;
                    this.new_project_form_label = korean.workspaces.new_project_form_label;
                    this.new_project_form_explain = korean.workspaces.new_project_form_explain;
                    this.delete_project = korean.workspaces.delete_project;
                    this.current_project = korean.workspaces.current_project;
                    this.project_open = korean.workspaces.project_open;
                    return;
                case 'en':
                    this.workspaces_header = english.workspaces.workspaces_header;
                    this.workspaces_search_placeholder = english.workspaces.workspaces_search_placeholder;
                    this.sort_workspace_recent = english.workspaces.sort_workspace_recent;
                    this.sort_workspace_name = english.workspaces.sort_workspace_name;
                    this.project_label_myproject = english.workspaces.project_label_myproject;
                    this.new_project_form_label = english.workspaces.new_project_form_label;
                    this.new_project_form_explain = english.workspaces.new_project_form_explain;
                    this.delete_project = english.workspaces.delete_project;
                    this.current_project = english.workspaces.current_project;
                    this.project_open = english.workspaces.project_open;
                    return;

            }
            // ==========================================================================================
        },
        updated() {
            console.log(this.sort_workspace)
        }
    }
</script>
<style scoped>
    /* 워크스페이스페이지 전체 크기 ======*/

    .workspaces_outer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #F5F6FA;
    }

    /*====================================*/

    /* 워크스페이스페이지 헤더 ===========*/

    .workspaces_header {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    .workspaces_header label {
        color: #858585;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px;
    }

    /*====================================*/

    /* 워크스페이스페이지 콘텐츠 =========*/

    .workspaces_content {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3%;
    }

    /* 검색 */

    .workspaces_search {
        /*height: 10%;*/
        /*width: 75%;*/
        width: 1400px;
        height: 100px;
        display: flex;
        align-items: center;
    }

    .workspaces_search input {
        height: 50px;
        width: 500px !important;
        border: none !important;
        border-radius: 5px;
        margin-left: 1.7%;
        font-size: 15px !important;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
    }

    .workspaces_search select {
        height: 40px;
        width: 200px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: auto;
        font-size: 15px !important;
    }

    /*프로젝트 라벨*/

    .project_label {
        /*height: 10%;*/
        /*width: 75%;*/
        width: 1400px;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .project_label label {
        height: 35px !important;
        margin-left: 1.7%;
        border-bottom: 3px solid #519DFF;
    }

    /* 프로젝트 카드 리스트*/

    .workspace_list {
        /*width: 75%;*/
        width: 1400px;
        height: 600px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .new_project_form {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .new_project_form .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .new_project_form label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .new_project_form p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .new_project_form:hover {
        background: #eeeeee;
        cursor: pointer;
    }



    .workspace_list .workspace_card {
        width: 280px;
        height: 250px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }


    .authorize {
        width: 100%;
        display: flex;
    }

    .authorize_account {
        width: 60px;
        height: 20px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 5px;
    }

    .authorize_account label {
        font-size: 8px;
        margin-bottom: 0 !important;
        color: #3E7AD3;
    }

    .authorize_setting {
        margin-top: 7px;
        /*margin-right: 10px;*/
    }

    .authorize_setting .b-icon {
        color: #555555;
    }

    .authorize_setting .lang-dropdown.dropdown-menu {
        min-width: 4rem !important;
    }



    .workspace_name {
        width: 100%;
    }

    .workspace_name label {
        font-weight: bold;
        margin-left: 30px;
    }

    .workspace_rating {
        width: 100%;
    }

    .workspace_rating .b-rating {
        border: none !important;
        width: 50%;
        margin-left: 15px;
    }

    .workspace_explain {
        width: 100%;
        height: 40%;
    }

    .workspace_explain p {
        font-size: 13px;
        color: #858585;
        work-break: keep-all;
        margin-left: 30px;
        margin-right: 30px;
    }

    .workspace_card_select {
        width: 100%;
        height: 60px;
        /*background: #DDE6F2;*/
        display: flex;
        align-items: center;
    }

    .workspace_card_select button {
        width: 130px;
        height: 35px;
        font-size: 13px !important;
        margin-left: 30px;
        margin-bottom: 40px;
    }

    .workspace_card_select img {
        width: 35px !important;
        height: 35px !important;
        border-radius: 100px;
        margin-bottom: 40px;
        margin-left: 50px;
    }

    /*====================================*/
</style>
