<template>
    <!-- 추론 결과 부분 -->
    <div class="inference_content">
        <div class="mi_content_header">
            <label>{{ mi_content_header_result }}</label>
        </div>
        
        <!-- 추론 결과 부분 통합 -->
        <b-card no-body class="show_inference_part">
            
            <!--추론 결과 부분 빈 헤더-->
            <b-card-header>
            </b-card-header>
            <!--추론 결과 부분 빈 헤더 END-->
            
            <!---->
            <div class="inference_output">
                <div class="inference" id="inference_container" >
                    <canvas id="result" width="350" height="350"/>
                </div>
                <div class="inference_data" v-if="session_Inference_Image != '' ">
                     <!-- MOBILE NET 일 경우 -->
                    <!-- 맥스 값만 존재 -->
                    <div class="inference_num" v-if="selected_pretrain_model === 'MOBILE NET'">
                        <div class="max_result_value">
                            <!--첫번째 그래프 값-->
                            <label class="mobilenet_label">{{ max_value_name }}</label>
                            <div class="max_result_progress_bar_outer">
                                <div class="max_result_progress_bar_inner" v-if=" max_value_result_percentage != '-Infinity' " :style="{width: max_value_result_percentage + '%'}">{{ max_value_result_percentage }}%</div>
                                <div class="max_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>
                            </div>
                        </div>
                    </div>
                    <!--MOBILE NET END -->
                    
                    <!-- MNIST CONV, MNIST DENSE -->
                    <div class="inference_num" v-else-if="selected_pretrain_model === 'MOBILE NET' || selected_pretrain_model === 'MNIST CONVOLUTION NET' || selected_pretrain_model === 'MNIST DENSE NET' || selected_pretrain_model === 'IRIS DENSE NET'">
                        <div class="max_result_value">
                         <!--첫번째 그래프 값-->
                            <label>{{ max_value_name }}</label>
                            <div class="max_result_progress_bar_outer">
                                <div class="max_result_progress_bar_inner" v-if=" max_value_result_percentage != '-Infinity' " :style="{width: max_value_result_percentage + '%'}">{{ max_value_result_percentage }}%</div>
                                <div class="max_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>
                            </div>
                        </div>
                        <div class="second_result_value">
                            <!--두번째 그래프 값-->
                            <label>{{ second_max_value_name }}</label>
                            <div class="second_result_progress_bar_outer">
                                <div class="second_result_progress_bar_inner" v-if=" second_max_value_result_percentage != '-Infinity' " :style="{width: second_max_value_result_percentage + '%'}">{{ second_max_value_result_percentage }}%</div>
                                <div class="second_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>
                            </div>
                        </div>
                    </div>
                    <!-- MNIST CONV, MNIST DENSE END -->
                </div>
                <div class="inference_data" v-else>
                    <div class="inference_num">
                    </div>
                </div>
            </div>
        </b-card>
        
        <!-- 추론 부분 IF GAN-CARTOONIZER -->
        <!--<b-card no-body class="show_inference_part" v-else-if="selected_pretrain_model === 'GAN-CARTOONIZER'">-->
        <!--    <b-card-header>-->
        <!--    </b-card-header>-->
        <!--    <div class="inference_output">-->
        <!--        <div class="inference" id="cartoon_container">-->
        <!--            <canvas id="result"/>-->
        <!--        </div>-->
        <!--        <div class="inference_data">-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</b-card>-->
        
        <!-- 추론 부분 IF CONTEXT R-CNN -->
        <!--<b-card no-body class="show_inference_part" v-else-if="selected_pretrain_model === 'CONTEXT R-CNN'">-->
        <!--    <b-card-header>-->
        <!--    </b-card-header>-->
        <!--    <div class="inference_output">-->
        <!--        <div class="inference" id="context_container">-->
        <!--            <canvas id="detection_result" width="350" height="350" />-->
        <!--        </div>-->
        <!--        <div class="inference_data">-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</b-card>-->
        
        <!-- 추론 부분 그래프 부분 IRIS DENSE NET -->
        <!-- MNIST, IRIS, MOBILE NET 은 그래프를 표현 함 -->
        <!-- 그중 MNIST, IRIS 는 맥스값과 두번째 값 을 표현 해줌 -->
        
        <!--<b-card no-body class="show_inference_part" v-if="selected_pretrain_model === 'IRIS DENSE NET'">-->
        <!--    <b-card-header>-->
        <!--    </b-card-header>-->
            
        <!--    <div class="inference_output">-->
        <!--        <div class="inference_iris" v-if="max_value_name != null ">-->
        <!--            <img v-if="max_value_name === '세토사'" src="@/assets/iris-setosa.jpg" />-->
        <!--            <img v-else-if="max_value_name === '버시컬러'" src="@/assets/iris-versicolor.jpg" />-->
        <!--            <img v-else-if="max_value_name === '버지니카'" src="@/assets/iris-virginica.jpg" />-->
        <!--        </div>-->
        <!--        <div class="inference_iris" v-else>-->
        <!--        </div>-->
        <!--        <div class="inference_data" v-if="max_value_name != null ">-->
        <!--            <div class="inference_num_iris">-->
        <!--                <div class="max_result_value">-->
                            <!--첫번째 그래프 값-->
        <!--                    <label>{{ max_value_name }}</label>-->
        <!--                    <div class="max_result_progress_bar_outer">-->
        <!--                        <div class="max_result_progress_bar_inner" v-if=" max_value_result_percentage != '-Infinity' " :style="{width: max_value_result_percentage + '%'}">{{ max_value_result_percentage }}%</div>-->
        <!--                        <div class="max_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="second_result_value">-->
                        <!--두번째 그래프 값-->
        <!--                    <label>{{ second_max_value_name }}</label>-->
        <!--                    <div class="second_result_progress_bar_outer">-->
        <!--                        <div class="second_result_progress_bar_inner" v-if=" second_max_value_result_percentage != '-Infinity' " :style="{width: second_max_value_result_percentage + '%'}">{{ second_max_value_result_percentage }}%</div>-->
        <!--                        <div class="second_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <!--        <div class="inference_data" v-else>-->
        <!--            <div class="inference_num_iris">-->
        <!--            </div>-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</b-card>-->
        <!-- 추론 부분 그래프 부분 IRIS DENSE NET END -->
        <!--//////// 되는 부분 END -->
        
        <!--추론 그래프 부분 NOT IRIS DENSE NET  -->
        
        <!-- MNIST CONV , MNIST DENSE , LOCAL -->
        <!--<b-card no-body class="show_inference_part" v-else>-->
        <!--    <b-card-header>-->
        <!--    </b-card-header>-->
            
            <!--<div class="inference_output">-->
                
            <!--    <div class="inference" v-if="session_Inference_Image != '' ">-->
            <!--        <img :src="session_Inference_Image" />-->
            <!--    </div>-->
            <!--    <div class="inference" v-else>-->
            <!--    </div>-->
                
                <!-- 이미지 유무 CHECK -->
            <!--    <div class="inference_data" v-if="session_Inference_Image != '' ">-->
                    
                    <!-- MOBILE NET 일 경우 -->
                    <!-- 맥스 값만 존재 -->
            <!--        <div class="inference_num" v-if="selected_pretrain_model === 'MOBILE NET'">-->
            <!--            <div class="max_result_value">-->
                            <!--첫번째 그래프 값-->
            <!--                <label class="mobilenet_label">{{ max_value_name }}</label>-->
            <!--                <div class="max_result_progress_bar_outer">-->
            <!--                    <div class="max_result_progress_bar_inner" v-if=" max_value_result_percentage != '-Infinity' " :style="{width: max_value_result_percentage + '%'}">{{ max_value_result_percentage }}%</div>-->
            <!--                    <div class="max_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->
                    <!-- MOBILE NET 일 경우 END -->
                    
                    <!-- MOBILE NET이 아닌 경우 -->
                    <!--맥스 값과 두번째 값 -->
                    <!--<div class="inference_num" v-else>-->
                    <!--    <div class="max_result_value">-->
                         <!--첫번째 그래프 값-->
                    <!--        <label>{{ max_value_name }}</label>-->
                    <!--        <div class="max_result_progress_bar_outer">-->
                    <!--            <div class="max_result_progress_bar_inner" v-if=" max_value_result_percentage != '-Infinity' " :style="{width: max_value_result_percentage + '%'}">{{ max_value_result_percentage }}%</div>-->
                    <!--            <div class="max_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>-->
                    <!--        </div>-->
                    <!--    </div>-->
                    <!--    <div class="second_result_value">-->
                            <!--두번째 그래프 값-->
                    <!--        <label>{{ second_max_value_name }}</label>-->
                    <!--        <div class="second_result_progress_bar_outer">-->
                    <!--            <div class="second_result_progress_bar_inner" v-if=" second_max_value_result_percentage != '-Infinity' " :style="{width: second_max_value_result_percentage + '%'}">{{ second_max_value_result_percentage }}%</div>-->
                    <!--            <div class="second_result_progress_bar_inner" v-else :style="{width: 0 + '%'}"></div>-->
                    <!--        </div>-->
                    <!--    </div>-->
                    <!--</div>-->
                    <!-- MOBILE NET이 아닌 경우 END -->
                    
                <!--</div>-->
                <!--이미지 유무 CHECK END -->
                
                
                <!--<div class="inference_data" v-else>-->
                <!--    <div class="inference_num">-->
                <!--    </div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</b-card>-->
        <!-- MNIST CONV , MNIST DENSE , LOCAL END  -->
        <!--추론 그래프 부분 NOT IRIS DENSE NET END -->
        
    </div>
    <!--추론 결과 부분 END -->
</template>
<script>
    // vuex =====================
    import store from '@/store';
    // ==========================

    // 언어 설정 JSON 파일 가져오기 ===============================
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================================

    // 모바일 넷 데이터 JSON 파일 가져오기 ========================
    import MobileNetData from '@/MobileNet/data.json';
    // ============================================================

    // 전역 설정 ==================================================
    let DOING_HANDPOSE = false;
    let model_handpose = null;
    let ctx_result;
    let fingerLookupIndices = {
        thumb: [0, 1, 2, 3, 4],
        indexFinger: [0, 5, 6, 7, 8],
        middleFinger: [0, 9, 10, 11, 12],
        ringFinger: [0, 13, 14, 15, 16],
        pinky: [0, 17, 18, 19, 20]
    };
    // ============================================================

    // 추론 결과 시 보여줄 아이리스 결과 이미지 ===================
    import setosa_image from '@/assets/iris-setosa.jpg';
    import versicolor_image from '@/assets/iris-versicolor.jpg';
    import virginica_image from '@/assets/iris-virginica.jpg';
    // ============================================================    

    export default {
        name: 'inference_result',
        data() {
            return {
                // 추론 결과를 보여줄 이미지 
                session_Inference_Image: '',
                // 다국어 변경시 이용할 부분 
                // 추론 결과 
                mi_content_header_result: '',

                // 언어 설정
                localization: ''
            }
        },
        mounted() {
            /* global navigator */

            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // 언어 설정 테스트 
            // this.localization = 'en';

            // if locale 한국어 설정
            switch (this.localization) {
                case 'ko':
                    // 추론 결과 카드 헤더 부분
                    this.mi_content_header_result = korean.inference.mi_content_header_result;
                    return;
                case 'en':
                    // 추론 결과 카드 헤더 부분
                    this.mi_content_header_result = english.inference.mi_content_header_result;
                    return;
            }
        },
        methods: {
            // 결과 이미지 보이기 부분
            show_result_image() {
                console.log("show_result_image");
                let check_max_value_name = store.getters.get_max_value_name;

                // 추론 캔버스 초기화 
                let result_canvas = document.getElementById("result");
                let context = result_canvas.getContext("2d");

                context.clearRect(0, 0, result_canvas.width, result_canvas.height);

                switch (this.selected_pretrain_model) {
                    case "local_upload_model":
                        // MNIST CONVOLUTION NET
                    case "MNIST CONVOLUTION NET":
                        this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                        break;
                        // MNIST DENSE NET
                    case "MNIST DENSE NET":
                        this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                        break;
                        // IRIS DENSE NET
                    case "IRIS DENSE NET":
                        // 맥스 벨류 네임 값에 따라 추론 결과 이미지를 분기 
                        switch (this.max_value_name) {
                            case "세토사":
                                // console.log('세토사!');
                                this.session_Inference_Image = setosa_image;
                                break;
                            case "버시컬러":
                                // console.log('버시컬러!');
                                this.session_Inference_Image = versicolor_image;
                                break;
                            case "버지니카":
                                // console.log('버지니카!');
                                this.session_Inference_Image = virginica_image;
                                break;
                        }
                        break;
                        // MOBILE NET
                    case "MOBILE NET":
                        this.session_Inference_Image = sessionStorage.getItem('session_Inference_Image');
                        break;
                    case "GAN-CARTOONIZER":
                        break;
                    case "HAND POSE":
                        break;
                    case "CONTEXT R-CNN":
                        break;
                }

                // 추론한 이미지를 canvas에 그림으로 변경
                let canvas = document.getElementById("result");

                if (canvas.getContext) {
                    let context = canvas.getContext("2d");

                    /* global Image */
                    let img = new Image();
                    img.src = this.session_Inference_Image;
                    img.onload = () => {

                        //전체 이미지 그리기
                        //drawImage(이미지객체, 사각형 왼위 x, 사각형 왼위 y, 가로크기, 세로크기)
                        context.drawImage(img, 0, 0, 350, 350);
                    }
                }

            },
            // 핸드포즈 그리기 부분 ============================================================
            drawHandPose(predictions, video_captured) {
                console.log("=====");
                const canvas_result = document.getElementById('result');
                canvas_result.width = 350;
                canvas_result.height = 350;
                ctx_result = canvas_result.getContext('2d');
                ctx_result.drawImage(video_captured, 0, 0);
                ctx_result.strokeStyle = 'green';
                ctx_result.fillStyle = 'red';
                if (predictions.length > 0) {
                    const result = predictions[0].landmarks;
                    this.drawKeypoints(result, predictions[0].annotations);
                    console.log(result);
                }
            },

            drawPoint(y, x, r) {
                ctx_result.beginPath();
                ctx_result.arc(x, y, r, 0, 2 * Math.PI);
                ctx_result.fill();
            },

            drawKeypoints(keypoints) {
                const keypointsArray = keypoints;

                for (let i = 0; i < keypointsArray.length; i++) {
                    const y = keypointsArray[i][0];
                    const x = keypointsArray[i][1];
                    this.drawPoint(x - 2, y - 2, 3);
                }

                const fingers = Object.keys(fingerLookupIndices);
                for (let i = 0; i < fingers.length; i++) {
                    const finger = fingers[i];
                    const points = fingerLookupIndices[finger].map(idx => keypoints[idx]);
                    this.drawPath(points, false);
                }
            },

            drawPath(points, closePath) {
                const region = new Path2D();
                region.moveTo(points[0][0], points[0][1]);
                for (let i = 1; i < points.length; i++) {
                    const point = points[i];
                    region.lineTo(point[0], point[1]);
                }

                if (closePath) {
                    region.closePath();
                }
                ctx_result.stroke(region);
            },
            // ================================================================================
            drawContext(predictions, video_captured) {
                // 추론 결과창에 보여줄 이미지 결과 캔버스로 그리기 
                const detected_canvas = document.getElementById('result');
                const detected_context = detected_canvas.getContext('2d');
                detected_context.drawImage(video_captured, 0, 0);
                detected_context.font = '10px Arial';
                detected_context.width = 350;
                detected_context.height = 350;

                // DETECTION 위치 기반으로 캔버스 위에 DETECTION 사각형 그림 그리기 
                for (let i = 0; i < predictions.length; i++) {
                    detected_context.beginPath();
                    detected_context.rect(...predictions[i].bbox);
                    detected_context.lineWidth = 1;
                    detected_context.strokeStyle = 'red';
                    detected_context.fillStyle = 'red';
                    detected_context.stroke();
                    detected_context.fillText(
                        predictions[i].score.toFixed(3) + ' ' + predictions[i].class, predictions[i].bbox[0],
                        predictions[i].bbox[1] > 10 ? predictions[i].bbox[1] - 5 : 10);
                }
            }

        },
        computed: {
            // 제일 높은 확률의 값 혹은 이름
            max_value_name() {
                // 사전 훈련된 모델명으로 분기
                switch (this.selected_pretrain_model) {

                    case "local_upload_model":
                        return store.getters.get_max_value_name;

                        // MNIST CONVOLUTION NET
                    case "MNIST CONVOLUTION NET":
                        return store.getters.get_max_value_name;

                        // MNIST DENSE NET
                    case "MNIST DENSE NET":
                        return store.getters.get_max_value_name;

                        // IRIS DENSE NET
                    case "IRIS DENSE NET":
                        return store.getters.get_max_value_name;

                        // MOBILE NET
                    case "MOBILE NET":
                        // max_rt_ts
                        let max_value = store.getters.get_max_value_name;
                        // 데이터 리스트
                        let max_data = MobileNetData.data;
                        // 결과
                        let max_result = max_data[max_value];
                        return max_result;
                }
            },
            second_max_value_name() {
                switch (this.selected_pretrain_model) {
                    // local file
                    case "local_upload_model":
                        return store.getters.get_second_max_value_name;
                        // MNIST CONVOLUTION NET
                    case 'MNIST CONVOLUTION NET':
                        return store.getters.get_second_max_value_name;
                        // MNIST DENSE NET                    
                    case 'MNIST DENSE NET':
                        return store.getters.get_second_max_value_name;
                        // IRIS DENSE NET
                    case "IRIS DENSE NET":
                        return store.getters.get_second_max_value_name;
                }
            },

            // 확률 그래프 표시값
            max_value_result_percentage() {

                // 값 초기화
                let max_value_multiply = null;
                let max_value_to_integer = null;

                // 사전 훈련된 모델명으로 분기
                switch (this.selected_pretrain_model) {

                    case "local_upload_model":
                        max_value_multiply = store.getters.get_max_value_percentage * 100;
                        max_value_to_integer = Math.round(max_value_multiply)
                        return max_value_to_integer;

                        // MNIST CONVOLUTION NET
                    case "MNIST CONVOLUTION NET":
                        max_value_multiply = store.getters.get_max_value_percentage * 100;
                        max_value_to_integer = Math.round(max_value_multiply);
                        return max_value_to_integer;

                        // MNIST DENSE NET
                    case "MNIST DENSE NET":
                        max_value_multiply = store.getters.get_max_value_percentage * 100;
                        max_value_to_integer = Math.round(max_value_multiply)
                        return max_value_to_integer;

                        // IRIS DENSE NET
                    case "IRIS DENSE NET":
                        max_value_multiply = store.getters.get_max_value_percentage * 100;
                        max_value_to_integer = Math.round(max_value_multiply)
                        return max_value_to_integer;

                        // MOBILE NET
                    case "MOBILE NET":
                        max_value_to_integer = Math.round(store.getters.get_max_value_percentage);
                        return max_value_to_integer;
                }
            },
            
            // 두번째 높은 값
            second_max_value_result_percentage() {

                // 값 초기화
                let second_value_multiply = null;
                let second_value_to_integer = null;

                switch (this.selected_pretrain_model) {

                    case "local_upload_model":
                        second_value_multiply = store.getters.get_second_max_value_percentage * 100;
                        second_value_to_integer = Math.round(second_value_multiply);
                        return second_value_to_integer;

                    case "MNIST CONVOLUTION NET":
                        second_value_multiply = store.getters.get_second_max_value_percentage * 100;
                        second_value_to_integer = Math.round(second_value_multiply);
                        return second_value_to_integer;

                    case "MNIST DENSE NET":
                        second_value_multiply = store.getters.get_second_max_value_percentage * 100;
                        second_value_to_integer = Math.round(second_value_multiply);
                        return second_value_to_integer;

                    case "IRIS DENSE NET":
                        second_value_multiply = store.getters.get_second_max_value_percentage * 100;
                        second_value_to_integer = Math.round(second_value_multiply)
                        return second_value_to_integer;
                }

            },
            // 선택된 사전훈련된 모델
            selected_pretrain_model() {
                return store.getters.get_pretrain_model_name;
            },
        }
    }
</script>
<style scoped>
    .mi_content_header {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .mi_content_header label {
        font-size: 20px;
        font-weight: bold;
    }

    /* 추론 부분 -----------------------------------------------*/

    .show_inference_part {
        width: 450px;
        height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .show_inference_part .card-header {
        width: 450px;
        height: 54px;
        display: flex;
        align-items: center;
    }

    .show_inference_part .card-header label {
        font-size: 20px;
        font-weight: bold;
    }

    .inference_output {
        /*width: 100%;*/
        /*height: 100%;*/
        width: 448px;
        height: 544px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
    }

    .inf_label {
        width: 100%;
        width: 350px;
        height: 10%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .inf_label label {
        font-size: 20px;
        font-weight: bold;
    }

    .inference {
        /*left: 200px;*/
        /*width: 100%;*/
        /*height: 70%;*/
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
        border-radius: 10px;
        margin-bottom: 10px;
        /*box-shadow: 1px 1px 2px #ddd;*/
    }

    .inference_iris {
        width: 350px;
        height: 350px;
        background: #F5F6FA;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow: 1px 1px 2px #ddd;
    }

    .inference_iris img {
        width: 350px;
        height: 350px;
        border-radius: 5px;
    }

    .inference img {
        width: 350px;
        height: 350px;
        border-radius: 10px;
    }

    .inference_data {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: 40px;
    }

    .inference_data_in {
        width: 98%;
    }

    /* gan ===============================================*/

    .show_inference_part #result {
        width: 350px;
        height: 350px;
        /*border: 1px solid #eee;*/
        border-radius: 10px;
    }

    /*====================================================*/

    /* MNIST 추론 ========================================*/

    .inference_num {
        width: 350px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inference_num label {
        font-size: 20px;
        font-weight: bold;
        margin-left: 30px;
    }

    /* 아이리스 추론 ====================================*/

    .inference_num_iris {
        width: 350px;
        height: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .inference_num_iris label {
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
    }


    /*==================================================*/

    /* 모바일 넷 추론 ==================================*/

    .inference_num .mobilenet_label {
        font-size: 15px;
        font-weight: bold;
    }

    /*==================================================*/

    /* 추론 공통 =======================================*/

    .max_result_value,
    .second_result_value {
        display: flex;
        align-items: center;
        width: 350px;
        height: 40px;
    }

    .second_result_value {
        margin-top: 5px;
    }

    /*===================================================*/

    .max_result_progress_bar_outer,
    .second_result_progress_bar_outer {
        margin-left: auto;
        box-shadow: 1px 1px 2px #ddd;
    }

    .max_result_progress_bar_outer,
    .second_result_progress_bar_outer {
        width: 180px;
        height: 40px;
        background-color: #eee;
        /*background-color: #FFDDB6;*/
        border-radius: 5px;
        -webkit-transition: width 2s, height 2s, background-color 2s, -webkit-transform 2s;
        transition: width 2s, height 2s, background-color 2s, transform 2s;
    }

    .max_result_progress_bar_inner,
    .second_result_progress_bar_inner {
        height: 40px;
        background-color: #0D8CFF;
        margin: 0;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        -webkit-transition: width 2s, height 2s, background-color 2s, -webkit-transform 2s;
        transition: width 2s, height 2s, background-color 2s, transform 2s;
    }

    .second_result_progress_bar_inner {
        background-color: #BABABA;

    }


    /* 추론 부분 END--------------------------------------------*/
</style>
