<template>
    <div class="flatten">
            <label>InputShape</label>
            <div class="image_data">
                {{ input_data.IMAGE_WIDTH + " , " + input_data.IMAGE_HEIGHT }}
            </div>
            <label>OutputShape</label>
            <div class="image_data">
                {{ input_data.IMAGE_WIDTH * input_data.IMAGE_HEIGHT }}
            </div>
    </div>
    
</template>

<script>
    import store from '@/store';

    export default {
        name: 'flatten',
        data() {
            return {
                input_shape: '',
                output_shape: 0,
            }
        },
        computed: {
            input_data() {
                return store.getters.get_input_data
            }
        },
    }
</script>

<style scoped>
    .flatten {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .flatten label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .flatten select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }

    /*.flatten-input {*/

    /*    color: #555555;*/

    /*    display: flex;*/

    /*    flex-direction: column;*/

    /*    justify-content: space-around;*/

    /*}*/

    /*.flatten-input label {*/

    /*    color: #555555;*/

    /*    font-size: 12px;*/

    /*}*/

    /*.flatten-input input {*/

    /*margin-left: auto;*/

    /*    width: 55px !important;*/

    /*    height: 20px !important;*/

    /*    border-radius: 5px;*/

    /*    background-color: white;*/

    /*    padding: 2px 6px;*/

    /*    border: 1px solid #999;*/

    /*    font-size: 100%;*/

    /*}*/

    /*.flatten-output {*/

    /*    display: flex;*/

    /*    justify-content: space-around;*/

    /*    text-align: center;*/

    /*}*/

    /*.flatten-output label {*/

    /*    color: #555555;*/

    /*    font-size: 12px;*/

    /*}*/

    /*.flatten-output input {*/

    /*    margin-left: auto;*/

    /*    width: 55px !important;*/

    /*    height: 20px !important;*/

    /*    border-radius: 5px;*/

    /*    background-color: white;*/

    /*    padding: 2px 6px;*/

    /*    border: 1px solid #999;*/

    /*    font-size: 100%;*/

    /*}*/

    .image_data {
        display: flex;
        align-items: center;
        width: 120px;
        height: 20px;
        border: 1px solid #999;
        border-radius: 5px;
        background-color: #fff;
        color: #000;
        font-size: 10px;
        padding-left: 5px;
    }
</style>
