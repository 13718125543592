import Rete from "rete";
import { numSocket } from '../../sockets';
import ConvControl from '../../controls/ConvControl';

export default class ConvComponent extends Rete.Component {
	constructor() {
		super("conv2d");
		this.data = {
			render: "vue"
		};
	}
	builder(node) {
		let input1 = new Rete.Input("input1","인풋(input)",numSocket);
		let output1 = new Rete.Output("output1","아웃풋(output)",numSocket);
		return node
			.addControl(new ConvControl(this.editor, "input"))
			.addInput(input1)
			.addOutput(output1);
	}
	worker() {}
}