import Rete from 'rete';
import { numSocket } from '../../sockets';
import MNISTDenseNetControl from '../../controls/test-controls/MNISTDenseNetControl';

export default class MNISTDenseNetComponent extends Rete.Component {
	constructor() {
		super("MNIST DenseNet");
		this.data = {
			render: 'vue'
		};
	}

	builder(node) {
		let input1 = new Rete.Input('input1', 'Input1', numSocket);
		let output1 = new Rete.Output('output1', 'Output1', numSocket);
		return node
			.addControl(new MNISTDenseNetControl(this.editor, "num"))
			.addOutput(output1)
			.addInput(input1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
