import Rete from "rete";
import { numSocket } from '../../sockets';
import DataInputControl from '../../controls/DataInputControl';

export default class InputComponent extends Rete.Component {
	constructor() {
		super("데이터입력");
		this.data = {
			render: "vue"
		};
	}
	builder(node) {
		let output1 = new Rete.Output("output1", "아웃풋(output)", numSocket);
		return node
			.addControl(new DataInputControl(this.editor, "input"))
			.addOutput(output1);
	}
	worker() {}
}
