<template>
    <div class="Monitoring">
        <label>종류</label>
	    	<select v-model="kinds_selected" @change="changeKind">
		    	<option v-for="option in kinds_options" v-bind:value="option.value" >
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>모니터링 위치</label>
	    	<select v-model="monitoring_loc_selected" @change="changelocation">
		    	<option v-for="option in monitoring_loc_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
	    <label>모니터링 방식</label>
	    	<select v-model="monitoring_kinds_selected" @change='changemonitorkind'>
		    	<option v-for="option in monitoring_kinds_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    	<label>구분 기준</label>
	    	<select v-model="distribution_selected">
		    	<option v-for="option in distribution_options" v-bind:value="option.value">
			    	{{ option.text }}
			    </option>
	    	</select>
    </div>
</template>
<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex';
    import store from '@/store';


    export default {
        name: 'Monitoring',
        data() {
            return {
                kinds_selected: '',
                monitoring_loc_selected: '',
                monitoring_kinds_selected: '',
                distribution_selected: '폴더 이름',
                kinds_options: [
                    { text: '데이터 시각화', value: "data_visualization" },
                    { text: '데이터 문자화', value: "data_text" },
                ],
                monitoring_loc_options: [
                    { text: '하단 오른쪽', value: 'bottom_right' },
                    { text: '하단 왼쪽', value: 'bottom_left' }
                ],
                monitoring_kinds_options: [
                    { text: '원형 그래프', value: 'donut_graph' },
                    { text: '데이터 차트', value: 'data_chart' },
                    { text: '불러온 이미지', value: 'upload_image' }
                ],
                distribution_options: [
                    { text: '폴더 이름', value: '폴더 이름' }
                ]
            }
        },
        methods: {
            changeKind(e) {
                switch (e.target.value) {
                    case 'data_visualization':
                        store.dispatch('call_visualization', { visualization: 'data_visualization' });
                        return
                    case 'data_text':
                        store.dispatch('call_visualization', { visualization: 'data_text' });
                        return
                }
            },
            changelocation(e) {
                switch (e.target.value) {
                    case 'bottom_right':
                        store.dispatch('call_location', { bottomlocation: 'bottom_right' });
                        this.monitoring_kinds_selected = ''
                        return

                    case 'bottom_left':
                        store.dispatch('call_location', { bottomlocation: 'bottom_left' });
                        this.monitoring_kinds_selected = ''
                        return
                }
            },
            changemonitorkind(e) {

                if (store.getters.getConnecting === true && store.getters.getVisualization == 'data_visualization') {

                    switch (e.target.value) {
                        case 'donut_graph':
                            store.dispatch('call_monitor_kinds', { monitorKinds: 'Datagraph' });
                            return

                        case 'data_chart':
                            store.dispatch('call_monitor_kinds', { monitorKinds: 'DataTable' });
                            return

                        case 'upload_image':
                            store.dispatch("call_monitor_kinds", { monitorKinds: 'MnistImage' });
                            return
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .Monitoring {
        color: #555555;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .Monitoring label {
        font-size: 10px !important;
        text-align: left;
        margin-bottom: 0 !important;
    }

    .Monitoring select,
    input {
        border-radius: 5px !important;
        width: 120px !important;
    }
</style>
