<template>
    <div class="MaxPooling">
            <div class="MaxPooling_poolSize">
                <label>poolSize</label>
                <input type="text" v-model="poolSize_input"></input>
            </div>
            <div class="MaxPooling_strides">
                <label>strides</label>
                <input type="text" v-model="strides_input"></input>
            </div>
        <!--</b-collapse>-->
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'MaxPooling',
        data() {
            return {
                poolSize_input: "",
                strides_input: "",
                input_data: {
                    poolSize: '',
                    strides: '',
                }
            }
        },
        updated() {
            this.input_data.poolSize = this.poolSize_input;
            this.input_data.strides = this.strides_input;

            let m_num = store.getters.get_mod_node_id;

            let maxpool_id = store.getmaxpoolnode_id;


            if (m_num != '') {
                sessionStorage.setItem(m_num, JSON.stringify(this.input_data));
            }
            else {
                return;
            }

        },
        mounted() {

            //MNIST SET
            if (store.getters.get_model_node_name === "MNIST ConvNet") {
                // switch (store.getters.getmaxpoolnode) {
                //     case "maxPooling2d9":
                //         this.poolSize_input = "2,2";
                //         this.strides_input = "2,2";
                //         return;

                //     case "maxPooling2d11":
                //         this.poolSize_input = "2,2";
                //         this.strides_input = "2,2";
                //         return;
                // }

                // 해당하는 모달의 데이터를 세션에서 가져오기
                let saved_model_data = sessionStorage.getItem('modaldata' + store.getters.getNodeSelected);
                // JSON 파싱
                let saved_model_data_json = JSON.parse(saved_model_data);
                // JSON NODE
                let s_json = saved_model_data_json.nodes;
                // NODE KEY
                let s_keys = Object.keys(s_json);
                // NODE VALUE
                let s_values = Object.values(s_json);
            
                // 선택한 MAXPOOLING2D NODE의 ID
                let maxpool_id = store.getters.getmaxpoolnode_id;
                
                // MAXPOOLING1D NODE 값과 내용들을 세션에 저장
                for (let i = 0; i < s_keys.length; i++) {
                    if (s_values[i].id === maxpool_id) {
                        sessionStorage.setItem(maxpool_id, JSON.stringify(s_values[i].data));

                        this.poolSize_input = s_values[i].data.poolSize;
                        this.strides_input = s_values[i].data.strides;
                    }
                }

            }
            else {
                // 해당하는 모달의 데이터를 세션에서 가져오기
                let saved_model_data = sessionStorage.getItem('modaldata' + store.getters.getNodeSelected);
                // JSON 파싱
                let saved_model_data_json = JSON.parse(saved_model_data);
                // JSON NODE
                let s_json = saved_model_data_json.nodes;
                // NODE KEY
                let s_keys = Object.keys(s_json);
                // NODE VALUE
                let s_values = Object.values(s_json);

                // 선택한 MAXPOOLING2D NODE의 ID
                let maxpool_id = store.getters.getmaxpoolnode_id;
                
                // MAXPOOLING NODE 값과 내용들을 세션에 저장
                for (let i = 0; i < s_keys.length; i++) {
                    if (s_values[i].id === maxpool_id) {
                        sessionStorage.setItem(maxpool_id, JSON.stringify(s_values[i].data));

                        this.poolSize_input = s_values[i].data.poolSize;
                        this.strides_input = s_values[i].data.strides;
                    }
                }
            }

        }
    }
</script>

<style scoped>
    .MaxPooling {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #555555;
        height: 50px;
    }

    .inner_view {
        width: 120px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inner_view:hover {
        background: #F5F5FB;
        border: 1px solid #A5A4BF;
        border-radius: 5px;
    }

    .MaxPooling label {
        color: #555555;
        font-size: 8px;
    }

    .MaxPooling input {
        width: 55px !important;
        height: 20px !important;
        border-radius: 5px;
        padding: 2px 6px;
        border: 1px solid #999;
        font-size: 100%;
    }

    .MaxPooling_poolSize {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .MaxPooling_poolSize input {
        margin-left: 10px;
    }

    .MaxPooling_strides {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .MaxPooling_strides input {
        margin-left: 10px;
    }
</style>
