// Vue
import Vue from 'vue';
import App from './App.vue';

// css 
import './style.css';

// vue-router
import { router } from './routes/index.js';

// vuex
import store from './store';
import Vuex from 'vuex';
Vue.use(Vuex);

// kakao login
// window.Kakao.init("b5e76ce6c5526d90e14c8b9aaaed1244");

// 8080~ 8081 port 전용 kakao
// testapp kakao key
// window.Kakao.init("3b32586783a79b7c8de10b59b0941086");

// alpha nocoding ai
window.Kakao.init("c7005e0c4799b270beda45bb4b84e721");

// bootstrap-vue 불러오기
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// vue css donut chart
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);

// vue-html2canvas
import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);


import VuePapaParse from 'vue-papa-parse';
Vue.use(VuePapaParse);

// long click ================================================================
// import { longClickDirective } from "vue-long-click";
// const longClickInstance = longClickDirective({ delay: 400, interval: 50 });
// Vue.directive("longclick", longClickInstance);
//============================================================================

//////////// analytics
import Analytics from '@aws-amplify/analytics';

import Auth from '@aws-amplify/auth';

const amplifyConfig = {
    Auth: {
        identityPoolId: "us-east-1:0cdd7fc3-2ca1-412b-aa99-847331d0e361",
        region: 'us-east-1'
    }
}
//Initialize Amplify
Auth.configure(amplifyConfig);

const analyticsConfig = {
    AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '13a8e3caba11411da98c13a63a28c92d',
        // Amazon service region
        region: 'us-east-1',
        mandatorySignIn: false,
    }
}

Analytics.configure(analyticsConfig)

//Record an event
Analytics.record('_session.start');

//Record a custom event
Analytics.record({
    name: 'Album',
    attributes: { genre: 'Rock', year: '1989' }
});



Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
