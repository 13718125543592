<template>
    <!-- 사전 훈련된 모델 불러오기 모달 부분-->
    <div class="pretrained_model_modal_outer">
        <!-- header -->
        <div class="pretrained_model_modal_header">
            <!--모델 선택 모달 헤더 부분-->
            <!--사전 훈련된 모델 선택 -->
            <label>{{ pretrained_model_select_header }}</label>
            <b-icon icon="x" id="mod-closebtn" class="close-btn" v-b-tooltip.hover.bottom="'Modal Close Button'" v-on:click="close_btn"></b-icon> 
        </div>
        <!-- body -->
        <div class="pretrained_model_modal_body">
            <!-- trained model 검색 부분-->
            <div class="pretrained_model_modal_search">
                <!--모델 서치 모델 검색 부분-->
                <!--placeholder="찾는 이름..."-->
                <input type="text" :placeholder="pretrained_model_search"></input>
                <select v-model="sort_model">
                    <!--셀랙트 메뉴 1-->
                    <!--이미지 관련 모음만-->
                    <option>{{ sort_model_related_image }}</option>
                    <!--셀렉트 메뉴 2-->
                    <!--최근 업데이트 순-->
                    <option>{{ sort_model_recent }}</option>
                    <!--셀렉트 메뉴 3-->
                    <!--이름 순-->
                    <option>{{ sort_model_name }}</option>
                </select>
            </div>
            <!-- trained model 불러오기-->
            <div class="model_upload_part">
                <div class="local_pretrained_file_upload" @click="local_file_upload">
                    <b-icon icon="plus-square-fill"></b-icon>
                    <!--로컬에 저장되어 있는 모델 불러오기 부분-->
                    <!--로컬에서 불러오기-->
                    <label>{{ local_pretrained_file_upload }}</label>
                    <!--로컬에서 불러오기 설명-->
                    <!--저장된 모델을 하드디스크에서 불러옵니다.-->
                    <p>{{ local_pretrained_file_upload_explain }}</p>
                    <input ref="local_file_input" type="file" multiple hidden @change="upload_local_model_file"/>
                </div>
                <b-modal ref="local_file_name_check" hide-footer :title="ainomis_alert">
                    <!--로컬 모델 이름 확인 알람창-->
                    <!--로컬 모델 이름을 확인해 주세요!-->
                    <label class="local_check_label">{{ local_name_check_alert }}</label>
                </b-modal>
                <!--제이슨 구조 혹은 제이슨 파일로 서버에 저장된 파일 가져오기 카드 부분-->
                <b-card class="model_card" no-body v-for="(pretrain_model, index) in pretrain_models" :key="index">
                    <div class="model_card_body">
                        <div class="model_card_icon">
                            <div class="icon_boundary">
                                <b-icon icon="bounding-box"/>                                
                            </div>
                            <div class="label_boundary">
                                <!--사전 훈련된 모델 카드 우측상단 라벨-->
                                <!--훈련 완료-->
                                <label>{{ train_complete }}</label>
                            </div>
                            <div class="model_setting">
                                <b-icon icon="three-dots-vertical"/>
                            </div>
                        </div>
                        <div class="model_card_explain">
                            <!--사전 훈련된 모델 이름-->
                            <label> {{ pretrain_model.name }} </label>
                            <!--사전 훈련된 모델 설명-->
                            <p> {{ pretrain_model.data }} </p>
                        </div>
                        <div class="model_card_select">
                            <label>
                                <!--데이터셋: 사전훈련된 모델의 데이터셋 이름-->
                                {{ dataset }}: {{ pretrain_model.datasetName }}</br>
                                <!--크기: 사전 훈련된 모델의 데이터셋 크기-->
                                {{ datasize }}: {{ pretrain_model.datasetSize }} </br>
                                <!--레이어수: 사전 훈련된 모델의 레이어 수-->
                                {{ modellayer }}: {{ pretrain_model.layers }} </br>
                            </label>
                            <b-button variant="primary" v-on:click="pretrain_model_select(pretrain_model)" :disabled="pretrain_model.isDisabled">선택하기</b-button>
                        </div>                        
                    </div>
                </b-card>
            </div>
        </div>
    </div>
    
</template>
<script>
    // vuex =======================================
    import store from '@/store';
    // ============================================

    // 언어 설정 JSON 파일 가져오기 ===============
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';
    // ============================================

    // 사전 훈련된 모델 JSON 불러오기 ==================================
    // import Pretrained_model from './ModelData/PretrainedModel.json';
    // =================================================================

    export default {
        name: 'trained_model',
        data() {
            return {
                sort_model: '이미지 관련 모음만',

                // 사전 훈련된 모델 임시 json
                pretrain_models: [{
                        name: 'MNIST CONVOLUTION NET',
                        data: 'CNN 중 convolutional layer를 중점적으로 사용한 기초 모델입니다. MNIST 숫자 판별을 위해 훈련되었습니다.',
                        datasetName: 'MNIST',
                        datasetSize: 65000,
                        layers: 6,
                        isDisabled: false
                    },
                    {
                        name: 'MNIST DENSE NET',
                        data: 'CNN 중 dense layer 를 중점적으로 사용한 기초 모델입니다. MNIST 숫자 판별을 위해 훈련되었습니다.',
                        datasetName: 'MNIST',
                        datasetSize: 65000,
                        layers: 3,
                        isDisabled: false
                    },
                    {
                        name: 'IRIS DENSE NET',
                        data: 'CNN 중 dense layer를 중점적으로 사용한 기초 모델입니다. IRIS 품종 구분을 위해 훈련되었습니다.',
                        datasetName: 'IRIS',
                        datasetSize: 150,
                        layers: 2,
                        isDisabled: false
                    },
                    {
                        name: 'MOBILE NET',
                        data: 'MOBILE NET 모델입니다. 동물 및 물체 종류 구분을 위해 훈련되었습니다.',
                        datasetName: 'ILSVRC2012',
                        // datasetName: 'ILSVRC-2012-CLS',
                        datasetSize: 20 + 'MB',
                        layers: 27,
                        isDisabled: false
                    },
                    {
                        name: 'GAN-CARTOONIZER',
                        data: 'GAN 모델입니다. 인물 사진에 만화 효과를 주기 위해 훈련되었습니다.',
                        datasetName: '-',
                        datasetSize: '- MB',
                        layers: "-",
                        isDisabled: false
                    },
                    {
                        name: 'CONTEXT R-CNN',
                        data: '사진속 객체를 인식하기 위해 훈련되었습니다.',
                        datasetName: '-',
                        datasetSize: '- MB',
                        layers: "-",
                        isDisabled: false
                    },
                    {
                        name: 'HAND POSE',
                        data: '웹캠으로 촬영하여 손의 위치, 마디를 감지하고 좌표를 알려주기 위해 훈련되었습니다.',
                        datasetName: '-',
                        datasetSize: '- MB',
                        layers: "-",
                        isDisabled: false
                    },
                ],
                local_upload_model: [],

                // 다국어 설정
                pretrained_model_select_header: '',
                pretrained_model_search: '',
                sort_model_related_image: '',
                sort_model_recent: '',
                sort_model_name: '',
                local_pretrained_file_upload: '',
                local_pretrained_file_upload_explain: '',
                ainomis_alert: '',
                local_name_check_alert: '',
                train_complete: '',
                dataset: '',
                datasize: '',
                modellayer: '',

                // 언어설정
                localization: '',
            }
        },
        mounted() {

            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);

            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.pretrained_model_select_header = korean.select_model_modal.pretrained_model_select_header;
                    this.pretrained_model_search = korean.select_model_modal.pretrained_model_search;
                    this.sort_model_related_image = korean.select_model_modal.sort_model_related_image;
                    this.sort_model_recent = korean.select_model_modal.sort_model_recent;
                    this.sort_model_name = korean.select_model_modal.sort_model_name;
                    this.local_pretrained_file_upload = korean.select_model_modal.local_pretrained_file_upload;
                    this.local_pretrained_file_upload_explain = korean.select_model_modal.local_pretrained_file_upload_explain;
                    this.ainomis_alert = korean.select_model_modal.ainomis_alert;
                    this.local_name_check_alert = korean.select_model_modal.local_name_check_alert;
                    this.train_complete = korean.select_model_modal.train_complete;
                    this.dataset = korean.select_model_modal.dataset;
                    this.datasize = korean.select_model_modal.datasize;
                    this.modellayer = korean.select_model_modal.modellayer;
                    break;
                case 'en':
                    this.pretrained_model_select_header = english.select_model_modal.pretrained_model_select_header;
                    this.pretrained_model_search = english.select_model_modal.pretrained_model_search;
                    this.sort_model_related_image = english.select_model_modal.sort_model_related_image;
                    this.sort_model_recent = english.select_model_modal.sort_model_recent;
                    this.sort_model_name = english.select_model_modal.sort_model_name;
                    this.local_pretrained_file_upload = english.select_model_modal.local_pretrained_file_upload;
                    this.local_pretrained_file_upload_explain = english.select_model_modal.local_pretrained_file_upload_explain;
                    this.ainomis_alert = english.select_model_modal.ainomis_alert;
                    this.local_name_check_alert = english.select_model_modal.local_name_check_alert;
                    this.train_complete = english.select_model_modal.train_complete;
                    this.dataset = english.select_model_modal.dataset;
                    this.datasize = english.select_model_modal.datasize;
                    this.modellayer = english.select_model_modal.modellayer;
                    break;

            }

        },
        methods: {
            close_btn() {
                this.$emit('close_btn');
            },
            // 사전 훈련된 모델 별 클릭
            pretrain_model_select(pretrain_model) {
                /* global localStorage */
                switch (pretrain_model.name) {
                    case "MNIST CONVOLUTION NET":

                        // 사전 훈련된 모델 이름 STORE DISPATCH
                        store.dispatch('call_pretrain_model_name', { pretrain_model_name: pretrain_model.name });

                        // 추론 부분의 그래프 부분 및 값 초기화
                        store.dispatch('call_max_value_name', { max_value_name: null });
                        store.dispatch('call_max_value_percentage', { max_value_percentage: null });
                        store.dispatch('call_second_max_value_name', { second_max_value_name: null });
                        store.dispatch('call_second_max_value_percentage', { second_max_value_percentage: null });
                        // this.$emit('close_btn', pretrain_model.name);
                        this.$emit('close_btn');
                        break;

                    case "MNIST DENSE NET":

                        // 사전 훈련된 모델 이름 STORE DISPATCH
                        store.dispatch('call_pretrain_model_name', { pretrain_model_name: pretrain_model.name });

                        // 초기화
                        store.dispatch('call_max_value_name', { max_value_name: null });
                        store.dispatch('call_max_value_percentage', { max_value_percentage: null });
                        store.dispatch('call_second_max_value_name', { second_max_value_name: null });
                        store.dispatch('call_second_max_value_percentage', { second_max_value_percentage: null });
                        this.$emit('close_btn');
                        return;
                        
                    case "IRIS DENSE NET":

                        // 사전 훈련된 모델 이름 STORE DISPATCH
                        store.dispatch('call_pretrain_model_name', { pretrain_model_name: pretrain_model.name });

                        store.dispatch('call_max_value_name', { max_value_name: null });
                        store.dispatch('call_max_value_percentage', { max_value_percentage: null });
                        store.dispatch('call_second_max_value_name', { second_max_value_name: null });
                        store.dispatch('call_second_max_value_percentage', { second_max_value_percentage: null });
                        this.$emit('close_btn');
                        return;

                    case "MOBILE NET":

                        // 사전 훈련된 모델 이름 STORE DISPATCH
                        store.dispatch('call_pretrain_model_name', { pretrain_model_name: pretrain_model.name });

                        // 초기화
                        store.dispatch('call_max_value_name', { max_value_name: null });
                        store.dispatch('call_max_value_percentage', { max_value_percentage: null });
                        this.$emit('close_btn');
                        return;


                        // TEST 용 
                    case "GAN-CARTOONIZER":
                        store.dispatch('call_pretrain_model_name', { pretrain_model_name: pretrain_model.name });
                        this.$emit('close_btn');
                        return;

                    case "CONTEXT R-CNN":
                        store.dispatch('call_pretrain_model_name', { pretrain_model_name: pretrain_model.name });
                        this.$emit('close_btn');
                        return;
                    case "HAND POSE":
                        store.dispatch('call_pretrain_model_name', { pretrain_model_name: pretrain_model.name });
                        this.$emit('close_btn');
                        return;

                }
            },
            local_file_upload() {
                this.$refs.local_file_input.click();
            },
            upload_local_model_file(e) {

                let local_upload_file = e.target.files;

                // 업로드한 JSON 파일과 BIN 파일 이름이 서로 동일한지 체크하여 순서 지정
                for (let i = 0; i < local_upload_file.length; i++) {

                    let check_local_file_name = local_upload_file[i].name;

                    if (check_local_file_name.includes('json')) {
                        // console.log('json check')
                        this.local_upload_model[0] = local_upload_file[i];
                    }
                    else {
                        // console.log('bin check');
                        this.local_upload_model[1] = local_upload_file[i];
                    }
                }
                // 선택한 로컬 파일 중 json 파일
                let local_model_file_first = this.local_upload_model[0].name;

                // 선택한 로컬 파일 중 bin 파일
                let local_model_file_second = this.local_upload_model[1].name;

                // 기준이 될 이름 추출
                let check_model_file_filter = local_model_file_first.split('.');
                let check_upload_model_name = check_model_file_filter[0];

                // JSON 파일과 bin 파일의 이름이 같으면 추론 페이지에 값 넘기기
                if (local_model_file_first.includes(check_upload_model_name) && local_model_file_second.includes(check_upload_model_name)) {

                    // local_upload_model store pretrain_model_name dispatch
                    store.dispatch('call_pretrain_model_name', { pretrain_model_name: 'local_upload_model' });

                    // local_upload_model store dispatch
                    store.dispatch('call_local_upload_model', { local_upload_model: this.local_upload_model });
                    this.$emit('close_btn');
                }
                else {
                    // alert("로컬 모델 이름을 확인해 주세요");
                    this.$refs['local_file_name_check'].show();
                }
            }
        },

    }
</script>
<style scoped>
    .pretrained_model_modal_outer {
        height: 800px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pretrained_model_modal_header {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .pretrained_model_modal_header label {
        color: #858585;
        font-size: 30px;
        font-weight: bold;
        margin-left: 10px;
    }

    .pretrained_model_modal_header .b-icon {
        margin-left: auto;
        margin-right: 10px;
    }

    .pretrained_model_modal_body {
        height: 80%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
    }

    .pretrained_model_modal_search {
        height: 10%;
        width: 90%;
        display: flex;
        align-items: center;
    }

    .pretrained_model_modal_search input {
        height: 50px;
        width: 500px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: 2.3%;
        font-size: 15px !important;
    }

    .pretrained_model_modal_search select {
        height: 40px;
        width: 200px !important;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        margin-left: auto;
        font-size: 15px !important;
        /*margin-right: 2.4%;*/
    }

    .model_upload_part {
        width: 90%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
        overflow-y: auto;
    }

    .local_pretrained_file_upload {
        width: 250px;
        height: 180px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    .local_pretrained_file_upload .b-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        color: #C2C2C2;
    }

    .local_pretrained_file_upload label {
        font-weight: bold;
        font-size: 20px;
        color: #C2C2C2;
    }

    .local_pretrained_file_upload p {
        font-size: 12px;
        color: #C2C2C2;
        text-align: center;
    }


    .local_pretrained_file_upload:hover {
        background: #eeeeee;
        cursor: pointer;
    }


    .model_upload_part .card {
        border: none !important;
    }

    .model_upload_part .model_card {
        width: 250px;
        height: 180px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        margin: 10px;
    }

    /*.model_upload_part .model_card:hover {*/

    /*    cursor: pointer;*/

    /*}*/

    .model_card_body {
        width: 250px;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .model_card_icon {
        width: 100%;
        height: 30%;
        display: flex;
    }

    .model_card_icon .icon_boundary {
        width: 35px;
        height: 35px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .model_card_icon .icon_boundary .b-icon {
        width: 20px;
        height: 20px;
        color: #3E7AD3;
    }

    .model_card_icon .label_boundary {
        width: 60px;
        height: 20px;
        border-radius: 10px;
        background: #E3ECFB;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
    }

    .model_card_icon .label_boundary label {
        font-size: 8px;
        margin-bottom: 0 !important;
        color: #3E7AD3;
    }

    .model_card_icon .model_setting {
        margin-top: 10px;
        margin-right: 10px;
    }

    .model_card_icon .model_setting .b-icon:hover {
        cursor: pointer;
    }

    .model_card_explain {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*align-items: center;*/
    }

    .model_card_explain label {
        font-weight: bold;
        margin-left: 15px;
    }

    .model_card_explain p {
        font-size: 10px;
        color: #858585;
        word-break: keep-all;
        margin-left: 15px;
        margin-right: 10px;
    }

    .model_card_select {
        width: 250px;
        height: 30%;
        background: #DDE6F2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .model_card_select label {
        margin-left: 15px;
        font-size: 8px;
        margin-top: 10px;
        color: #858585;
    }

    .model_card_select button {
        width: 100px;
        height: 30px;
        margin-left: auto;
        margin-right: 10px;
        font-size: 13px;
    }
</style>
