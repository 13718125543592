<template>
    <div class="dock">
         <!--v-b-toggle.collapse-a-->
        <div class="dock-label">
            <b-icon icon="chevron-down"></b-icon>
            <!--노드 구성 토글 버튼 부분-->
            <!--노드 구성 -->
            <label>{{ node_config_label }}</label>
        </div>
        <!--<b-collapse id="collapse-a">-->
             <div class="dock-item">
                <div class="node 데이터입력" id="datainput">
                    <!--데이터 입력 노드 생성-->
                    <!--데이터 입력(input)-->
                    <div class="title">{{ node_input }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node Conv2d" id="conv2d">
                    <!--CONVOLUTION 2D 노드 생성-->
                    <!--Conv2d-->
                    <div class="title">{{ node_Conv2d }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node MaxPooling2d" id="maxpooling2d">
                    <!--MAXPOOLING 2D 노드 생성-->
                    <!--MaxPooling2d-->
                    <div class="title">{{node_MaxPooling2d}}</div>
                </div>
            </div>
            <div class="dock-item">
                <div class="node 플랫튼(flatten)" id="flatten">
                    <!--플랫튼 노드 생성-->
                    <!--플랫튼(flatten)-->
                    <div class="title">{{ node_flatten }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node 덴스(dense)" id="dense">
                    <!--덴스 노드 생성-->
                    <!--덴스(dense)-->
                    <div class="title">{{ node_dense }}</div>
                </div>
            </div>
             <div class="dock-item">
                <div class="node 데이터출력" id="dataoutput">
                    <!--데이터출력 노드 생성-->
                    <!--데이터출력(output)-->
                    <div class="title">{{ node_output }}</div>
                </div>
            </div>
        <!--</b-collapse>-->
    </div>
</template>

<script>
    // 언어 설정 불러오기
    import korean from '@/language/korean.json';
    import english from '@/language/english.json';

    export default {
        name: "dock-item",
        data() {
            return {

                // 다국어 설정
                node_config_label: '',
                node_input: '',
                node_Conv2d: '',
                node_MaxPooling2d: '',
                node_flatten: '',
                node_dense: '',
                node_output: '',
                
                // 언어 설정
                localization: ''
            }
        },
        mounted() {
            /* global navigator */
            let locale = navigator.language || navigator.userLanguage;

            this.localization = locale.substring(0, 2);
            
            // 언어 설정 테스트
            // this.localization = 'en';

            switch (this.localization) {
                case 'ko':
                    this.node_config_label = korean.model_details_dock.node_config_label;
                    this.node_input = korean.model_details_dock.node_input;
                    this.node_Conv2d = korean.model_details_dock.node_Conv2d;
                    this.node_MaxPooling2d = korean.model_details_dock.node_MaxPooling2d;
                    this.node_flatten = korean.model_details_dock.node_flatten;
                    this.node_dense = korean.model_details_dock.node_dense;
                    this.node_output = korean.model_details_dock.node_output;
                    return;
                case 'en':
                    this.node_config_label = english.model_details_dock.node_config_label;
                    this.node_input = english.model_details_dock.node_input;
                    this.node_Conv2d = english.model_details_dock.node_Conv2d;
                    this.node_MaxPooling2d = english.model_details_dock.node_MaxPooling2d;
                    this.node_flatten = english.model_details_dock.node_flatten;
                    this.node_dense = english.model_details_dock.node_dense;
                    this.node_output = english.model_details_dock.node_output;
                    return;
            }
        },
        methods: {}
    }
</script>

<style scoped>
    .dock {
        width: 15%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: #ffffff;
        border-right: 1px solid #555555;
    }

    .dock-item .node {
        cursor: pointer;
        height: 45px !important;
        display: flex;
        align-items: center !important;
        border: 1px solid #ccc !important;
        border-radius: 6px !important;
        box-shadow: 1px 1px 2px #ddd;
        color: #ccc !important;
    }

    .dock .node span {
        height: 30px !important;
        width: 30px !important;
        margin-left: 5px;
    }

    .dock .node .title {
        margin-left: 5px !important;
    }

    .title {
        font-size: 13px;
    }
</style>
