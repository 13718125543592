import Rete from 'rete';
import { numSocket } from '../../sockets';
import datamonitoringControl from '../../controls/monitoring-controls/datamonitoringControl';

export default class datamonitoringComponent extends Rete.Component {
	constructor() {
		super("데이터모니터링");
		this.data = {
			render: 'vue'
		};
	}

	builder(node) {
		let input1 = new Rete.Input('input1', 'Input1', numSocket);
		return node
			.addControl(new datamonitoringControl(this.editor, "num"))
			.addInput(input1);
	}
	worker(node, inputs, outputs) {
		outputs["num"] = node.data.num;
	}
}
